import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderTeacher from "./HeaderTeacher";
import FooterTeacher from "./FooterTeacher";
// import ReportWaitListTable from "./ReportWaitListTable";
import LessonTable from "../LessonTable";
import { can_access_page } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";
import { getCurrentDate_HKT, DATE_FORMAT_FULL, DATE_FORMAT_DDMMYYYY } from "../../app/date_utils";

const DashboardTeacher = () => {

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    const [ date_string_now_DDMMYYYY ] = useState( getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ) );

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Teacher_Dashboard) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        user &&
        <>
            <div className="basic_bg font-sans">
                
                <HeaderTeacher />

                <div className="dashboard_container">
                
                <section className="heading">

                    <div className="px-6 py-3 text-center font-extrabold text-white">{getCurrentDate_HKT( DATE_FORMAT_FULL )}</div>

                    <h1>Today's Classes</h1>
                    
                </section>
                
                <LessonTable the_date={ date_string_now_DDMMYYYY } the_teacher={user}/>

                </div>

                <FooterTeacher />
            </div>
        </>
    );
}

export default DashboardTeacher;