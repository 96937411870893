import { useEffect, useState, useCallback } from "react";
import { MdOutlineEventRepeat } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addRecurringClass, resetAddRecurringClass } from "../../../features/recurringClasses/recurringClassSlice";
import { getTeachersByName, resetTeachers } from "../../../features/teachers/teacherSlice";
import Spinner from "../../Spinner";
import FooterAdmin from "../FooterAdmin";
import StudentSearchComponent from "../../StudentSearchComponent";
import { PATH_NAME, DAYS_OF_WEEK, CLASS_LEVELS,
            TIME_SLOTS, ROOMS } from "../../../app/constants";
import { UI_STRING_ADD_LESSON_ADMIN, UI_STRING_LOADING_ALL, UI_STRING_RECURRING_CLASS } from "../../../app/strings";
import { can_access_page } from "../../../app/user_utils";
import { show_error_toast } from "../../../app/ui_utils";

const AddRecurringClass = () => {
    const [form_data, set_form_data] = useState({
        // date_string: getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ),
        day_of_week: DAYS_OF_WEEK[1].value,
        class_level: CLASS_LEVELS[0].value,
        time_of_day: TIME_SLOTS[0].start,
        end_time: TIME_SLOTS[0].end,
        room: ROOMS[0].value,
        center: ROOMS[0].center + " - Shop " + ROOMS[0].shop,
        selected_teacher: null,
        student_name: "",
        selected_students: [],
    });

    const { day_of_week, class_level, time_of_day, end_time, center, room, selected_teacher, selected_students } = form_data;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    // const { settings } = useSelector(state => state.settings);
    const { is_adding_recurring_class, is_added_recurring_class, new_recurring_class } = useSelector(state => state.recurring_classes);

    const { teachers, is_success_reading_teachers } = useSelector(state => state.teachers);

    const [ formElement, setFormElement ] = useState(null);

    const [search_teacher_query, set_search_teacher_query] = useState("");
    const [teacher_results, set_teacher_results] = useState([]);

    // Redirect if not logged in or does not have access
    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Add_Recurring_Class) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_lesson_form"));
    }, []);

    useEffect(() => {

        if (is_added_recurring_class && new_recurring_class)
        {
            dispatch( resetAddRecurringClass() );

            navigate( PATH_NAME.Admin_School_Schedule );
        }
        
    }, [is_added_recurring_class, new_recurring_class, navigate, dispatch]);

    useEffect(() => {
        
        if (is_success_reading_teachers && teachers)
        {
            set_teacher_results(teachers);
        }

        // This represents the unmount function,
        // in which it's a good idea to reset to prevent memory leaks.
        return () => {
            dispatch(resetTeachers());
        };

    }, [teachers, is_success_reading_teachers, dispatch]);

    const handle_week_day_change = (event) => {

        const selected_day = DAYS_OF_WEEK.find(day => day.value === event.target.value);
        
        set_form_data((prev_data) => ({
            ...prev_data,
            day_of_week: selected_day.value,
        }));

        // console.log("AddLesson.js >> handle_class_level_change :: class_level: ", class_level);
    };

    const handle_class_level_change = (event) => {

        const selected_level = CLASS_LEVELS.find(level => level.value === event.target.value);
        
        set_form_data((prev_data) => ({
            ...prev_data,
            class_level: selected_level.value,
        }));

        // console.log("AddLesson.js >> handle_class_level_change :: class_level: ", class_level);
    };

    const handle_time_change = (event) => {

        // const selected_time = event.target.value;
        const selected_time = TIME_SLOTS.find(slot => slot.value === event.target.value);

        set_form_data((prev_data) => ({
            ...prev_data,
            time_of_day: selected_time.start,
            end_time: selected_time.end,
        }));
    };

    // Handle input change for teacher search
    const handle_teacher_search = async (e) => {
        const query = e.target.value;
        set_search_teacher_query(query);

        const nameRegex = /^[A-Za-z]+(?:\.?\s?[A-Za-z]+)*$/;

        // Make sure there is at least one letter.
        // The query can have alphabetical letters, dots, and optional spaces after dots,
        // but no spaces before dots and no leading or trailing spaces.
        if (query.length > 0 && nameRegex.test(query))
        {
            try
            {
                // console.log("AddLesson.js >> handle_teacher_search :: query: ", query);
                dispatch(getTeachersByName(query));  
            }
            catch (error)
            {
                console.error("Failed to fetch teachers", error);
            }
        }
        else
        {
            set_teacher_results([]);
        }
    };

    // Handle adding teacher to the selected list
    const handle_add_teacher = (teacher) => {
        // Only one teacher can be selected
        if (form_data.selected_teacher)
        {
            return;
        }

        if (!form_data.selected_teacher)
        {
            set_form_data({
                ...form_data,
                selected_teacher: teacher
            });
        }
    };

    // Handle removing teacher from the selected list
    const handle_remove_teacher = () => {
        set_form_data({
            ...form_data,
            selected_teacher: null
        });
    };

    const handle_room_change = (event) => {
        const selected_room = event.target.value;
        set_form_data((prev_data) => ({
            ...prev_data,
            room: selected_room,
            center: ROOMS.find(room => room.value === selected_room).center + " - Shop " + ROOMS.find(room => room.value === selected_room).shop,
        }));
    };

    // Handle adding student to the selected list
    const handle_add_student = (student) => {
        if (!form_data.selected_students.find(s => s.student_id === student.student_id))
        {
            set_form_data({
                ...form_data,
                selected_students: [...form_data.selected_students, student]
            });
        }
    };

    // Handle removing student from the selected list
    const handle_remove_student = (studentId) => {
        set_form_data({
            ...form_data,
            selected_students: form_data.selected_students.filter(s => s.student_id !== studentId)
        });
    };

    const submit_form = e => {
        e.preventDefault();

        if (!selected_students || selected_students.length === 0)
        {
            show_error_toast(UI_STRING_ADD_LESSON_ADMIN.Toast_Error_Missing_Students);
        }
        else if (!selected_teacher)
        {
            show_error_toast(UI_STRING_ADD_LESSON_ADMIN.Toast_Error_Missing_Teacher);
        }
        else
        {
            const recurring_class_data = {
                                    class_level,
                                    day_of_week,
                                    time_of_day,
                                    end_time,
                                    center,
                                    room,
                                    teacher: selected_teacher,
                                    students: selected_students,
                                 };

            // console.log("AddRecurringClass.js >> submit_form :: recurring_lesson_data: ", recurring_class_data);

            dispatch( addRecurringClass(recurring_class_data) );
        }
    };

    const cancel_add_class = e => {

        e.preventDefault();

        // Return to School Schedule
        navigate( PATH_NAME.Admin_School_Schedule );
    };


    if (is_adding_recurring_class)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Adding_Recurring_Class }/>; // Handle loading state
    }

    return (
        (
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center"><MdOutlineEventRepeat className="mr-8"/>
                            {UI_STRING_RECURRING_CLASS.Add_Recurring_Class_Title}
                        </h1>
                    </section>
                    
                    <section className="form">
                        <form id="add_lesson_form" onKeyDown={preventFormSubmitOnEnter}>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Day of Week:</label>

                                <select
                                    className="form_control"
                                    name="day_of_week"
                                    id="day_of_week"
                                    onChange={handle_week_day_change}
                                    defaultValue={DAYS_OF_WEEK[1].value} // Default to Tuesday
                                >
                                    {DAYS_OF_WEEK.map((day_of_week, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={day_of_week.value}
                                            disabled={day_of_week.disabled}
                                        >
                                            {day_of_week.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">

                                <label className="text-gray-700 font-bold text-xl">Select Time Slot:</label>

                                <select
                                    className="form_control"
                                    name="time"
                                    id="time"
                                    onChange={handle_time_change}
                                    defaultValue={TIME_SLOTS[0].value} // Default to the first time slot
                                >
                                    {TIME_SLOTS.map((slot, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={slot.value}
                                        >
                                            {slot.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Class Level:</label>

                                <select
                                    className="form_control"
                                    name="level"
                                    id="level"
                                    onChange={handle_class_level_change}
                                    defaultValue={CLASS_LEVELS[0].value} // Default to the first level
                                >
                                    {CLASS_LEVELS.map((level, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={level.value}
                                        >
                                            {level.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Classroom:</label>

                                <select
                                    className="form_control"
                                    name="room"
                                    id="room"
                                    onChange={handle_room_change}
                                    defaultValue={ROOMS[0].value} // Default to the first room
                                >
                                    {ROOMS.map((room, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={room.value}
                                        >
                                            {room.label + " (Shop " + room.shop + ")"}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Teacher Field */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Teacher for this Class:</label>
                                <ul>
                                    {selected_teacher ? (
                                        <li className="text-white text-left font-bold text-xl">
                                            <button type="button" onClick={() => handle_remove_teacher()}>
                                                [-] &nbsp; {selected_teacher.display_name}
                                            </button>
                                        </li>
                                        ) : (
                                            <></>
                                    )}
                                </ul>
                            </div>

                            {/* Teacher Search Field */}
                            <div className="form_group">
                                {/* <label className="text-gray-700 font-bold text-xl">Search Teacher by Name:</label> */}
                                <input
                                    type="text"
                                    className="form_control"
                                    placeholder="Enter teacher's name to search"
                                    value={search_teacher_query}
                                    onChange={handle_teacher_search}
                                />
                                <ul className="search_results">
                                    {teacher_results.map((teacher) => (
                                        <li className="text-gray-800 text-left text-xl" key={teacher.username}>
                                            <button type="button" onClick={() => handle_add_teacher(teacher)}>
                                                [+] &nbsp; {teacher.display_name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Students List */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Students Present in this Class:</label>
                                <ul>
                                    {form_data.selected_students.map((student) => (
                                        <li className="text-white text-left text-xl" key={student.student_id}>
                                            <button type="button" onClick={() => handle_remove_student(student.student_id)}>
                                                [-] &nbsp; {student.student_name + " (" + student.student_id + ")"}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <StudentSearchComponent
                                is_multiple={ true }
                                on_result_click={ handle_add_student }
                            />

                            <div className="form_group mt-12">
                                <button className="btn btn_block" onClick={submit_form}>
                                    {UI_STRING_ADD_LESSON_ADMIN.Button_Add_To_System}
                                </button>
                            </div>

                            <div className="form_group mt-12">
                                <button className="btn_gray btn_block" onClick={cancel_add_class}>
                                    {UI_STRING_ADD_LESSON_ADMIN.Button_Cancel}
                                </button>
                            </div>
                        </form>
                    </section>
                </div>

                <FooterAdmin />
            </div>
            </>
        )
    )
};

export default AddRecurringClass;