import React, { useState } from "react";
import { Tooltip } from "react-tooltip";   
import { useNavigate } from "react-router-dom"; 
// import { useDispatch } from "react-redux";
// import { addLesson, resetAddLesson } from "../features/lessons/lessonSlice";
// import Spinner from "./Spinner";
import { FaChevronDown, FaChevronUp, FaPen, FaClone } from 'react-icons/fa';
import { PATH_NAME } from "../app/constants";
import { UI_STRING_LESSON_BAR } from "../app/strings";

const LessonAccordion = ({ this_lesson, this_teacher, index }) => {

    const [is_open, set_is_open] = useState(false);

    // const { new_lesson, is_adding_lesson, is_added_lesson } = useSelector(state => state.lessons);

    // const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (is_added_lesson && new_lesson)
    //     {
    //         // Go to the edit page
    //         navigate( PATH_NAME.Admin_Update_Lesson + "/" + new_lesson._id );

    //         dispatch( resetAddLesson() );
    //     }

    // }, [is_added_lesson, new_lesson, navigate, dispatch]);

    const toggle_accordion = () => {
        set_is_open(!is_open);
    };

    const go_to_student_page = (student_id, student_name) => () => {

        // console.log("LessonAccordion.js >> go_to_student_page :: student_id: ", student_id);

        if (this_teacher)
        {
            navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + student_id + "/" + student_name);
        }
        // Admin access
        else
        {
            navigate(PATH_NAME.Admin_Student_Reports + "/" + student_id + "/" + student_name);
        }
    };

    const go_to_edit_lesson = (lesson_id) => () => {
        
        // console.log("LessonAccordion.js >> go_to_edit_lesson :: lesson_id: ", lesson_id);

        navigate(PATH_NAME.Admin_Update_Lesson + "/" + lesson_id);
    };

    const clone_lesson = (curr_lesson) => () => {

        const lesson_data = {
            class_level: curr_lesson.class_level,
            date_string: curr_lesson.date_string,
            time_of_day: curr_lesson.time_of_day,
            end_time: curr_lesson.end_time,
            center: curr_lesson.center,
            room: curr_lesson.room,
            teacher: curr_lesson.teacher,
            students: curr_lesson.students };

        // console.log("LessonAccordion.js >> clone_lesson :: lesson_data: ", lesson_data);

        // dispatch( addLesson(lesson_data) );

        // Navigate to a new page to clone the lesson,
        // just so we can use the Spinner component outside of the table.
        navigate( PATH_NAME.Admin_Clone_Lesson + "/" + curr_lesson._id,
                    { state: lesson_data }
         );
    };

    return (
        <>
            <tr key={index}
                className={`pl-4 ${this_teacher && this_teacher.display_name === this_lesson.teacher?.display_name? "bg-cyan-100" : "bg-white"}`}
                onClick={toggle_accordion}
            >
                <td className="px-4 py-4 whitespace-nowrap rounded-l-xl" data-tooltip-id="expand_icon">
                    <div className="flex items-center">
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                {is_open ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>
                    </div>
                    {!is_open &&
                    <Tooltip id="expand_icon" content={UI_STRING_LESSON_BAR.Tooltip_Click_To_Expand} />
                    }
                </td>

                <td className="px-4 py-4 whitespace-nowrap">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_lesson.class_level}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-hots-red">
                                {this_lesson.room}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_lesson.teacher ? this_lesson.teacher.display_name : "Filler Teacher"}
                            </div>
                        </div>
                    </div>
                </td>

                <td className={`py-4 whitespace-nowrap font-medium ${this_teacher ? "rounded-r-xl" : ""}`}>
                
                    <div className="flex items-left">
                        <div className="">
                            <div className="font-medium text-left text-hots-purple">
                                # Students: {this_lesson.students.length}
                            </div>
                        </div>
                    </div>
                    {/* <button className="btn_item">
                        <FaPen />
                    </button> */}
                </td>
                    
                {
                // Only show the edit and clone buttons for Admin
                !this_teacher &&
                <>
                <td className="px-4 py-4 whitespace-nowrap">
                    <div className="flex items-center" data-tooltip-id="edit_icon"
                            onClick={go_to_edit_lesson(this_lesson._id)}>
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                <FaPen />
                            </div>
                        </div>
                        <Tooltip id="edit_icon" content={UI_STRING_LESSON_BAR.Tooltip_Edit_This_Class} />
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center" data-tooltip-id="clone_icon"
                            onClick={clone_lesson(this_lesson)}>
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                <FaClone />
                            </div>
                        </div>
                        <Tooltip id="clone_icon" content={UI_STRING_LESSON_BAR.Tooltip_Clone_This_Class} />
                    </div>
                </td>
                </>
                }
            </tr>

            {is_open && (
                <tr className="pl-4 bg-gray-200">
                    <td colSpan={this_teacher ? "5" : "7"}
                        className="px-6 py-4 whitespace-nowrap rounded-bl-xl rounded-br-xl rounded-tr-xl">
                        <div className="flex flex-col items-left">
                            <div className="ml-4">
                                <div className="font-medium text-left text-gray-900">Attendance:</div>
                                {this_lesson.students && this_lesson.students.length > 0 ? (
                                    <ul className="list-disc ml-6">
                                        {this_lesson.students.map((curr_student, idx) => (
                                            <li key={idx} className="text-left text-gray-700
                                                                    hover:cursor-pointer hover:text-hots-green-1"
                                                onClick={ go_to_student_page(curr_student.student_id, curr_student.student_name) }
                                            >
                                                {curr_student.student_name + " (" + curr_student.student_id + ")"}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="text-left text-gray-700">No students assigned to this lesson.</div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default LessonAccordion;