import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { getSummariesByStudent, resetGetStudentSummariesState, } from "../../../features/summaries/summarySlice";
import Spinner from "../../Spinner";
import { FaTools, FaLink } from "react-icons/fa";
import { ImNewTab } from "react-icons/im";
import { IoDocumentTextOutline } from "react-icons/io5";
import { getDate_HKT, DATE_FORMAT_DDMMYYYY } from "../../../app/date_utils";
import { PATH_NAME } from "../../../app/constants";
import { UI_STRING_LOADING_ALL } from "../../../app/strings";

const StudentSummariesTable = ({ student_id, student_name }) => {

  const curr_student_id = student_id ? student_id : null;
  const curr_student_name = student_name ? student_name : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canRenderTable, setCanRenderTable] = useState(false);

  const { student_summaries, is_loaded_student_summaries } = useSelector((state) => state.summaries);

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [current_summaries, setCurrentSummaries] = useState([]);

  const handleDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {

    // if (is_error)
    // {
    //     console.log(message);
    // }

    if (curr_student_id === null)
    {
        navigate( PATH_NAME.Dashboard);

        return;
    }

    dispatch( getSummariesByStudent( curr_student_id ) );

    return () => {
        dispatch( resetGetStudentSummariesState() );
    };
  }, [navigate, curr_student_id, dispatch]);

  useEffect(() => {

    if (is_loaded_student_summaries && student_summaries)
    {
      setTotalItems(student_summaries.length);
      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      setCurrentSummaries(student_summaries.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      ));

      setCanRenderTable(true);
    }
  }, [is_loaded_student_summaries, student_summaries, currentPage, totalItems]);

  const go_to_update_summary = (shareable_id) => () => {

    navigate( PATH_NAME.Admin_Update_Summary + "/" + shareable_id + PATH_NAME.Edit_Mode );

  };

  const goToStudentReportPage = () => {

    if (curr_student_id && curr_student_name)
    {
        navigate( PATH_NAME.Admin_Student_Reports + "/" + curr_student_id + "/" + curr_student_name );
    }
  };

  if (!canRenderTable)
  {
      return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Student_Summaries }/>;
  }

  return (
    
    // !canRenderTable ? <Spinner /> :
      (
      <>
      <div className="p-4 tracking-wider">

        <div className="mt-6 mb-20 text-center">

          <button className="btn_white_outline btn_block w-5/6 mt-4" onClick={() => goToStudentReportPage()}>
            <IoDocumentTextOutline />&nbsp;Student's Report Page
          </button>
        </div>

        <div className="flex items-center mb-8 ml-10">
          <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
          <div>
            <select className="border rounded p-2" onChange={handleDropdownChange} value={currentPage}>
              {Array.from({ length: totalPages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto mb-32">
          <table className="px-8 min-w-full table-auto rounded-3xl
                            border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
            <thead className="bg-transparent text-xl">
              <tr>
                <th className="px-6 py-3 text-left font-extrabold text-white">Date Generated</th>
                <th className="px-6 py-3 text-left font-extrabold text-white flex items-center">
                  Public Link <FaLink className="ml-2" />
                </th>
                <th className="px-6 py-3 text-left font-extrabold text-white">Prepare Summary</th>
              </tr>
            </thead>

            <tbody className="bg-transparent text-xl">

              {current_summaries.length > 0 && current_summaries.map((summary, index) => (

                <tr key={index}
                  className={`pl-4 ${ (summary.status.includes(" - Signed") && summary.admin_signature) ? "bg-white" : "bg-rose-100"}`}
                >

                  <td className="px-6 py-4 whitespace-nowrap rounded-l-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{getDate_HKT( summary.createdAt, DATE_FORMAT_DDMMYYYY )}</div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <Link to={PATH_NAME.Parent_View_Summary + "/" + summary.shareable_id} target="_blank" rel="noopener noreferrer" >
                        <button className="btn_item"><ImNewTab /></button>
                        </Link>
                        
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        {/* <Link to="/sample_summary/66b643d0e1f5412fbbbf0168" target="_blank" rel="noopener noreferrer"> */}
                        <button className="btn_item" onClick={go_to_update_summary( summary.shareable_id )}><FaTools /></button>
                        {/* </Link> */}
                        
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

              <tr className="my-12">
                <th colSpan="4" className="px-6 py-6 text-left font-medium text-white">
                <div className="text-white text-xl">
                  {totalItems === 0 ? (
                    "No entries to show"
                    ) : totalItems === 1 ? (
                    `Showing: 1 of 1 entry`
                    ) : (
                    `Showing: ${1 + (currentPage - 1) * ITEMS_PER_PAGE} to ${Math.min(currentPage * ITEMS_PER_PAGE, totalItems)} of ${totalItems} entries`
                  )}
                </div>
                </th>
              </tr>

            </tbody>
          </table>

        </div>

      </div>
      
      </>
      )
  );
};

export default StudentSummariesTable;
