import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import adminAlertService from "./adminAlertService";
// import authService from '../auth/authService';

const initialState_Fetch = {
    admin_alerts: [],
    is_fetching_alerts: false,
    is_fetched_alerts: false,
    is_error_fetching_alerts: false,
    message_fetching_alerts: "",
}

const initialState_Close = {
    is_closing_alert: false,
    is_closed_alert: false,
    is_error_closing_alert: false,
    message_closing_alert: "",
}

const initialState = {
    ...initialState_Fetch,
    ...initialState_Close,
};

export const createAdminAlert = createAsyncThunk(
    "admin_alerts/create",
    async (alert_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await adminAlertService.createAdminAlert(alert_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getOpenAdminAlerts = createAsyncThunk(
    "admin_alerts/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await adminAlertService.getOpenAdminAlerts(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const closeAdminAlert = createAsyncThunk(
    "admin_alerts/close",
    async (id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("adminAlertSlice.js >> closeAdminAlert: id = " + id + "; token = " + token);
            return await adminAlertService.closeAdminAlert(id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const adminAlertSlice = createSlice({
    name: "admin_alerts",
    initialState,
    reducers: {
        resetAdminAlerts: state => initialState,
        resetFetchAdminAlerts: state => initialState_Fetch,
        resetCloseAdminAlerts: state => initialState_Close,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAdminAlert.pending, state => {
                state.is_loading = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(createAdminAlert.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_success = true;
                state.admin_alerts.push(action.payload);
            })
            .addCase(createAdminAlert.rejected, (state, action) => {
                state.is_loading = false;
                state.is_error = true;
                state.message = action.payload;
            })

            .addCase(getOpenAdminAlerts.pending, (state) => {
                state.is_fetching_alerts = true;
                state.is_fetched_alerts = false;
                state.is_error_fetching_alerts = false;
                state.message_fetching_alerts = "";
            })
            .addCase(getOpenAdminAlerts.fulfilled, (state, action) => {
                state.is_fetching_alerts = false;
                state.is_fetched_alerts = true;
                state.is_error_fetching_alerts = false;
                state.admin_alerts = action.payload;
            })
            .addCase(getOpenAdminAlerts.rejected, (state, action) => {
                state.is_fetching_alerts = false;
                state.is_fetched_alerts = false;
                state.is_error_fetching_alerts = true;
                state.message_fetching_alerts = action.payload;
            })

            .addCase(closeAdminAlert.pending, (state) => {
                state.is_closing_alert = true;
                state.is_closed_alert = false;
                state.is_error_closing_alert = false;
                state.message_closing_alert = "";
            })
            .addCase(closeAdminAlert.fulfilled, (state, action) => {
                state.is_closing_alert = false;
                state.is_closed_alert = true;
                state.is_error_closing_alert = false;

                // Assuming action.payload contains the id of the closed alert
                const closed_alert_id = action.payload.id;
                
                // Remove the closed alert from the admin_alerts array
                state.admin_alerts = state.admin_alerts.filter(
                    alert => alert._id !== closed_alert_id
                );
            })
            .addCase(closeAdminAlert.rejected, (state, action) => {
                state.is_closing_alert = false;
                state.is_closed_alert = false;
                state.is_error_closing_alert = true;
                state.message_closing_alert = action.payload;
            });
    }
});

export const { resetAdminAlerts, resetFetchAdminAlerts, resetCloseAdminAlerts } = adminAlertSlice.actions;
export default adminAlertSlice.reducer;