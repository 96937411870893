import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { getLessonsByDate, resetFetchLessonsByDate } from "../features/lessons/lessonSlice";
import Spinner from "./Spinner";
import LessonAccordion from "./LessonAccordion";
// import { FaPen } from "react-icons/fa";
import { TIME_SLOTS } from "../app/constants";
import { UI_STRING_LOADING_ALL } from '../app/strings';

// the_date is a string in the format "DD-MM-YYYY"
const LessonTable = ({ the_date, the_teacher }) => {
    
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    

    const { lessons_on_date,
                is_fetching_lessons_by_date,
                is_fetched_lessons_by_date } = useSelector((state) => state.lessons);

    const [ the_lessons, set_the_lessons ] = useState([]);

    const [ should_render, set_should_render ] = useState(false);

    const filter_lessons_by_time_slot = (lesson_list, start_time, end_time) =>
    {
        // Filtering exact matches now
        return lesson_list.filter(l => l.time_of_day === start_time && l.end_time === end_time);
        // return lesson_list.filter(l => l.time_of_day >= start_time && l.time_of_day <= end_time);
    };

    useEffect(() => {

        // console.log("LessonTable.js >> useEffect :: formatted_date: ", formatted_date);

        dispatch( getLessonsByDate(the_date) );

        // if (the_teacher && the_teacher.length === 0)
        // Null means all teachers
        // if (!the_teacher)
        // {
        //     // Get date string in format, "mm/dd/yyyy"
        //     // let formatted_date = getDate_HKT( the_date, DATE_FORMAT_DDMMYYYY );

        //     // console.log("LessonTable.js >> useEffect :: Fetching lessons by date, for all teachers...");

        //     // Reset the lessons state before fetching
        //     // resetFetchLessonsByDate();

        //     dispatch( getLessonsByDate(the_date) );
        // }

    }, [
        // the_teacher,
        the_date, dispatch]);

    useEffect(() => {

        if (is_fetched_lessons_by_date && lessons_on_date)
        {
            // console.log("LessonTable.js >> useEffect :: Setting the lessons...");

            set_the_lessons(lessons_on_date);

            set_should_render(true);

            return () => {
              dispatch( resetFetchLessonsByDate() );
            };
        }

    }, [is_fetched_lessons_by_date, lessons_on_date, dispatch]);

    if (is_fetching_lessons_by_date && !should_render)// || !lessons_on_date)
    {
        // console.log("LessonTable.js >> Is fetching lessons by date...");
        return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Lessons }/>;
    }  


  return (

    (should_render) && (
    
      <>
      <div className="p-4 tracking-wider">
        
        <div className="overflow-x-auto">

          {TIME_SLOTS.map((slot, slot_index) => {
              const filtered_lessons = filter_lessons_by_time_slot(the_lessons, slot.start, slot.end);
              return (
                  <table
                      key={slot_index}
                      className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3 mb-8"
                  >
                      <thead className="bg-transparent text-xl">
                          <tr>
                              <th colSpan="5" className="px-6 py-3 text-left font-extrabold text-hots-purple">
                                  {slot.label}
                              </th>
                          </tr>
                      </thead>
                      <tbody className="bg-transparent text-xl">
                          {filtered_lessons.length > 0 ? (
                              filtered_lessons.map((this_lesson, index) => (
                                <LessonAccordion key={index} this_lesson={this_lesson} this_teacher={the_teacher} index={index} />
                              ))
                          ) : (
                              <tr>
                                  <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                      No classes scheduled for this time slot.
                                  </td>
                              </tr>
                          )}
                          <tr className="my-12">
                              <th colSpan="5" className="px-6 py-6 text-left font-medium text-white">
                                  <div className="text-white text-xl">
                                      {filtered_lessons.length === 0 ? (
                                          "No entries to show"
                                      ) : filtered_lessons.length === 1 ? (
                                          `Showing: 1 entry`
                                      ) : (
                                          `Showing: ${filtered_lessons.length} entries`
                                      )}
                                  </div>
                              </th>
                          </tr>
                      </tbody>
                  </table>
              );
          })}
      </div>

        <div className="flex justify-between items-center mt-4 ml-10">
        

        </div>
      </div>
      </>
      )
  );
};

export default LessonTable;
