import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        RATINGS,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_8_HE,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm8HEWeek3 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`${UI_STRING_REPORT_8_HE.Week_3}`}
    </div>

    {/* Textarea: Topic Section */}
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_8_HE.Key_Topic}
        name="key_topic"
        value={formData.content.key_topic}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_topic"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_1}`}
        name="_3_1_"
        value={formData.content._3_1_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_2}`}
        name="_3_2_"
        value={formData.content._3_2_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_3}`}
        name="_3_3_"
        value={formData.content._3_3_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_4}`}
        name="_3_4_"
        value={formData.content._3_4_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_5}`}
        name="_3_5_"
        value={formData.content._3_5_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_5_"]}
    />
    
    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_6}`}
        name="_3_6_"
        value={formData.content._3_6_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_7}`}
        name="_3_7_"
        value={formData.content._3_7_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_8}`}
        name="_3_8_"
        value={formData.content._3_8_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_8_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_9}`}
        name="_3_9_"
        value={formData.content._3_9_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_9_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_8_HE.Week_3_Metric_10}`}
        name="_3_10_"
        value={formData.content._3_10_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_10_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Checkbox: Can Advance Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_8_HE.Can_Advance}
        name="can_advance"
        value={formData.content.can_advance}
        type="checkbox"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["can_advance"]}
    />
    
    </div>
  );
};

export default UpdateReportForm8HEWeek3;