import { useLocation } from "react-router-dom";
import { MdSchedule } from "react-icons/md";
import { PATH_NAME } from "../app/constants";
import { UI_STRING_HEADER_ALL } from "../app/strings";

const TabButtonTodaySchedule = () => {

    const location = useLocation();

    return (
        location.pathname === PATH_NAME.Admin_Dashboard || location.pathname === PATH_NAME.Teacher_Dashboard
        ? (
            <button className="btn_white_outline_highlighted">
                <MdSchedule />&nbsp;{UI_STRING_HEADER_ALL.Button_Today_Schedule}
            </button>
        ) : (
            <button className="btn_white_outline">
                <MdSchedule />&nbsp;{UI_STRING_HEADER_ALL.Button_Today_Schedule}
            </button>
        )
    );
};

export default TabButtonTodaySchedule;