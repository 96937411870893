import { useEffect, useState, useCallback } from "react";
import { TiDocumentAdd } from "react-icons/ti";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { generateAdHocReport, resetGenerateAdHocReportState } from "../../features/reports/reportSlice";
import Spinner from "../Spinner";
import FooterTeacher from "./FooterTeacher";
import { PATH_NAME, CLASS_LEVELS, WEEK_NUMBERS_JUNIOR, WEEK_NUMBERS_SENIOR } from "../../app/constants";
import { UI_STRING_LOADING_ALL, UI_STRING_REPORT_ALL, UI_STRING_REPORT_AD_HOC, UI_STRING_REPORT_DETAILS } from "../../app/strings";
import { DATE_FORMAT_DDMMYYYY, getCurrentDate_HKT } from "../../app/date_utils";
import { can_access_page } from "../../app/user_utils";
import { getDate_HKT } from "../../app/date_utils";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_orange.css";  // Import a theme of your choice
import { show_error_toast } from "../../app/ui_utils";

const AddReport = () => {

    // const [ selected_date, set_selected_date ] = useState("");

    const { student_id, student_name } = useParams();

    const this_student_id = student_id ? student_id : null;
    const this_student_name = student_name ? student_name : null;

    const [form_data, set_form_data] = useState({
        date_string: getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ),
        class_level: "",//CLASS_LEVELS[0].value,
        week_number: "",//WEEK_NUMBERS_JUNIOR[0].value,
        // time_of_day: "",//TIME_SLOTS[0].start,
        // end_time: "",//TIME_SLOTS[0].end,
        // room: "",//ROOMS[0].value,
        // center: "",//ROOMS[0].center + " - Shop " + ROOMS[0].shop,
        // selected_teacher: null,
        // student_name: "",
        // selected_students: [],
    });

    const { date_string, class_level, week_number } = form_data;
    // const { date_string, time_of_day, end_time, class_level, week_number, center, room } = form_data;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    
    const { new_ad_hoc_report, is_generating_ad_hoc_report, is_generated_ad_hoc_report } = useSelector(state => state.reports);
    // const { is_adding_lesson, is_added_lesson, new_lesson } = useSelector(state => state.lessons);

    const [ formElement, setFormElement ] = useState(null);

    // const { is_generated_blank_report, is_generating_blank_report } = useSelector(state => state.reports);
    // const [ num_reports_generated, set_num_reports_generated ] = useState(0);

    const [week_options, set_week_options] = useState(WEEK_NUMBERS_JUNIOR);
    const [is_loading_week_options, set_is_loading_week_options] = useState(false);

    // Redirect if not logged in or does not have access
    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Teacher_Add_Report) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_report_form"));
    }, []);

    useEffect(() => {
        if (is_generated_ad_hoc_report && new_ad_hoc_report)
        {
            // The new report object contains the report and the admin alert

            // console.log("AddReport.js >> useEffect >> new_ad_hoc_report: ", new_ad_hoc_report);

            const cached_report = new_ad_hoc_report.report;

            // Go straight to Step 2 of the new report
            // Caching the report, so that it does not need to be fetched again
            navigate( PATH_NAME.Teacher_Update_Report
                            + "/" + cached_report.class_level
                            + "/" + cached_report.week_number
                            + "/" + cached_report._id
                            + PATH_NAME.Edit_Mode, {
                state: { cached_report }
            } );

            // return () => {
            resetGenerateAdHocReportState();
            // }
        }
    }, [is_generated_ad_hoc_report, new_ad_hoc_report, navigate]);

    const handle_class_level_change = (event) => {

        // const selected_level = event.target.value;
        const selected_level = CLASS_LEVELS.find(level => level.value === event.target.value);

        // set_selected_level(curr_level.value);

        set_is_loading_week_options(true); // Start loading before options update

        setTimeout(() => {
            if (selected_level.type === "Junior")
            {
                set_week_options( WEEK_NUMBERS_JUNIOR );
            }
            else
            {
                set_week_options( WEEK_NUMBERS_SENIOR );
            }

            set_is_loading_week_options(false); // End loading after options update

        }, 1000); // Simulate a delay for updating options
        
        set_form_data((prev_data) => ({
            ...prev_data,
            class_level: selected_level.value,
        }));
    };

    // const handle_time_change = (event) => {

    //     // const selected_time = event.target.value;
    //     const selected_time = TIME_SLOTS.find(slot => slot.value === event.target.value);

    //     set_form_data((prev_data) => ({
    //         ...prev_data,
    //         time_of_day: selected_time.start,
    //         end_time: selected_time.end,
    //     }));
    // };

    const handle_week_number_change = (event) => {
        const selected_week = event.target.value;
        set_form_data((prev_data) => ({
            ...prev_data,
            week_number: selected_week,
        }));
    };

    // const handle_room_change = (event) => {
    //     const selected_room = event.target.value;
    //     set_form_data((prev_data) => ({
    //         ...prev_data,
    //         room: selected_room,
    //         center: ROOMS.find(room => room.value === selected_room).center + " - Shop " + ROOMS.find(room => room.value === selected_room).shop,
    //     }));
    // };

    const handle_date_change = (date) => {

        // console.log("AddReport.js >> handle_date_change >> date: ", date);

        const formattedDate = getDate_HKT(date[0], DATE_FORMAT_DDMMYYYY);

        // console.log("AddReport.js >> handle_date_change >> formattedDate: ", formattedDate);

        // set_selected_date(formattedDate);

        set_form_data((prev_data) => ({
            ...prev_data,
            date_string: formattedDate,
        }));
    };

    const submit_form = e => {
        e.preventDefault();

        if (date_string === "")
        {
            show_error_toast(UI_STRING_REPORT_DETAILS.Toast_Error_Missing_Date);

            return;
        }

        if (class_level === "")
        {
            show_error_toast(UI_STRING_REPORT_AD_HOC.Toast_Error_Missing_Class_Level);

            return;
        }

        if (week_number === "")
        {
            show_error_toast(UI_STRING_REPORT_AD_HOC.Toast_Error_Missing_Week_Number);

            return;
        }

        // if (time_of_day === "")
        // {
        //     show_error_toast(UI_STRING_REPORT_AD_HOC.Toast_Error_Missing_Time_Slot);
            
        //     return;
        // }

        // if (room === "")
        // {
        //     show_error_toast(UI_STRING_REPORT_AD_HOC.Toast_Error_Missing_Classroom);

        //     return;
        // }

        const report_data = {
                    date_string,//: getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ),
                    class_level,
                    week_number,
                    // time_of_day,
                    // end_time,
                    // center,
                    // room,
                    teacher_id: user?._id,
                    student_id: this_student_id };

        // console.log("AddReport.js >> submit_form >> report_data: ", report_data);

        dispatch( generateAdHocReport(report_data) );
    };

    const return_to_student_page = (event) => {

        event.preventDefault();

        navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + this_student_id + "/" + this_student_name);
    };
    
    if (is_generating_ad_hoc_report)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Generating_The_Report }/>;
    }

    return (
        (
            !is_generating_ad_hoc_report &&
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center">
                            <TiDocumentAdd className="mr-8"/>Add an ad hoc report
                        </h1>
                    </section>
                    
                    <section className="form">
                        <form id="add_report_form" onKeyDown={preventFormSubmitOnEnter}>

                            <div className="form_group">

                                <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Student:</label>
                                    <label className="text-gray-200 font-medium text-xl">{this_student_name} ({this_student_id})</label>
                                </div>

                                <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Teacher:</label>
                                    <label className="text-gray-200 font-medium text-xl">{ user?.display_name }</label>
                                </div>

                                <hr className="mt-8 mb-8"></hr>

                                <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Lesson Date:</label>
                                    {/* <label className="text-gray-200 font-medium text-xl">{selected_date || 'No date selected'}</label> */}

                                    <Flatpickr
                                        className="form-control mt-2"
                                        // value={selected_date}
                                        value={ getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ) }
                                        onChange={handle_date_change}
                                        options={{ dateFormat: "d-m-Y" }}
                                    />
                                </div>

                                {/* <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Today's Date:</label>
                                    <label className="text-gray-200 font-medium text-xl">{getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY )}</label>
                                </div> */}

                                {/* <label className="text-gray-700 font-bold text-xl">Select Time Slot:</label>

                                <select
                                    className="form_control"
                                    name="time"
                                    id="time"
                                    onChange={handle_time_change}
                                    defaultValue="" // Default to the placeholder option
                                    // defaultValue={TIME_SLOTS[0].value} // Default to the first time slot
                                >
                                    <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                    {TIME_SLOTS.map((slot, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={slot.value}
                                        >
                                            {slot.label}
                                        </option>
                                    ))}
                                </select> */}
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Class Level:</label>

                                <select
                                    className="form_control"
                                    name="level"
                                    id="level"
                                    onChange={handle_class_level_change}
                                    defaultValue="" // Default to the placeholder option
                                    // defaultValue={CLASS_LEVELS[0].value} // Default to the first level
                                >
                                    <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                    {CLASS_LEVELS.map((level, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={level.value}
                                        >
                                            {level.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Week Number:</label>
                                <select
                                    className="form_control"
                                    name="week_number"
                                    id="week_number"
                                    onChange={handle_week_number_change}
                                    defaultValue="" // Default to the placeholder option
                                    disabled={is_loading_week_options} // Disable when loading
                                >
                                    {is_loading_week_options ? (
                                        <option>Please wait...</option> // Display "Please wait..." while loading
                                    ) : (
                                        
                                        <>
                                        <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                        {
                                        week_options.map((week, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={week.value}>
                                            {week.label}
                                        </option>
                                        ))
                                        }
                                        </>
                                    )}
                                </select>
                            </div>

                            {/* <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Classroom:</label>

                                <select
                                    className="form_control"
                                    name="room"
                                    id="room"
                                    onChange={handle_room_change}
                                    defaultValue="" // Default to the placeholder option
                                    // defaultValue={ROOMS[0].value} // Default to the first room
                                >
                                    <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                    {ROOMS.map((room, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={room.value}
                                        >
                                            {room.label + " (Shop " + room.shop + ")"}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            <hr className="mt-8 mb-8"></hr>

                            <div className="form_group mt-12">
                                <button className="btn btn_block" onClick={submit_form}>
                                    {UI_STRING_REPORT_ALL.Button_Add_To_System}
                                </button>
                            </div>

                            <div className="form_group flex justify-center mt-12">
                                <button className="btn btn_gray w-full" onClick={return_to_student_page}>
                                    {UI_STRING_REPORT_ALL.Button_Return}
                                </button>
                            </div>
                        </form>
                    </section>
                </div>

                <FooterTeacher />
            </div>
            </>
        )
    )
};

export default AddReport;