import React, { useState } from "react";
import { Tooltip } from "react-tooltip";   
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp, FaPen } from 'react-icons/fa';
import { PATH_NAME } from "../../../app/constants";
import { UI_STRING_LESSON_BAR } from "../../../app/strings";

const RecurringClassAccordion = ({ this_class, index }) => {

    const [is_open, set_is_open] = useState(false);

    // const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggle_accordion = () => {
        set_is_open(!is_open);
    };

    const go_to_student_page = (student_id, student_name) => () => {

        // console.log("RecurringClassAccordion.js >> go_to_student_page :: student_id: ", student_id);

        navigate(PATH_NAME.Admin_Student_Reports + "/" + student_id + "/" + student_name);

        // if (this_teacher)
        // {
        //     navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + student_id + "/" + student_name);
        // }
        // // Admin access
        // else
        // {
        //     navigate(PATH_NAME.Admin_Student_Reports + "/" + student_id + "/" + student_name);
        // }
    };

    const go_to_edit_class = (recurring_class_id) => () => {
        
        // console.log("LessonAccordion.js >> go_to_edit_lesson :: lesson_id: ", lesson_id);

        navigate(PATH_NAME.Admin_Update_Recurring_Class + "/" + recurring_class_id);
    };

    return (
        <>
            <tr key={index}
                className={`pl-4 ${true ? "bg-cyan-100" : "bg-white"}`}
                onClick={toggle_accordion}
            >
                <td className="px-4 py-4 whitespace-nowrap rounded-l-xl" data-tooltip-id="expand_icon">
                    <div className="flex items-center">
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                {is_open ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>
                    </div>
                    {!is_open &&
                    <Tooltip id="expand_icon" content={UI_STRING_LESSON_BAR.Tooltip_Click_To_Expand} />
                    }
                </td>

                <td className="px-4 py-4 whitespace-nowrap">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_class.class_level}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-hots-red">
                                {this_class.room}
                            </div>
                        </div>
                    </div>
                </td>

                <td className="px-4 py-4 whitespace-nowrap font-medium">
                    <div className="flex items-left">
                        <div className="ml-4">
                            <div className="font-medium text-left text-gray-900">
                                {this_class.teacher ? this_class.teacher.display_name : "Filler Teacher"}
                            </div>
                        </div>
                    </div>
                </td>

                <td className={`py-4 whitespace-nowrap font-medium ${false ? "rounded-r-xl" : ""}`}>
                
                    <div className="flex items-left">
                        <div className="">
                            <div className="font-medium text-left text-hots-purple">
                                # Students: {this_class.students.length}
                            </div>
                        </div>
                    </div>
                </td>
                    
                {
                <>
                <td className="px-4 py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center" data-tooltip-id="edit_icon"
                            onClick={go_to_edit_class(this_class._id)}>
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                <FaPen />
                            </div>
                        </div>
                        <Tooltip id="edit_icon" content={UI_STRING_LESSON_BAR.Tooltip_Edit_This_Class} />
                    </div>
                </td>

                {/* <td className="px-4 py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center" data-tooltip-id="clone_icon"
                            onClick={clone_lesson(this_lesson)}>
                        <div className="ml-4">
                            <div className="font-extrabold text-left text-hots-purple">
                                <FaClone />
                            </div>
                        </div>
                        <Tooltip id="clone_icon" content={UI_STRING_LESSON_BAR.Tooltip_Clone_This_Class} />
                    </div>
                </td> */}
                </>
                }
            </tr>

            {is_open && (
                <tr className="pl-4 bg-gray-200">
                    <td colSpan={"6"}
                        className="px-6 py-4 whitespace-nowrap rounded-bl-xl rounded-br-xl rounded-tr-xl">
                        <div className="flex flex-col items-left">
                            <div className="ml-4">
                                <div className="font-medium text-left text-gray-900">Attendance:</div>
                                {this_class.students && this_class.students.length > 0 ? (
                                    <ul className="list-disc ml-6">
                                        {this_class.students.map((curr_student, idx) => (
                                            <li key={idx} className="text-left text-gray-700
                                                                    hover:cursor-pointer hover:text-hots-green-1"
                                                onClick={ go_to_student_page(curr_student.student_id, curr_student.student_name) }
                                            >
                                                {curr_student.student_name + " (" + curr_student.student_id + ")"}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="text-left text-gray-700">No students assigned to this lesson.</div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default RecurringClassAccordion;