import { Link } from "react-router-dom";
import { PATH_NAME } from "../../app/constants";
import { APP_VERSION_DATE } from "../../app/config";

const FooterAdmin = () => {

    return (
        <footer className="bg-gray-200 py-20 mt-8 shadow-md top-full sticky">
            <Link to={ PATH_NAME.Admin_Dashboard }>
                <p className="text-gray-500 text-xl font-medium">HOTS System - Admin Corner</p>

                <p className="text-gray-500 text-sm font-regular mt-8">{APP_VERSION_DATE}</p>
            </Link>
        </footer>
    );

}

export default FooterAdmin;