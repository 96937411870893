import { UI_STRING_REPORT_2_PINK } from "../../../../app/strings";

// Break them up into smaller arrays for fewer chart lines
export const NUM_SUB_CATEGORIES = [3, 3, 2, 1, 1, 3, 2, 3, 3, 3, 4];
// export const NUM_SUB_CATEGORIES = [6, 2, 1, 1, 5, 3, 10];

export const CHART_DATA_2_PINK = [
    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // OVERALL CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "學生整體進度",
        type: "line",
        label: "整體評分",
        backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)"
        ],
        borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)"
        ],
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 1 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_1 + " (I)",
        type: "line",
        datasets: [
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },
        ]
    },

    {
        title: UI_STRING_REPORT_2_PINK.Group_1 + " (II)",
        type: "line",
        datasets: [
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_1_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },
        ]
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 2 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_2,
        type: "line",
        datasets: [
            { "label": UI_STRING_REPORT_2_PINK.Group_2_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_2_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
        ]
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 3 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_3,
        type: "line",
        label: UI_STRING_REPORT_2_PINK.Group_3,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 4 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_4,
        type: "line",
        label: UI_STRING_REPORT_2_PINK.Group_4,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 5 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_5 + " (I)",
        type: "line",
        datasets: [
            { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },
        ]
    },

    {
        title: UI_STRING_REPORT_2_PINK.Group_5 + " (II)",
        type: "line",
        datasets: [
            // { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
            // { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_5_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },
        ]
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 6 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_6,
        type: "line",
        datasets: [
            { "label": UI_STRING_REPORT_2_PINK.Group_6_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_6_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },
            { "label": UI_STRING_REPORT_2_PINK.Group_6_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },
        ]
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // GROUP 7 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: UI_STRING_REPORT_2_PINK.Group_7 + " (I)",
        type: "line",
        datasets: [
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },  // Red
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },  // Blue
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },  // Yellow
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },  // Teal
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },  // Purple
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },  // Orange
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_7, "borderColor": "rgba(60, 179, 113, 1)", "backgroundColor": "rgba(60, 179, 113, 0.2)" },  // MediumSeaGreen
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_8, "borderColor": "rgba(139, 69, 19, 1)", "backgroundColor": "rgba(139, 69, 19, 0.2)" },  // SaddleBrown
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_9, "borderColor": "rgba(75, 0, 130, 1)", "backgroundColor": "rgba(75, 0, 130, 0.2)" },  // Indigo
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_10, "borderColor": "rgba(220, 20, 60, 1)", "backgroundColor": "rgba(220, 20, 60, 0.2)" }  // Crimson
        ]
    },

    {
        title: UI_STRING_REPORT_2_PINK.Group_7 + " (II)",
        type: "line",
        datasets: [
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },  // Red
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },  // Blue
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },  // Yellow
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },  // Teal
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },  // Purple
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },  // Orange
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_7, "borderColor": "rgba(60, 179, 113, 1)", "backgroundColor": "rgba(60, 179, 113, 0.2)" },  // MediumSeaGreen
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_8, "borderColor": "rgba(139, 69, 19, 1)", "backgroundColor": "rgba(139, 69, 19, 0.2)" },  // SaddleBrown
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_9, "borderColor": "rgba(75, 0, 130, 1)", "backgroundColor": "rgba(75, 0, 130, 0.2)" },  // Indigo
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_10, "borderColor": "rgba(220, 20, 60, 1)", "backgroundColor": "rgba(220, 20, 60, 0.2)" }  // Crimson
        ]
    },

    {
        title: UI_STRING_REPORT_2_PINK.Group_7 + " (III)",
        type: "line",
        datasets: [
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },  // Red
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },  // Blue
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },  // Yellow
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },  // Teal
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },  // Purple
            // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },  // Orange
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_7, "borderColor": "rgba(60, 179, 113, 1)", "backgroundColor": "rgba(60, 179, 113, 0.2)" },  // MediumSeaGreen
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_8, "borderColor": "rgba(139, 69, 19, 1)", "backgroundColor": "rgba(139, 69, 19, 0.2)" },  // SaddleBrown
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_9, "borderColor": "rgba(75, 0, 130, 1)", "backgroundColor": "rgba(75, 0, 130, 0.2)" },  // Indigo
            { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_10, "borderColor": "rgba(220, 20, 60, 1)", "backgroundColor": "rgba(220, 20, 60, 0.2)" }  // Crimson
        ]
    },

    // {
    //     title: UI_STRING_REPORT_2_PINK.Group_7,
    //     type: "line",
    //     datasets: [
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_1, "borderColor": "rgba(255, 99, 132, 1)", "backgroundColor": "rgba(255, 99, 132, 0.2)" },  // Red
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_2, "borderColor": "rgba(54, 162, 235, 1)", "backgroundColor": "rgba(54, 162, 235, 0.2)" },  // Blue
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_3, "borderColor": "rgba(255, 206, 86, 1)", "backgroundColor": "rgba(255, 206, 86, 0.2)" },  // Yellow
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_4, "borderColor": "rgba(75, 192, 192, 1)", "backgroundColor": "rgba(75, 192, 192, 0.2)" },  // Teal
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_5, "borderColor": "rgba(153, 102, 255, 1)", "backgroundColor": "rgba(153, 102, 255, 0.2)" },  // Purple
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_6, "borderColor": "rgba(255, 159, 64, 1)", "backgroundColor": "rgba(255, 159, 64, 0.2)" },  // Orange
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_7, "borderColor": "rgba(60, 179, 113, 1)", "backgroundColor": "rgba(60, 179, 113, 0.2)" },  // MediumSeaGreen
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_8, "borderColor": "rgba(139, 69, 19, 1)", "backgroundColor": "rgba(139, 69, 19, 0.2)" },  // SaddleBrown
    //         // { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_9, "borderColor": "rgba(75, 0, 130, 1)", "backgroundColor": "rgba(75, 0, 130, 0.2)" },  // Indigo
    //         { "label": UI_STRING_REPORT_2_PINK.Group_7_Metric_10, "borderColor": "rgba(220, 20, 60, 1)", "backgroundColor": "rgba(220, 20, 60, 0.2)" }  // Crimson
    //     ]
    // },
];

///////////////////////////////////////////////////////////////
//=============================================================
// CHART SETTINGS
//=============================================================
///////////////////////////////////////////////////////////////

export const CHART_SETTINGS_AVERAGES = {
    borderWidth: 3,
    options: {
        responsive: true, // Ensures the chart responds to window size
        // maintainAspectRatio: false, // Make the chart fit the canvas
        plugins: {
            legend: {
                labels: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 20
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 6,
                ticks: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 18
                    },
                    stepSize: 1, // Force the y-axis to increment by 1 (or any integer step size you need)
                    // suggestedMax: 5,
                    // Custom callback to hide the number 6
                    callback: function (value) {
                        if (value === 6) {
                        return ''; // Do not show 6
                        }
                        return value; // Show all other values
                    },
                },
                grid: {
                    // Hide the grid line for the value 6
                    drawTicks: true,
                    drawBorder: true,
                    color: (context) => (context.tick.value === 6 ? "transparent" : "rgba(114, 114, 114, 0.9)"),
                },
            },
            x: {
                ticks: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 18
                    }
                },
                grid: {
                    color: "rgba(114, 114, 114, 0.9)"
                }
            }
        }
    }
}

export const CHART_SETTINGS_SCORES = {
    borderWidth: 2,
    options: {
        responsive: true, // Ensures the chart responds to window size
        // maintainAspectRatio: false, // Allows chart to scale without maintaining strict aspect ratio
        scales: {
            y: {
                beginAtZero: true,
                max: 5.5,
                ticks: {
                    stepSize: 1, // Force the y-axis to increment by 1 (or any integer step size you need)
                    // Custom callback to hide the number 6
                    callback: function (value) {
                        if (value === 5.5) {
                        return ''; // Do not show 6
                        }
                        return value; // Show all other values
                    },
                },
                grid: {
                    // Hide the grid line for the value 6
                    drawTicks: true,
                    drawBorder: true,
                    color: (context) => (context.tick.value === 5.5 ? "transparent" : "rgba(233, 233, 233, 1)"),
                },
            }
        }
    }
};

export const CHART_SETTINGS_SCORES_MULTI = {
    borderWidth: 2,
    options: {
        responsive: true, // Ensures the chart responds to window size
        // maintainAspectRatio: false, // Allows chart to scale without maintaining strict aspect ratio
        scales: {
            y: {
                beginAtZero: true,
                max: 5.5,
                ticks: {
                    stepSize: 1, // Force the y-axis to increment by 1 (or any integer step size you need)
                    // Custom callback to hide the number 6
                    callback: function (value) {
                        if (value === 5.5) {
                        return ''; // Do not show 6
                        }
                        return value; // Show all other values
                    },
                },
                grid: {
                    // Hide the grid line for the value 6
                    drawTicks: true,
                    drawBorder: true,
                    color: (context) => (context.tick.value === 5.5 ? "transparent" : "rgba(233, 233, 233, 1)"),
                },
            }
        },
    }
};