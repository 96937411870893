import React, { useState, useEffect  } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getOpenAdminAlerts, closeAdminAlert, reset as resetAdminAlerts } from "../../features/adminAlerts/adminAlertSlice";
import Spinner from '../Spinner';
import { PATH_NAME } from "../../app/constants";
// import { getDate_HKT, DATE_FORMAT_DDMMYYYY } from "../../app/date_utils";


const NotificationTable = () => {

  const navigate = useNavigate();

  const [canRenderTable, setCanRenderTable] = useState(false);

  // const { admin_alerts, is_error, message, is_reading, is_closing, is_closed } = useSelector((state) => state.admin_alerts);

  // Subscribe only, not actively dispatching
  const { admin_alerts, is_fetching_alerts, } = useSelector((state) => state.admin_alerts);
  // const { pending_reports, is_loading_pending_reports } = useSelector((state) => state.reports);
  // const { pending_summaries, is_loading_pending_summaries } = useSelector((state) => state.summaries);

  const ITEMS_PER_PAGE = 40;
  const [currentReportPage, setCurrentReportPage] = useState(1);
  const [totalReportItems, setTotalReportItems] = useState(0);
  const [totalReportPages, setTotalReportPages] = useState(0);

  const [currentSummaryPage, setCurrentSummaryPage] = useState(1);
  const [totalSummaryItems, setTotalSummaryItems] = useState(0);
  const [totalSummaryPages, setTotalSummaryPages] = useState(0);
  
  const [curr_report_notifications, set_curr_report_notifications] = useState([]);
  const [curr_summary_notifications, set_curr_summary_notifications] = useState([]);

  const handleReportDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentReportPage(selectedPage);
  };

  const handleSummaryDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentSummaryPage(selectedPage);
  };

  useEffect(() => {

    // if (pending_reports && pending_summaries)
    if (admin_alerts)
    {
      // Categorize the admin_alerts into unresolved_reports and unresolved_summaries
      const unresolved_reports = admin_alerts.filter(alert => alert.category === "2_incomplete_report");
      const unresolved_summaries = admin_alerts.filter(alert => alert.category === "1_handle_summary");

      // console.log("NotificationTable.js >> useEffect >> unresolved_reports: ", unresolved_reports);
      // console.log("NotificationTable.js >> useEffect >> unresolved_summaries: ", unresolved_summaries);

      // Update total report items and pages
      setTotalReportItems(unresolved_reports.length);
      setTotalReportPages(Math.ceil(unresolved_reports.length / ITEMS_PER_PAGE));

      // Update the current report notifications based on the current page
      set_curr_report_notifications(unresolved_reports.slice(
        (currentReportPage - 1) * ITEMS_PER_PAGE,
        currentReportPage * ITEMS_PER_PAGE
      ));

      // console.log("NotificationTable.js >> useEffect >> curr_report_notifications: ", curr_report_notifications);

      // Update total summary items and pages
      setTotalSummaryItems(unresolved_summaries.length);
      setTotalSummaryPages(Math.ceil(unresolved_summaries.length / ITEMS_PER_PAGE));

      // Update the current summary notifications based on the current page
      set_curr_summary_notifications(unresolved_summaries.slice(
        (currentSummaryPage - 1) * ITEMS_PER_PAGE,
        currentSummaryPage * ITEMS_PER_PAGE
      ));

      // Allow rendering the table after categorization and updates
      setCanRenderTable(true);
    }
  }, [
      admin_alerts,
      // pending_reports, pending_summaries, 
      currentReportPage, totalReportItems,
      currentSummaryPage, totalSummaryItems]);

  const visitStudentReportPage = (student_id, student_name) => () => {

    navigate(PATH_NAME.Admin_Student_Reports + "/" + student_id + "/" + student_name);

  };

  // const visitStudentSummaryPage = (shareable_id) => () => {
  
  //   navigate( PATH_NAME.Admin_Update_Summary + "/" + shareable_id + PATH_NAME.Edit_Mode );

  // };
  
  // Loading pending reports is now handled by App.js
  // useEffect(() => {

  //   if (is_error_loading_pending_reports)
  //   {
  //       return;
  //   }

  //   // Dispatch once
  //   dispatch( getAllPendingReports() );
  //   // dispatch(getOpenAdminAlerts());

  //   // Dispatch the action to get the alerts every REFRESH_ALERT_MS
  //   const interval = setInterval(() => {

  //       // console.log("Refreshing alerts -- " + Date.now());

  //       dispatch( getAllPendingReports() );
  //       // dispatch(getOpenAdminAlerts());

  //   }, REFRESH_ALERT_MS);

  //   // This represents the unmount function,
  //   // in which it's a good idea to reset, and clear your interval to prevent memory leaks.
  //   return () => {
  //       dispatch( resetGetPendingReportsState() );
  //       // dispatch(resetAdminAlerts());
  //       clearInterval(interval);
  //   };

  // // If you need to get the alerts only in the initial render,
  // // leave it out of the dependency array empty, so it will only invoke once
  // // }, [navigate, admin_alerts, is_error, message, dispatch]);
  // }, [navigate, dispatch, is_error_loading_pending_reports]);

  return (
    // Load again even when is_loading_pending_reports is fired by another page
    !canRenderTable || is_fetching_alerts ? <Spinner /> : (
    // !canRenderTable || is_loading_pending_reports || is_loading_pending_summaries ? <Spinner /> : (
    <>
    {/* Table 1 */}
    <div className="p-4 tracking-wider my-20">
      <div className="overflow-x-auto">
        <table className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
          <thead className="bg-transparent text-xl">
            
            <tr>
              <th className="px-6 py-3 my-16 text-left font-bold text-2xl text-white">GROUP 1: Summaries to be handled by Admin</th>
            </tr>
          </thead>

          <tbody className="bg-transparent text-xl">
            {curr_summary_notifications.map((notification, index) => (

              // Make this clickable
              <tr key={index}
                  className="pl-4 bg-white hover:cursor-point hover:bg-gray-200 hover:scale-105 transition-transform duration-200"
                  onClick={ visitStudentReportPage(notification.content.student_id, notification.content.student_name ) }
                  
              >
                <td className="px-6 py-4 whitespace-nowrap rounded-xl">
                  <div className="flex items-start">
                    <div className="ml-4">
                      <div className="text-left">
                        <span className="font-regular text-gray-700"> Please process a summary for: </span>
                        <span className="font-bold text-hots-green-1">{notification.content.student_name} </span>
                        <span className="font-regular text-hots-green-1">({notification.content.student_id})</span>
                        {/* <span className="font-regular text-gray-700"> - Progress Summary generated on: </span>
                        <span className="font-regular text-gray-700">{ getDate_HKT( notification.createdAt, DATE_FORMAT_DDMMYYYY ) }</span> */}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            <tr className="my-12">
                <th className="py-6 text-left font-medium text-white">

                  {totalSummaryItems === 0 ? (

                    <div className="text-gray-500 text-xl ml-6">
                      No notifications to show
                    </div>

                    ) : totalSummaryItems === 1 ? (

                    <div className="text-white text-xl ml-6">
                      Showing: 1 of 1 notification
                    </div>

                    ) : (

                    <div className="text-white text-xl ml-6">
                      Showing: {1 + (currentSummaryPage - 1) * ITEMS_PER_PAGE} to {Math.min(currentSummaryPage * ITEMS_PER_PAGE, totalSummaryItems)} of {totalSummaryItems} notifications
                    </div>

                  )}

                </th>
            </tr>

            <tr>
              <td className="flex items-center mb-8 ml-6">
                <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
                <div>
                  <select className="border rounded p-2" onChange={handleSummaryDropdownChange} value={currentSummaryPage}>
                    {Array.from({ length: totalSummaryPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    {/* Table 2 */}

    <div className="p-4 tracking-wider my-20">
      
      <div className="overflow-x-auto">
        <table className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
          <thead className="bg-transparent text-xl">
            
            <tr>
              <th className="px-6 py-3 my-16 text-left font-bold text-2xl text-white">GROUP 2: Reports to be completed by Teachers</th>
            </tr>
          </thead>

          <tbody className="bg-transparent text-xl">

            {/* Notification is a Report here */}
            {curr_report_notifications.map((notification, index) => (

              // Make this clickable
              <tr key={index}
                  className="pl-4 bg-white hover:cursor-point hover:bg-gray-200 hover:scale-105 transition-transform duration-200"
                  onClick={ visitStudentReportPage(notification.content.student_id, notification.content.student_name ) }
              >
                <td className="px-6 py-4 whitespace-nowrap rounded-xl">
                  <div className="flex items-start">
                    <div className="ml-4">
                      <div className="text-left">
                        <span className="font-bold text-hots-purple">{notification.content.teacher_name} </span>
                        <span className="font-regular text-gray-700">has yet to complete report for: <br/></span>
                        <span className="font-bold text-hots-green-1">{notification.content.student_name} </span>
                        <span className="font-regular text-hots-green-1">({notification.content.student_id})</span>
                        <span className="font-regular text-gray-700">; <u>{notification.content.class_level}</u> class;</span>
                        {/* <span className="font-regular text-gray-700">
                          (Week: {notification.content.week_number ? notification.content.week_number : "pending"});
                        </span> */}
                        <span className="font-regular text-gray-700"><br/>on {notification.content.date_string} </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            <tr className="my-12">
                <th className="py-6 text-left font-medium text-white">
                
                  {totalReportItems === 0 ? (

                    <div className="text-gray-500 text-xl ml-6">
                      No notifications to show
                    </div>
                    ) : totalReportItems === 1 ? (

                    <div className="text-white text-xl ml-6">
                      Showing: 1 of 1 notification
                    </div>

                    ) : (

                    <div className="text-white text-xl ml-6">
                    Showing: {1 + (currentReportPage - 1) * ITEMS_PER_PAGE} to {Math.min(currentReportPage * ITEMS_PER_PAGE, totalReportItems)} of {totalReportItems} notifications
                    </div>
                  )}
                </th>
            </tr>

            <tr>
              <td className="flex items-center mb-8 ml-6">
                <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
                <div>
                  <select className="border rounded p-2" onChange={handleReportDropdownChange} value={currentReportPage}>
                    {Array.from({ length: totalReportPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </>
    )
  );
};

export default NotificationTable;
