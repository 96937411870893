import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { getRecurringClassesByDay, resetFetchRecurringClassesByDay } from '../../../features/recurringClasses/recurringClassSlice';
import Spinner from "../../Spinner";
// import { FaPen } from "react-icons/fa";
import { TIME_SLOTS } from "../../../app/constants";
import { UI_STRING_LOADING_ALL } from '../../../app/strings';
import { UI_STRING_RECURRING_CLASS } from '../../../app/strings';
import RecurringClassAccordion from './RecurringClassAccordion';

const RecurringClassTable = ({ the_week_day }) => {

    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const { recurring_classes_on_day,
                is_fetching_recurring_classes_by_day,
                is_fetched_recurring_classes_by_day } = useSelector((state) => state.recurring_classes);

    const [ the_classes, set_the_classes ] = useState([]);

    const [ should_render, set_should_render ] = useState(false);

    const filter_classes_by_time_slot = (class_list, start_time, end_time) =>
    {
        // Filtering exact matches now
        return class_list.filter(c => c.time_of_day === start_time && c.end_time === end_time);
    };

    useEffect(() => {

        set_should_render(false);

        // console.log("RecurringClassTable.js >> useEffect :: Fetching recurring classes on day... ", the_week_day);
        resetFetchRecurringClassesByDay();
        dispatch( getRecurringClassesByDay(the_week_day) );

    }, [ the_week_day, dispatch]);

    useEffect(() => {

        if (is_fetched_recurring_classes_by_day)
        {
            // console.log("RecurringClassTable.js >> useEffect :: recurring_classes_on_day: ", recurring_classes_on_day);

            set_the_classes( recurring_classes_on_day );

            set_should_render(true);

            return () => {
              dispatch( resetFetchRecurringClassesByDay() );
            };
        }

    }, [is_fetched_recurring_classes_by_day, recurring_classes_on_day, dispatch]);

    if (is_fetching_recurring_classes_by_day || !should_render)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Recurring_Classes }/>;
    }  

  return (

    (should_render) && (
    
      <>
      <div className="p-4 tracking-wider">
        
        <div className="overflow-x-auto">

          {TIME_SLOTS.map((slot, slot_index) => {
              const filtered_classes = filter_classes_by_time_slot(the_classes, slot.start, slot.end);
              return (
                  <table
                      key={slot_index}
                      className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3 mb-8"
                  >
                      <thead className="bg-transparent text-xl">
                          <tr>
                              <th colSpan="5" className="px-6 py-3 text-left font-extrabold text-hots-purple">
                                  {slot.label}
                              </th>
                          </tr>
                      </thead>
                      <tbody className="bg-transparent text-xl">
                          {filtered_classes.length > 0 ? (
                              filtered_classes.map((this_class, index) => (
                                <RecurringClassAccordion key={index} this_class={this_class} index={index} />
                              ))
                          ) : (
                              <tr>
                                  <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                      {UI_STRING_RECURRING_CLASS.No_Classes_In_Slot}
                                  </td>
                              </tr>
                          )}
                          <tr className="my-12">
                              <th colSpan="5" className="px-6 py-6 text-left font-medium text-white">
                                  <div className="text-white text-xl">
                                      {filtered_classes.length === 0 ? (
                                          "No entries to show"
                                      ) : filtered_classes.length === 1 ? (
                                          `Showing: 1 entry`
                                      ) : (
                                          `Showing: ${filtered_classes.length} entries`
                                      )}
                                  </div>
                              </th>
                          </tr>
                      </tbody>
                  </table>
              );
          })}
      </div>

        <div className="flex justify-between items-center mt-4 ml-10">
        

        </div>
      </div>
      </>
      )
  );
};

export default RecurringClassTable;