import { FaCalendarAlt } from "react-icons/fa";
import { PATH_NAME } from "../app/constants";

const TabButtonCalendar = () => {

    return (
        window.location.href.includes( PATH_NAME.Day_Search ) === true ? (
            <button className="btn_white_outline_highlighted">
                <FaCalendarAlt />&nbsp;Calendar
            </button>
        ) : (
            <button className="btn_white_outline">
                <FaCalendarAlt />&nbsp;Calendar
            </button>
        )
    );
};

export default TabButtonCalendar;