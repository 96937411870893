import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        RATINGS,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_6_GREEN,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm6Green = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>
    
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_6_GREEN.Key_Object}
        name="key_object"
        value={formData.content.key_object}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_object"]}
    />
      
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_6_GREEN.Key_Letter}
        name="key_letter"
        value={formData.content.key_letter}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_letter"]}
    />

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        type="radio"
        label={`1. ${UI_STRING_REPORT_6_GREEN.Metric_1}`}
        name="_1_"
        value={formData.content._1_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`2. ${UI_STRING_REPORT_6_GREEN.Metric_2}`}
        name="_2_"
        value={formData.content._2_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        label={`3. ${UI_STRING_REPORT_6_GREEN.Metric_3}`}
        name="_3_"
        value={formData.content._3_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`4. ${UI_STRING_REPORT_6_GREEN.Metric_4}`}
        name="_4_"
        value={formData.content._4_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`5. ${UI_STRING_REPORT_6_GREEN.Metric_5}`}
        name="_5_"
        value={formData.content._5_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`6. ${UI_STRING_REPORT_6_GREEN.Metric_6}`}
        name="_6_"
        value={formData.content._6_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`7. ${UI_STRING_REPORT_6_GREEN.Metric_7}`}
        name="_7_"
        value={formData.content._7_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-white font-bold text-xl text-left mb-8">
        {`${UI_STRING_REPORT_6_GREEN.Category_2}`}
    </div>

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`8. ${UI_STRING_REPORT_6_GREEN.Metric_8}`}
        name="_8_"
        value={formData.content._8_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_8_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`9. ${UI_STRING_REPORT_6_GREEN.Metric_9}`}
        name="_9_"
        value={formData.content._9_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_9_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`10. ${UI_STRING_REPORT_6_GREEN.Metric_10}`}
        name="_10_"
        value={formData.content._10_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_10_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`11. ${UI_STRING_REPORT_6_GREEN.Metric_11}`}
        name="_11_"
        value={formData.content._11_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_11_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`12. ${UI_STRING_REPORT_6_GREEN.Metric_12}`}
        name="_12_"
        value={formData.content._12_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_12_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`13. ${UI_STRING_REPORT_6_GREEN.Metric_13}`}
        name="_13_"
        value={formData.content._13_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_13_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`14. ${UI_STRING_REPORT_6_GREEN.Metric_14}`}
        name="_14_"
        value={formData.content._14_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_14_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`15. ${UI_STRING_REPORT_6_GREEN.Metric_15}`}
        name="_15_"
        value={formData.content._15_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_15_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`16. ${UI_STRING_REPORT_6_GREEN.Metric_16}`}
        name="_16_"
        value={formData.content._16_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_16_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Checkbox: Can Advance Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_6_GREEN.Can_Advance}
        name="can_advance"
        value={formData.content.can_advance}
        type="checkbox"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["can_advance"]}
    />

    </div>
  );
};

export default UpdateReportForm6Green;