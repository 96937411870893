export const ReportContentData_1_Yellow = {
    _1_: "",
    _2_1_: "",
    _2_2_: "",
    _2_3_: "",
    _2_4_: "",
    _2_5_: "",
    _2_6_: "",
    _3_1_: "",
    _3_2_: "",
    _4_: "",
    _5_: "",
    _6_1_: "",
    _6_2_: "",
    _6_3_: "",
    _6_4_: "",
    _6_5_: "",
    _7_1_: "",
    _7_2_: "",
    _7_3_: "",
    _8_1_: "",
    _8_2_: "",
    _8_3_: "",
    _8_4_: "",
    _8_5_: "",
    _8_6_: "",
    _8_7_: "",
    _8_8_: "",
    _8_9_: "",
    _8_10_: "",
    weekly_comment: "",
}

export const ReportContentData_1_Yellow_RequiredFields = [
    { name: "_1_", is_optional: false },
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_2_3_", is_optional: false },
    { name: "_2_4_", is_optional: false },
    { name: "_2_5_", is_optional: false },
    { name: "_2_6_", is_optional: false },
    { name: "_3_1_", is_optional: false },
    { name: "_3_2_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_", is_optional: false },
    { name: "_6_1_", is_optional: false },
    { name: "_6_2_", is_optional: false },
    { name: "_6_3_", is_optional: false },
    { name: "_6_4_", is_optional: false },
    { name: "_6_5_", is_optional: false },
    { name: "_7_1_", is_optional: false },
    { name: "_7_2_", is_optional: false },
    { name: "_7_3_", is_optional: false },
    { name: "_8_1_", is_optional: false },
    { name: "_8_2_", is_optional: false },
    { name: "_8_3_", is_optional: false },
    { name: "_8_4_", is_optional: false },
    { name: "_8_5_", is_optional: false },
    { name: "_8_6_", is_optional: false },
    { name: "_8_7_", is_optional: false },
    { name: "_8_8_", is_optional: false },
    { name: "_8_9_", is_optional: false },
    { name: "_8_10_", is_optional: false },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_2_Pink = {
    _1_1_: "",
    _1_2_: "",
    _1_3_: "",
    _1_4_: "",
    _1_5_: "",
    _1_6_: "",
    _2_1_: "",
    _2_2_: "",
    _3_: "",
    _4_: "",
    _5_1_: "",
    _5_2_: "",
    _5_3_: "",
    _5_4_: "",
    _5_5_: "",
    _6_1_: "",
    _6_2_: "",
    _6_3_: "",
    _7_1_: "",
    _7_2_: "",
    _7_3_: "",
    _7_4_: "",
    _7_5_: "",
    _7_6_: "",
    _7_7_: "",
    _7_8_: "",
    _7_9_: "",
    _7_10_: "",
    weekly_comment: "",
}

export const ReportContentData_2_Pink_RequiredFields = [
    { name: "_1_1_", is_optional: false },
    { name: "_1_2_", is_optional: false },
    { name: "_1_3_", is_optional: false },
    { name: "_1_4_", is_optional: false },
    { name: "_1_5_", is_optional: false },
    { name: "_1_6_", is_optional: false },
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_3_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_1_", is_optional: false },
    { name: "_5_2_", is_optional: false },
    { name: "_5_3_", is_optional: false },
    { name: "_5_4_", is_optional: false },
    { name: "_5_5_", is_optional: false },
    { name: "_6_1_", is_optional: false },
    { name: "_6_2_", is_optional: false },
    { name: "_6_3_", is_optional: false },
    { name: "_7_1_", is_optional: false },
    { name: "_7_2_", is_optional: false },
    { name: "_7_3_", is_optional: false },
    { name: "_7_4_", is_optional: false },
    { name: "_7_5_", is_optional: false },
    { name: "_7_6_", is_optional: false },
    { name: "_7_7_", is_optional: false },
    { name: "_7_8_", is_optional: false },
    { name: "_7_9_", is_optional: false },
    { name: "_7_10_", is_optional: false },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_3_Red =
{
    key_object: "",
    key_letter: "",
    _1_: "",
    _2_: "",
    _3_: "",
    _4_: "",
    _5_: "",
    _6_: "",
    _7_: "",
    _8_: "",
    _9_: "",
    _10_: "",
    _11_: "",
    // _1_phonics: "",
    // _2_communication: "",
    // _3_listening: "",
    // _4_speaking: "",
    // _5_reading: "",
    // _6_letter_recognition: "",
    // _7_tracing_paper: "",
    // _8_story_making: "",
    // _9_eagerness: "",
    // _10_coachiness: "",
    // _11_discipline: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_3_Red_RequiredFields = [
    { name: "key_object", is_optional: true },
    { name: "key_letter", is_optional: true },
    { name: "_1_", is_optional: false },
    { name: "_2_", is_optional: false },
    { name: "_3_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_", is_optional: false },
    { name: "_6_", is_optional: false },
    { name: "_7_", is_optional: false },
    { name: "_8_", is_optional: false },
    { name: "_9_", is_optional: false },
    { name: "_10_", is_optional: false },
    { name: "_11_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_4_PreBlue =
{
    key_object: "",
    key_letter: "",
    _1_: "",
    _2_: "",
    _3_: "",
    _4_: "",
    _5_: "",
    _6_: "",
    _7_: "",
    _8_: "",
    _9_: "",
    _10_: "",
    _11_: "",
    _12_: "",
    _13_: "",
    _14_: "",
    _15_: "",
    _16_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_4_PreBlue_RequiredFields = [
    { name: "key_object", is_optional: true },
    { name: "key_letter", is_optional: true },
    { name: "_1_", is_optional: false },
    { name: "_2_", is_optional: false },
    { name: "_3_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_", is_optional: false },
    { name: "_6_", is_optional: false },
    { name: "_7_", is_optional: false },
    { name: "_8_", is_optional: false },
    { name: "_9_", is_optional: false },
    { name: "_10_", is_optional: false },
    { name: "_11_", is_optional: false },
    { name: "_12_", is_optional: false },
    { name: "_13_", is_optional: false },
    { name: "_14_", is_optional: false },
    { name: "_15_", is_optional: false },
    { name: "_16_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_5_Blue =
{
    key_object: "",
    key_letter: "",
    _1_: "",
    _2_: "",
    _3_: "",
    _4_: "",
    _5_: "",
    _6_: "",
    _7_: "",
    _8_: "",
    _9_: "",
    _10_: "",
    _11_: "",
    _12_: "",
    _13_: "",
    _14_: "",
    _15_: "",
    _16_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_5_Blue_RequiredFields = [
    { name: "key_object", is_optional: true },
    { name: "key_letter", is_optional: true },
    { name: "_1_", is_optional: false },
    { name: "_2_", is_optional: false },
    { name: "_3_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_", is_optional: false },
    { name: "_6_", is_optional: false },
    { name: "_7_", is_optional: false },
    { name: "_8_", is_optional: false },
    { name: "_9_", is_optional: false },
    { name: "_10_", is_optional: false },
    { name: "_11_", is_optional: false },
    { name: "_12_", is_optional: false },
    { name: "_13_", is_optional: false },
    { name: "_14_", is_optional: false },
    { name: "_15_", is_optional: false },
    { name: "_16_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_6_Green = {
    key_object: "",
    key_letter: "",
    _1_: "",
    _2_: "",
    _3_: "",
    _4_: "",
    _5_: "",
    _6_: "",
    _7_: "",
    _8_: "",
    _9_: "",
    _10_: "",
    _11_: "",
    _12_: "",
    _13_: "",
    _14_: "",
    _15_: "",
    _16_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_6_Green_RequiredFields = [
    { name: "key_object", is_optional: true },
    { name: "key_letter", is_optional: true },
    { name: "_1_", is_optional: false },
    { name: "_2_", is_optional: false },
    { name: "_3_", is_optional: false },
    { name: "_4_", is_optional: false },
    { name: "_5_", is_optional: false },
    { name: "_6_", is_optional: false },
    { name: "_7_", is_optional: false },
    { name: "_8_", is_optional: false },
    { name: "_9_", is_optional: false },
    { name: "_10_", is_optional: false },
    { name: "_11_", is_optional: false },
    { name: "_12_", is_optional: false },
    { name: "_13_", is_optional: false },
    { name: "_14_", is_optional: false },
    { name: "_15_", is_optional: false },
    { name: "_16_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP = {
    key_topic: "",
    _1_1_: "",
    _1_2_: "",
    _1_3_: "",
    _1_4_: "",
    _1_5_: "",
    _1_6_: "",
    _1_7_: "",
    _2_1_: "",
    _2_2_: "",
    _2_3_: "",
    _2_4_: "",
    _2_5_: "",
    _2_6_: "",
    _3_1_: "",
    _3_2_: "",
    _3_3_: "",
    _3_4_: "",
    _3_5_: "",
    _3_6_: "",
    _3_7_: "",
    _3_8_: "",
    _3_9_: "",
    _3_10_: "",
    _4_1_: "",
    _4_2_: "",
    _4_3_: "",
    _4_4_: "",
    _4_5_: "",
    _4_6_: "",
    _4_7_: "",
    _4_8_: "",
    _4_9_: "",
    _4_10_: "",
    _5_1_: "",
    _5_2_: "",
    _5_3_: "",
    _5_4_: "",
    _5_5_: "",
    _5_6_: "",
    _5_7_: "",
    _6_1_: "",
    _6_2_: "",
    _6_3_: "",
    _6_4_: "",
    _6_5_: "",
    _6_6_: "",
    _6_7_: "",
    _6_8_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_7_HEEP_Week_1_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_1_1_", is_optional: false },
    { name: "_1_2_", is_optional: false },
    { name: "_1_3_", is_optional: false },
    { name: "_1_4_", is_optional: false },
    { name: "_1_5_", is_optional: false },
    { name: "_1_6_", is_optional: false },
    { name: "_1_7_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP_Week_2_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_2_3_", is_optional: false },
    { name: "_2_4_", is_optional: false },
    { name: "_2_5_", is_optional: false },
    { name: "_2_6_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP_Week_3_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_3_1_", is_optional: false },
    { name: "_3_2_", is_optional: false },
    { name: "_3_3_", is_optional: false },
    { name: "_3_4_", is_optional: false },
    { name: "_3_5_", is_optional: false },
    { name: "_3_6_", is_optional: false },
    { name: "_3_7_", is_optional: false },
    { name: "_3_8_", is_optional: false },
    { name: "_3_9_", is_optional: false },
    { name: "_3_10_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP_Week_4_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_4_1_", is_optional: false },
    { name: "_4_2_", is_optional: false },
    { name: "_4_3_", is_optional: false },
    { name: "_4_4_", is_optional: false },
    { name: "_4_5_", is_optional: false },
    { name: "_4_6_", is_optional: false },
    { name: "_4_7_", is_optional: false },
    { name: "_4_8_", is_optional: false },
    { name: "_4_9_", is_optional: false },
    { name: "_4_10_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP_Week_5_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_5_1_", is_optional: false },
    { name: "_5_2_", is_optional: false },
    { name: "_5_3_", is_optional: false },
    { name: "_5_4_", is_optional: false },
    { name: "_5_5_", is_optional: false },
    { name: "_5_6_", is_optional: false },
    { name: "_5_7_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_7_HEEP_Week_6_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_6_1_", is_optional: false },
    { name: "_6_2_", is_optional: false },
    { name: "_6_3_", is_optional: false },
    { name: "_6_4_", is_optional: false },
    { name: "_6_5_", is_optional: false },
    { name: "_6_6_", is_optional: false },
    { name: "_6_7_", is_optional: false },
    { name: "_6_8_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

// Shared among both HE L1 and L2
export const ReportContentData_8_HE = {
    key_topic: "",
    _1_1_: "",
    _1_2_: "",
    _1_3_: "",
    _1_4_: "",
    _1_5_: "",
    _2_1_: "",
    _2_2_: "",
    _2_3_: "",
    _2_4_: "",
    _2_5_: "",
    _2_6_: "",
    _2_7_: "",
    _2_8_: "",
    _2_9_: "",
    _2_10_: "",
    _2_11_: "",
    _2_12_: "",
    _3_1_: "",
    _3_2_: "",
    _3_3_: "",
    _3_4_: "",
    _3_5_: "",
    _3_6_: "",
    _3_7_: "",
    _3_8_: "",
    _3_9_: "",
    _3_10_: "",
    _4_1_: "",
    _4_2_: "",
    _4_3_: "",
    _4_4_: "",
    _4_5_: "",
    _4_6_: "",
    _4_7_: "",
    _4_8_: "",
    _4_9_: "",
    _5_1_: "",
    _5_2_: "",
    _5_3_: "",
    _5_4_: "",
    _5_5_: "",
    _5_6_: "",
    _5_7_: "",
    _5_8_: "",
    _5_9_: "",
    _6_1_: "",
    _6_2_: "",
    _6_3_: "",
    _6_4_: "",
    _6_5_: "",
    _6_6_: "",
    _6_7_: "",
    _6_8_: "",
    _6_9_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_8_HE_Week_1_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_1_1_", is_optional: false },
    { name: "_1_2_", is_optional: false },
    { name: "_1_3_", is_optional: false },
    { name: "_1_4_", is_optional: false },
    { name: "_1_5_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_8_HE_Week_2_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_2_3_", is_optional: false },
    { name: "_2_4_", is_optional: false },
    { name: "_2_5_", is_optional: false },
    { name: "_2_6_", is_optional: false },
    { name: "_2_7_", is_optional: false },
    { name: "_2_8_", is_optional: false },
    { name: "_2_9_", is_optional: false },
    { name: "_2_10_", is_optional: false },
    { name: "_2_11_", is_optional: false },
    { name: "_2_12_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_8_HE_Week_3_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_3_1_", is_optional: false },
    { name: "_3_2_", is_optional: false },
    { name: "_3_3_", is_optional: false },
    { name: "_3_4_", is_optional: false },
    { name: "_3_5_", is_optional: false },
    { name: "_3_6_", is_optional: false },
    { name: "_3_7_", is_optional: false },
    { name: "_3_8_", is_optional: false },
    { name: "_3_9_", is_optional: false },
    { name: "_3_10_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_8_HE_Week_4_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_4_1_", is_optional: false },
    { name: "_4_2_", is_optional: false },
    { name: "_4_3_", is_optional: false },
    { name: "_4_4_", is_optional: false },
    { name: "_4_5_", is_optional: false },
    { name: "_4_6_", is_optional: false },
    { name: "_4_7_", is_optional: false },
    { name: "_4_8_", is_optional: false },
    { name: "_4_9_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_8_HE_Week_5_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_5_1_", is_optional: false },
    { name: "_5_2_", is_optional: false },
    { name: "_5_3_", is_optional: false },
    { name: "_5_4_", is_optional: false },
    { name: "_5_5_", is_optional: false },
    { name: "_5_6_", is_optional: false },
    { name: "_5_7_", is_optional: false },
    { name: "_5_8_", is_optional: false },
    { name: "_5_9_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_8_HE_Week_6_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_6_1_", is_optional: false },
    { name: "_6_2_", is_optional: false },
    { name: "_6_3_", is_optional: false },
    { name: "_6_4_", is_optional: false },
    { name: "_6_5_", is_optional: false },
    { name: "_6_6_", is_optional: false },
    { name: "_6_7_", is_optional: false },
    { name: "_6_8_", is_optional: false },
    { name: "_6_9_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_10_HI = {
    key_topic: "",
    _1_1_: "",
    _1_2_: "",
    _1_3_: "",
    _1_4_: "",
    _1_5_: "",
    _1_6_: "",
    _1_7_: "",
    _1_8_: "",
    _2_1_: "",
    _2_2_: "",
    _2_3_: "",
    _2_4_: "",
    _2_5_: "",
    _2_6_: "",
    _2_7_: "",
    _2_8_: "",
    _2_9_: "",
    _2_10_: "",
    _2_11_: "",
    _2_12_: "",
    _3_1_: "",
    _3_2_: "",
    _3_3_: "",
    _3_4_: "",
    _3_5_: "",
    _3_6_: "",
    _3_7_: "",
    _3_8_: "",
    _3_9_: "",
    _3_10_: "",
    _4_1_: "",
    _4_2_: "",
    _4_3_: "",
    _4_4_: "",
    _4_5_: "",
    _4_6_: "",
    _4_7_: "",
    _4_8_: "",
    _5_1_: "",
    _5_2_: "",
    _5_3_: "",
    _5_4_: "",
    _5_5_: "",
    _5_6_: "",
    _5_7_: "",
    _5_8_: "",
    _5_9_: "",
    _6_1_: "",
    _6_2_: "",
    _6_3_: "",
    _6_4_: "",
    _6_5_: "",
    _6_6_: "",
    can_advance: false,
    weekly_comment: "",
};

export const ReportContentData_10_HI_Week_1_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_1_1_", is_optional: false },
    { name: "_1_2_", is_optional: false },
    { name: "_1_3_", is_optional: false },
    { name: "_1_4_", is_optional: false },
    { name: "_1_5_", is_optional: false },
    { name: "_1_6_", is_optional: false },
    { name: "_1_7_", is_optional: false },
    { name: "_1_8_", is_optional: false },
];

export const ReportContentData_10_HI_Week_2_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_2_3_", is_optional: false },
    { name: "_2_4_", is_optional: false },
    { name: "_2_5_", is_optional: false },
    { name: "_2_6_", is_optional: false },
    { name: "_2_7_", is_optional: false },
    { name: "_2_8_", is_optional: false },
    { name: "_2_9_", is_optional: false },
    { name: "_2_10_", is_optional: false },
    { name: "_2_11_", is_optional: false },
    { name: "_2_12_", is_optional: false },
];

export const ReportContentData_10_HI_Week_3_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_3_1_", is_optional: false },
    { name: "_3_2_", is_optional: false },
    { name: "_3_3_", is_optional: false },
    { name: "_3_4_", is_optional: false },
    { name: "_3_5_", is_optional: false },
    { name: "_3_6_", is_optional: false },
    { name: "_3_7_", is_optional: false },
    { name: "_3_8_", is_optional: false },
    { name: "_3_9_", is_optional: false },
    { name: "_3_10_", is_optional: false },
];

export const ReportContentData_10_HI_Week_4_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_4_1_", is_optional: false },
    { name: "_4_2_", is_optional: false },
    { name: "_4_3_", is_optional: false },
    { name: "_4_4_", is_optional: false },
    { name: "_4_5_", is_optional: false },
    { name: "_4_6_", is_optional: false },
    { name: "_4_7_", is_optional: false },
    { name: "_4_8_", is_optional: false },
];

export const ReportContentData_10_HI_Week_5_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_5_1_", is_optional: false },
    { name: "_5_2_", is_optional: false },
    { name: "_5_3_", is_optional: false },
    { name: "_5_4_", is_optional: false },
    { name: "_5_5_", is_optional: false },
    { name: "_5_6_", is_optional: false },
    { name: "_5_7_", is_optional: false },
    { name: "_5_8_", is_optional: false },
    { name: "_5_9_", is_optional: false },
];

export const ReportContentData_10_HI_Week_6_RequiredFields = [
    { name: "key_topic", is_optional: false },
    { name: "_6_1_", is_optional: false },
    { name: "_6_2_", is_optional: false },
    { name: "_6_3_", is_optional: false },
    { name: "_6_4_", is_optional: false },
    { name: "_6_5_", is_optional: false },
    { name: "_6_6_", is_optional: false },
    { name: "can_advance", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

// Mostly text boxes
export const ReportContentData_11_Reading = {
    _1_1_: "",
    _1_2_: "",
    _1_3_: "",
    _1_4_: "",
    _1_5_: "",
    _1_6_: "",
    _1_7_: "",
    _1_8_: "",
    _2_1_: "",
    _2_2_: "",
    _2_3_: "",
    _2_4_: "",
    _2_5_: "",
    _2_6_: "",
    _2_7_: "",
    _2_8_: "",
    _3_1_: "",
    _3_2_: "",
    _3_3_: "",
    _3_4_: "",
    _3_5_: "",
    _3_6_: "",
    _3_7_: "",
    _3_8_: "",
    _4_1_: "",
    _4_2_: "",
    _4_3_: "",
    sub_level: "",
    weekly_comment: "",
};

export const ReportContentData_11_Reading_Week_1_RequiredFields = [
    { name: "_1_1_", is_optional: false },
    { name: "_1_2_", is_optional: false },
    { name: "_1_3_", is_optional: false },
    { name: "_1_4_", is_optional: false },
    { name: "_1_5_", is_optional: false },
    { name: "_1_6_", is_optional: false },
    { name: "_1_7_", is_optional: false },
    { name: "_1_8_", is_optional: false },
    { name: "sub_level", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_11_Reading_Week_2_RequiredFields = [
    { name: "_2_1_", is_optional: false },
    { name: "_2_2_", is_optional: false },
    { name: "_2_3_", is_optional: false },
    { name: "_2_4_", is_optional: false },
    { name: "_2_5_", is_optional: false },
    { name: "_2_6_", is_optional: false },
    { name: "_2_7_", is_optional: false },
    { name: "_2_8_", is_optional: false },
    { name: "sub_level", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_11_Reading_Week_3_RequiredFields = [
    { name: "_3_1_", is_optional: false },
    { name: "_3_2_", is_optional: false },
    { name: "_3_3_", is_optional: false },
    { name: "_3_4_", is_optional: false },
    { name: "_3_5_", is_optional: false },
    { name: "_3_6_", is_optional: false },
    { name: "_3_7_", is_optional: false },
    { name: "_3_8_", is_optional: false },
    { name: "sub_level", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];

export const ReportContentData_11_Reading_Week_4_RequiredFields = [
    { name: "_4_1_", is_optional: false },
    { name: "_4_2_", is_optional: false },
    { name: "_4_3_", is_optional: false },
    { name: "sub_level", is_optional: true },
    { name: "weekly_comment", is_optional: false }
];