import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        RATINGS,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_10_HI,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm10HIWeek1 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`${UI_STRING_REPORT_10_HI.Week_1}`}
    </div>

    {/* Textarea: Topic Section */}
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_10_HI.Key_Topic}
        name="key_topic"
        value={formData.content.key_topic}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_topic"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_1}`}
        name="_1_1_"
        value={formData.content._1_1_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_2}`}
        name="_1_2_"
        value={formData.content._1_2_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_3}`}
        name="_1_3_"
        value={formData.content._1_3_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_4}`}
        name="_1_4_"
        value={formData.content._1_4_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_5}`}
        name="_1_5_"
        value={formData.content._1_5_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_6}`}
        name="_1_6_"
        value={formData.content._1_6_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_7}`}
        name="_1_7_"
        value={formData.content._1_7_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_1_Metric_8}`}
        name="_1_8_"
        value={formData.content._1_8_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_8_"]}
    />
    
    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Checkbox: Can Advance Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_10_HI.Can_Advance}
        name="can_advance"
        value={formData.content.can_advance}
        type="checkbox"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["can_advance"]}
    />
    
    </div>
  );
};

export default UpdateReportForm10HIWeek1;