import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getStudentsByID, getStudentsByName, resetStudents } from "../features/students/studentSlice";
import { FaEye } from "react-icons/fa";
// import { STUDENT_ID_PREFIX } from "../app/constants";
import { UI_STRING_CALENDAR } from "../app/strings";
import { PATH_NAME } from "../app/constants";
// import { is_valid_date } from "../app/date_utils";
import { getDate_HKT, getCurrentDate_HKT, DATE_FORMAT_DDMMYYYY } from "../app/date_utils";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_orange.css";  // Import a theme of your choice

const DaySearchComponent = ({ teacher }) => {
  // const DaySearchComponent = ({ teacher, on_result_click }) => {

  // [] means all teachers
  // const the_teacher = teacher;

  const navigate = useNavigate();

  const [ selected_date, set_selected_date ] = useState( getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY ) );

  // const [search_date_query, set_search_date_query] = useState("");
  // const [ is_search_date_valid, set_is_search_date_valid ] = useState(false);

  // const [debounce_timeout, set_debounce_timeout] = useState(null);

  // const valid_input_length = 10;
  // const date_regex = /^20\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;  // Regular expression for date in format "YYYY-MM-DD"

  const handle_date_change = (date) => {

    const formattedDate = getDate_HKT(date[0], DATE_FORMAT_DDMMYYYY);

    set_selected_date(formattedDate);
  };

  // // Locally check input query
  // const handle_date_search = async (query) => {

  //   set_is_search_date_valid(false);

  //   if (query.length === valid_input_length)
  //   {
  //     if (date_regex.test(query) && is_valid_date(query))
  //     {
  //       // console.log("StudentSearchComponent.js >> handle_student_search :: encountered a valid date query...", query);

  //       set_is_search_date_valid(true);
  //     }
  //   }
  // };

  // // Handle input change for student search with debounce
  // const handle_input_change = (e) => {

  //   const query = e.target.value;

  //   // Set latest input string as the search query
  //   set_search_date_query(query);

  //   // Reset state when input is cleared
  //   if (query === "")
  //   {
  //     // console.log("StudentSearchComponent.js >> handle_input_change :: empty input...");

  //     // set_show_table(false);

  //     // set_student_results([]);
  //     // set_no_results(false);
  //     return;
  //   }

  //   // Clear any existing timeout
  //   if (debounce_timeout) clearTimeout(debounce_timeout);

  //   // Debounce the search:
  //   // This ensures the search is not triggered on every keystroke but waits until the user has stopped typing for a moment.
  //   // Set a new timeout for search after 200ms
  //   const new_timeout = setTimeout(() => {

  //     // console.log("StudentSearchComponent.js >> handle_input_change :: time to fire handle_student_search...");

  //     handle_date_search(query);

  //     // The search is delayed by 100 milliseconds.
  //     // It doesn't need to be a long delay, as no API calls are made at this point.
  //   }, 100);

  //   set_debounce_timeout(new_timeout);
  // };

  // // Handle Enter key press for search
  // const handle_key_down = (e) => {

  //   if (e.key === "Enter")
  //   {
  //     // When the Enter key is pressed, any pending debounce search is cleared,
  //     // and the search is triggered immediately.

  //     // Clear the debounce timeout when Enter is pressed
  //     if (debounce_timeout) clearTimeout(debounce_timeout);

  //     // console.log("StudentSearchComponent.js >> handle_key_down :: enter pressed, fire handle_student_search...");

  //     handle_date_search(search_date_query);
  //   }
  // };

  const handle_valid_date_click = () => {

    // console.log("DaySearchComponent.js >> handle_valid_date_click :: selected_date: ", selected_date);

    navigate(PATH_NAME.Day_Lessons + "/" + selected_date);

    // search_date_query is a valid date, in the format "YYYY-MM-DD"
    // It will be converted after being passed inside day page
    // navigate(PATH_NAME.Day_Lessons + "/" + search_date_query);
  };

  return (
      (
      <>
      {/* Class Search Field */}

      <div className="flex items-center space-x-2 ml-2 mb-4">
        <div className="text-gray-600 font-bold text-2xl">
          {UI_STRING_CALENDAR.Choose_Date}
        </div>
        {/* <div className="text-gray-600 font-medium text-lg">
          (e.g. 2024-09-18)
        </div> */}
      </div>

      <Flatpickr
          className="form-control mt-2"
          placeholder={UI_STRING_CALENDAR.Search_Prompt}
          // value={selected_date}
          value={ selected_date }
          onChange={ handle_date_change }
          options={{ dateFormat: "d-m-Y" }}
      />

      <div className="flex justify-center mt-8 mb-16">
          <button className="btn_green w-full" onClick={handle_valid_date_click}>
              <FaEye />&nbsp;{UI_STRING_CALENDAR.View_Day}
          </button>
      </div>
      
      {/*
      <input
          type="text"
          className="form_control"
          placeholder={UI_STRING_CALENDAR.Search_Prompt}
          value={search_date_query}
          onChange={handle_input_change}
          onKeyDown={handle_key_down}
      />

      {
      is_search_date_valid === false ?
      
      (
        search_date_query.length > 0 &&
        (
        <div className="w-full text-left"
        >
          <div className="flex items-center text-gray-500 font-bold text-2xl my-2">
            <FaSearch className="mr-2" /> 
            <span>{search_date_query}</span>
          </div>
        </div>
        )
      )
      :
      (
        // Link to the valid date's timetable
        <>
        <button 
          type="button" 
          onClick={ handle_valid_date_click }
          className="focus:outline-none w-full text-left"
        >
          <div className="flex items-center text-hots-green-1 font-bold text-2xl my-2">
            <FaSearch className="mr-2" /> 
            <span>{search_date_query}</span>
          </div>
        </button>
        
        </>
        
      )
      
      } */}
      </>
      )
  );
};

export default DaySearchComponent;
