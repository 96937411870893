import React, { useState } from 'react';
import { FaPen } from "react-icons/fa";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { PATH_NAME } from '../../app/constants';
import { UI_STRING_LOADING_ALL } from '../../app/strings';

const ReportWaitListTable = () => {

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  
  const { incomplete_reports,
          is_loading_incomplete_reports } = useSelector((state) => state.reports);

  const [current_page, set_current_page] = useState(1);
  const items_per_page = 20;
  const total_items = incomplete_reports?.length;
  const total_pages = Math.ceil(total_items / items_per_page);
  
  const curr_pending_reports = incomplete_reports?.slice(
    (current_page - 1) * items_per_page,
    current_page * items_per_page
  );

  const handleDropdownChange = (event) => {
    const selected_page = Number(event.target.value);
    set_current_page(selected_page);
  };

  const handleEditClick = (report_id, class_level) => {
    navigate( PATH_NAME.Teacher_Update_Report_Details + "/" + class_level + "/" + report_id + PATH_NAME.Edit_Mode );
    // navigate(`/update_report/${class_level}/${report_id}`);
  };

  const go_to_student_reports_page = (curr_student) => {

    if (curr_student && curr_student.student_id && curr_student.student_name)
    {
        navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + curr_student.student_id + "/" + curr_student.student_name);
    }
  };

  if (is_loading_incomplete_reports)
  {
      return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Incomplete_Reports_By_Teacher }/>;
  }

  return (
    // !incomplete_reports ? <Spinner /> :
    (
      incomplete_reports &&
      <>
      <section className="heading_alert mt-12">

        {
          (total_items > 1) &&
          ( <h1>Reports to be completed: {total_items}</h1> )
        }

        {
          (total_items === 1) &&
          ( <h1>Report to be completed: {total_items}</h1> )
        }

        {
          (total_items === 0) &&
          ( <h1 className="text-hots-green-1">All reports have been completed</h1> )
        }
        
      </section>
      <div className="p-4">
        <div className="flex items-center mb-8 ml-10">
        <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
          <div>
            <select className="border rounded p-2" onChange={handleDropdownChange} value={current_page}>
              {Array.from({ length: total_pages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <table className="px-8 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
            <thead className="bg-transparent">
              <tr>
                <th className="px-6 py-3 text-left text-xl font-extrabold text-white uppercase tracking-wider">Student</th>
                <th className="px-6 py-3 text-center text-xl font-extrabold text-white uppercase tracking-wider">Class</th>
                <th className="px-6 py-3 text-center text-xl font-extrabold text-white uppercase tracking-wider">Date </th>
                <th className="px-6 py-3 text-center text-xl font-extrabold text-white uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="bg-transparent">
              {curr_pending_reports && curr_pending_reports.length > 0 && curr_pending_reports.map((this_report, index) => (
                  <tr key={index} className="pl-4 bg-white">

                    <td className="px-6 py-4 whitespace-nowrap rounded-l-xl">
                      <div className="flex items-left">
                        <div className="ml-4">
                          <button type="button" onClick={() => go_to_student_reports_page(this_report.student)}>
                            <div className="text-left font-bold text-hots-green-1 text-xl">{this_report.student.student_name}</div>
                            <br />
                            <div className="text-left font-regular text-gray-500 text-lg">({this_report.student.student_id})</div>
                          </button>
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap font-medium">
                      <div className="flex items-left">
                        <div className="ml-4">
                          <div className="font-medium text-gray-900 text-xl">{this_report.class_level}</div>
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap font-medium">
                      <div className="flex items-center">
                        <div className="ml-6">
                          <div className="text-left font-medium text-gray-900 text-xl">{this_report.date_string}</div>
                          {/* <br /> */}
                          {/* Better to use the week number from the report itself */}
                          {/* <div className="text-left font-regular text-gray-500 text-lg">(Week {this_report.lesson.week_number})</div> */}
                          {/* <div className="text-left font-regular text-gray-500 text-lg">(Week {this_report.week_number})</div> */}
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap font-medium rounded-r-xl">
                      <div className="ml-8">
                        <button className="btn_item"  onClick={() => handleEditClick(this_report._id, this_report.class_level)}>
                          <FaPen />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              <tr className="my=12">
                <th colSpan="4" className="px-6 py-6 text-left font-medium text-white">
                <div className="text-white text-xl">
                  {total_items === 0 ? (
                    "No entries to show"
                    ) : total_items === 1 ? (
                    `Showing: 1 of 1 entry`
                    ) : (
                    `Showing: ${1 + (current_page - 1) * items_per_page} to ${Math.min(current_page * items_per_page, total_items)} of ${total_items} entries`
                  )}
                </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4 ml-10">
        
        </div>
      </div>
      </>
    )
  );
};

export default ReportWaitListTable