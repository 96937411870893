import { PATH_NAME } from "./constants";

export function is_summary_page( location_pathname )
{
  // User normally visits this page via direct link, instead of via navigate()
  // so pathname is "/summary/{random}" instead of "/summary"
  // Matches "/summary/{random}" where random is alphanumeric and may contain underscores
  const link_pattern = new RegExp(`^${PATH_NAME.Parent_View_Summary}/[a-zA-Z0-9_]+$`);

  const is_summary_link = link_pattern.test(location_pathname);

  // console.log("utils.js >> is_summary_page :: is_summary_link: ", is_summary_link);
  return is_summary_link;
};

export function isClassSenior( class_level )
{
  // console.log("isClassSenior: class_level = ", class_level);

  return (class_level === "HEEP" || class_level === "HE L1" || class_level === "HE L2" || class_level === "HI");
}

export function hasTestEveryCycle( class_level )
{
  // console.log("hasTestEveryCycle: class_level = ", class_level);

  // These class levels have tests every 6 weeks
  return (class_level === "HE L1" || class_level === "HE L2" || class_level === "HI");
}