import React, { useEffect, useRef, useCallback, useState } from "react";
import { Chart } from "chart.js/auto";
import {
        CHART_SETTINGS,
        CHART_COLORS,
      } from "./ChartData8HE";
import { get_x_axis_label } from "../../../../app/chart_utils";
import { UI_STRING_REPORT_8_HE } from "../../../../app/strings";

const ChartViewer8HE = ({ chart_data }) => {

  const chartRefs = useRef([]); // Array of canvas refs
  const chartInstances = useRef([]); // Array of chart instance refs
  
  const [ curr_chart_data ] = useState( chart_data);

  const [isCanvasReady, setIsCanvasReady] = useState(false); // Track if canvas is ready

  const calculateWeeklyScores = useCallback((content_array) => {

    const numWeeks = content_array.length;

    const week_numbers = Array.from({ length: numWeeks }, (_, i) => `${ content_array[i].week_number }`) ;

    // console.log("ChartViewer8HE.js >> calculateWeeklyScores >> week_numbers: ", week_numbers);

    const values_array_by_week = [];
    const labels_array_by_week = [];
    const title_by_week = [];

    week_numbers.map((weekNumber, index) => {
      const content = content_array[index].content;

      // Create an array to store values for the current week
      const values_array = [];
      const labels_array = [];
      
      // Initialize a counter to track the current index
      let counter = 0; 

      // Loop through the content object and extract keys that start with the weekNumber
      for (const key in content) {
        if (key.startsWith(`_${weekNumber}_`)) {

          values_array.push(content[key]); // Push matching value into the array

          // Dynamically create the label key for UI_STRING_REPORT_8_HE
          const metricKey = `Week_${weekNumber}_Metric_${counter + 1}`;

          // Use the dynamically created key to access UI_STRING_REPORT_8_HE
          const label = UI_STRING_REPORT_8_HE[metricKey];
          
          labels_array.push(label); // Push matching key into

          // Increment the counter for the next iteration
          counter++;
        }
      }

      values_array_by_week.push(values_array); // Add the values array to the result array
      labels_array_by_week.push(labels_array); // Add the labels array to the result array

      const title_key = `Week_${weekNumber}`;

      title_by_week.push(UI_STRING_REPORT_8_HE[title_key]); // Add the title to the result array

      return 1; // Return a value to avoid a warning
      
    });

    // console.log("ChartViewer8HE.js >> calculateWeeklyScores >> values_array: ", values_array_by_week);
    // console.log("ChartViewer8HE.js >> calculateWeeklyScores >> labels_array: ", labels_array_by_week);

    const result = {
      values_array_by_week,
      labels_array_by_week,
      title_by_week
    };

    return result; // Return filtered content (only the values) for the current week

  }, []); // Dependency array passed 

  useEffect(() => {

    // Check if all canvas refs are ready and set the flag to true
    if (chart_data.length > 0 && chartRefs.current.length === chart_data.length)
    {
      // set_curr_chart_data( chart_data );

      setIsCanvasReady(true);
    }
  }, [chart_data]);

  useEffect(() => {
    if (!isCanvasReady) return;

    // Store the current chart instances locally
    const currentChartInstances = chartInstances.current;

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare chart data
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////

    const numWeeks = curr_chart_data.length;

    // X-axis labels for the chart
    const x_labels = Array.from({ length: numWeeks }, (_, i) => `${ get_x_axis_label( curr_chart_data[i] ) }`);
    const titles = calculateWeeklyScores(curr_chart_data).title_by_week;

    const weekly_scores = calculateWeeklyScores(curr_chart_data).values_array_by_week;
    const weekly_labels = calculateWeeklyScores(curr_chart_data).labels_array_by_week;

    // console.log("ChartViewer8HE.js >> useEffect >> weekly_scores: ", weekly_scores);

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare canvas and chart instances
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////
    
    weekly_scores.forEach((data, index) => {
      const canvas = chartRefs.current[index];
      const ctx = canvas?.getContext("2d", { willReadFrequently: true });

      if (!ctx) 
      {
        console.error("Unable to get 2D context for the canvas.");
        return;
      }

      // Destroy the chart instance if it already exists to avoid creating duplicate charts
      if (currentChartInstances[index])
      {
        currentChartInstances[index].destroy();
      }

      // Get the color from the palette, rotating every 6 charts
      const color = CHART_COLORS[index % CHART_COLORS.length];

      // Create a new radar chart for each dataset
      currentChartInstances[index] = new Chart(ctx, {
        type: "radar",
        data: {
          labels: weekly_labels[index], // The label for each chart metric
          datasets: [
            {
              label: `${titles[index]} (on ${x_labels[index]})`,
              data: weekly_scores[index], // The actual data for each chart
              fill: true,
              backgroundColor: color.backgroundColor,
              borderColor: color.borderColor,
              pointBackgroundColor: color.borderColor,
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: color.borderColor
            }
          ]
        },
        options: CHART_SETTINGS.options
      });
    });

    // Cleanup on component unmount
    return () => {
      currentChartInstances.forEach((chart) => {
        if (chart) {
          chart.destroy();
        }
      });
    };
  }, [isCanvasReady, curr_chart_data, calculateWeeklyScores]); // Dependency array includes isCanvasReady and curr_chart_data

  return (
    <>

    {
        (!curr_chart_data || curr_chart_data.length === 0) &&
        <div className="text-gray-100 text-lg font-semibold mb-4">
            Loading charts, please wait...
        </div>
    }

    <div className="bg-transparent my-16">
        <div className="text-gray-100 text-3xl font-semibold mb-2">Topic: {curr_chart_data["0"]?.content.key_topic || ""}</div>
    </div> 

    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {curr_chart_data.map((_, index) => (
        <div key={index} className="bg-white rounded-xl p-6 shadow-md md:col-span-1"
              // style={{ width: "600px", height: "600px" }}
        >
          <canvas
            ref={(el) => (chartRefs.current[index] = el)} // Adjust index for chartRefs
            // width="320"
            // height="240"
            className="pb-8"
          />
        </div>
      ))}
    </div>

    </>
  );
};

export default ChartViewer8HE;