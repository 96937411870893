import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStudentsByID, getStudentsByName, resetStudents } from "../features/students/studentSlice";
import { FaSearch } from "react-icons/fa";
import { STUDENT_ID_PREFIX } from "../app/constants";

const StudentSearchComponent = ({ is_multiple, on_result_click }) => {

  const dispatch = useDispatch();
  const { students, is_reading_students, is_success_reading_students } = useSelector((state) => state.students);

  const [search_student_query, set_search_student_query] = useState("");
  const [student_results, set_student_results] = useState([]);
  const [no_results, set_no_results] = useState(false);
  const [debounce_timeout, set_debounce_timeout] = useState(null);

  useEffect(() => {

    // Reset everything on component mount or when input is empty
    if (search_student_query === "")
    {
      // console.log("StudentSearchComponent.js >> Clearing input field and results...");
      set_student_results([]);
      set_no_results(false);
      dispatch( resetStudents() );  // Reset the Redux state for students
    }
  }, [search_student_query, dispatch]);

  useEffect(() => {
        
    if (is_success_reading_students)
    {
      if (students && students.length > 0)
      {
        // console.log("StudentSearchComponent.js >> Setting loaded results...");

        set_student_results(students);
        set_no_results(false);
      }
      else
      {
        // console.log("StudentSearchComponent.js >> No results...");
        set_student_results([]);
        set_no_results(true);
      }
    }

    // This represents the unmount function,
    // in which it's a good idea to reset to prevent memory leaks.
    return () => {

      // console.log("StudentSearchComponent.js >> Unmounting, clear student data...");

      // Cleanup function to reset state when component unmounts or reloads
      dispatch( resetStudents() );
    };
    
  }, [students, is_success_reading_students, dispatch]);

  // Determine if the search is by name or ID
  const handle_student_search = async (query) => {

    if (query.length > 1)
    {
      try
      {
        const id_prefixed_regex = /^SYL\d{1,4}$/i;  // Regular expression for "SYL" followed by 1 to 4 digits, case-insensitive
        const id_numbered_regex = /^\d{1,4}$/;  // Regular expression for 1 to 4 digits
        const name_regex = /^[a-zA-Z\s]+$/;  // Regular expression for alphabets and spaces only
      
        if (id_prefixed_regex.test(query))
        {
          // console.log("StudentSearchComponent.js >> ID (prefixed) query detected... id = ", query);

          // Search by ID
          dispatch(getStudentsByID(query));
        }
        else if (id_numbered_regex.test(query))
        {
          // console.log("StudentSearchComponent.js >> ID query detected... id = ", query);

          // Search by ID - prefix it first
          dispatch(getStudentsByID(STUDENT_ID_PREFIX + query));
        }
        else if (name_regex.test(query))
        {
          // console.log("StudentSearchComponent.js >> Name query detected...");

          // Search by Name
          dispatch(getStudentsByName(query));
        }
      }
      catch (error)
      {
        console.error("Failed to fetch students", error);
      }
    }
    else
    {
      // console.log("StudentSearchComponent.js >> handle_student_search :: fewer than 2 characters, clear results...");

      set_student_results([]);
    }
  };

  // Handle input change for student search with debounce
  const handle_input_change = (e) => {

    const query = e.target.value;

    // Set latest input string as the search query
    set_search_student_query(query);

    // Reset state when input is cleared
    if (query === "")
    {
      // console.log("StudentSearchComponent.js >> handle_input_change :: empty input...");

      set_student_results([]);
      set_no_results(false);
      return;
    }

    // Clear any existing timeout
    if (debounce_timeout) clearTimeout(debounce_timeout);

    // Debounce the search:
    // This ensures the search is not triggered on every keystroke but waits until the user has stopped typing for a moment.
    // Set a new timeout for search after 200ms
    const new_timeout = setTimeout(() => {

      // console.log("StudentSearchComponent.js >> handle_input_change :: time to fire handle_student_search...");

      handle_student_search(query);

      // The search is delayed by 200 milliseconds to avoid excessive API calls when the user types.
    }, 200);

    set_debounce_timeout(new_timeout);
  };

  // Handle Enter key press for search
  const handle_key_down = (e) => {

    if (e.key === "Enter")
    {
      // When the Enter key is pressed, any pending debounce search is cleared,
      // and the search is triggered immediately.

      // Clear the debounce timeout when Enter is pressed
      if (debounce_timeout) clearTimeout(debounce_timeout);

      // console.log("StudentSearchComponent.js >> handle_key_down :: enter pressed, fire handle_student_search...");

      handle_student_search(search_student_query);
    }
  };

  // const go_to_next_page = (student) => {
  //   if (student && student.student_id && student.student_name)
  //   {
  //       navigate(next_page + student.student_id + "/" + student.student_name);
  //   }
  // };

  return (
      (
      <>
      {/* <div className="p-4 tracking-wider"> */}

        {/* Student Search Field */}
        <div className="form_group">
            {/* <label className="text-gray-700 font-bold text-xl">Search Students by Name:</label> */}
            <input
                type="text"
                className="form_control"
                placeholder="Enter student's name or number (SYL####) to search"
                value={search_student_query}
                // onChange={handle_student_search}
                onChange={handle_input_change}
                onKeyDown={handle_key_down}
            />
            { is_reading_students ? <div className="text-gray-500 text-left font-bold text-2xl my-2">Searching...</div>
            :
            ( no_results ? <div className="text-gray-500 text-left font-bold text-2xl my-2">No results found</div>
            :
              (<>
              <ul className="search_results">
                  {student_results.map((student) => (
                      is_multiple ?
                      (
                          <li className="text-gray-800 text-left text-xl" key={student.student_id}>
                            <button type="button" onClick={() => on_result_click(student)}>
                                [+] &nbsp; {student.student_name + " (" + student.student_id + ")"}
                            </button>
                          </li>
                      )
                      :
                      (
                        <li className="text-gray-700 text-left font-regular text-2xl my-2" key={student.student_id && student.student_id}>
                            <button type="button" onClick={() => on_result_click(student)}>
                              <span className="flex items-center">
                                <FaSearch />&nbsp;&nbsp; 
                                <span className="font-medium">{student.student_name}</span>
                                &nbsp; ({student.student_id && student.student_id})
                              </span>
                            </button>
                        </li>
                      )
                  ))}
              </ul>
              </>)
            )}
        </div>
      {/* </div> */}
      </>
      )
  );
};

export default StudentSearchComponent;
