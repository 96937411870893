import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        FREQUENCIES,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_2_PINK,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm2Pink = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>
    
    {/* <ReportFormSection
        type="radio"
        label={`1. ${UI_STRING_REPORT_2_PINK.Group_1}`}
        name="_1_"
        value={formData.content._1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
    />

    <hr className="mt-8 mb-8 thin" /> */}

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`1. ${UI_STRING_REPORT_2_PINK.Group_1}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`1.1 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_1}`}
        name="_1_1_"
        value={formData.content._1_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_1_"]}
    />

    <ReportFormSection
        type="radio"
        label={`1.2 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_2}`}
        name="_1_2_"
        value={formData.content._1_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_2_"]}
    />

    <ReportFormSection
        type="radio"
        label={`1.3 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_3}`}
        name="_1_3_"
        value={formData.content._1_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_3_"]}
    />

    <ReportFormSection
        type="radio"
        label={`1.4 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_4}`}
        name="_1_4_"
        value={formData.content._1_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_4_"]}
    />

    <ReportFormSection
        type="radio"
        label={`1.5 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_5}`}
        name="_1_5_"
        value={formData.content._1_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_5_"]}
    />

    <ReportFormSection
        type="radio"
        label={`1.6 ${UI_STRING_REPORT_2_PINK.Group_1_Metric_6}`}
        name="_1_6_"
        value={formData.content._1_6_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`2. ${UI_STRING_REPORT_2_PINK.Group_2}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`2.1 ${UI_STRING_REPORT_2_PINK.Group_2_Metric_1}`}
        name="_2_1_"
        value={formData.content._2_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_1_"]}
    />

    <ReportFormSection
        type="radio"
        label={`2.2 ${UI_STRING_REPORT_2_PINK.Group_2_Metric_2}`}
        name="_2_2_"
        value={formData.content._2_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        type="radio"
        label={`3. ${UI_STRING_REPORT_2_PINK.Group_3}`}
        name="_3_"
        value={formData.content._3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`4. ${UI_STRING_REPORT_2_PINK.Group_4}`}
        name="_4_"
        value={formData.content._4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`5. ${UI_STRING_REPORT_2_PINK.Group_5}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`5.1 ${UI_STRING_REPORT_2_PINK.Group_5_Metric_1}`}
        name="_5_1_"
        value={formData.content._5_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_1_"]}
    />

    <ReportFormSection
        type="radio"
        label={`5.2 ${UI_STRING_REPORT_2_PINK.Group_5_Metric_2}`}
        name="_5_2_"
        value={formData.content._5_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_2_"]}
    />

    <ReportFormSection
        type="radio"
        label={`5.3 ${UI_STRING_REPORT_2_PINK.Group_5_Metric_3}`}
        name="_5_3_"
        value={formData.content._5_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_3_"]}
    />

    <ReportFormSection
        type="radio"
        label={`5.4 ${UI_STRING_REPORT_2_PINK.Group_5_Metric_4}`}
        name="_5_4_"
        value={formData.content._5_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_4_"]}
    />

    <ReportFormSection
        type="radio"
        label={`5.5 ${UI_STRING_REPORT_2_PINK.Group_5_Metric_5}`}
        name="_5_5_"
        value={formData.content._5_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`6. ${UI_STRING_REPORT_2_PINK.Group_6}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`6.1 ${UI_STRING_REPORT_2_PINK.Group_6_Metric_1}`}
        name="_6_1_"
        value={formData.content._6_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_6_1_"]}
    />

    <ReportFormSection
        type="radio"
        label={`6.2 ${UI_STRING_REPORT_2_PINK.Group_6_Metric_2}`}
        name="_6_2_"
        value={formData.content._6_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_6_2_"]}
    />

    <ReportFormSection
        type="radio"
        label={`6.3 ${UI_STRING_REPORT_2_PINK.Group_6_Metric_3}`}
        name="_6_3_"
        value={formData.content._6_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_6_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`7. ${UI_STRING_REPORT_2_PINK.Group_7}`}
    </div>

    <ReportFormSection
        type="radio"
        label={`7.1 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_1}`}
        name="_7_1_"
        value={formData.content._7_1_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_1_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.2 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_2}`}
        name="_7_2_"
        value={formData.content._7_2_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_2_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.3 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_3}`}
        name="_7_3_"
        value={formData.content._7_3_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_3_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.4 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_4}`}
        name="_7_4_"
        value={formData.content._7_4_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_4_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.5 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_5}`}
        name="_7_5_"
        value={formData.content._7_5_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_5_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.6 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_6}`}
        name="_7_6_"
        value={formData.content._7_6_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_6_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.7 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_7}`}
        name="_7_7_"
        value={formData.content._7_7_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_7_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.8 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_8}`}
        name="_7_8_"
        value={formData.content._7_8_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_8_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.9 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_9}`}
        name="_7_9_"
        value={formData.content._7_9_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_9_"]}
    />

    <ReportFormSection
        type="radio"
        label={`7.10 ${UI_STRING_REPORT_2_PINK.Group_7_Metric_10}`}
        name="_7_10_"
        value={formData.content._7_10_}
        options={FREQUENCIES}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_10_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />
    
    </div>
  );
};

export default UpdateReportForm2Pink;