import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import recurringClassService from "./recurringClassService";

const initialState_AddRecurringClass = {
    new_recurring_class: null,
    is_adding_recurring_class: false,
    is_added_recurring_class: false,
    is_error_adding_recurring_class: false,
    message_for_adding_recurring_class: ""
};

const initialState_FetchRecurringClassesByDay = {
    recurring_classes_on_day: [],
    is_fetching_recurring_classes_by_day: false,
    is_fetched_recurring_classes_by_day: false,
    is_error_fetching_recurring_classes_by_day: false,
    message_for_fetching_recurring_classes_by_day: ""
};

const initialState_FetchRecurringClass = {
    fetched_recurring_class: null,
    is_fetching_recurring_class: false,
    is_fetched_recurring_class: false,
    is_error_fetching_recurring_class: false,
    message_for_fetching_recurring_class: "",
}

const initialState_UpdateRecurringClass = {
    updated_recurring_class: null,
    is_updating_recurring_class: false,
    is_updated_recurring_class: false,
    is_error_updating_recurring_class: false,
    message_for_updating_recurring_class: "",
}

const initialState_TrashRecurringClass = {
    trashed_recurring_class: null,
    is_trashing_recurring_class: false,
    is_trashed_recurring_class: false,
    is_error_trashing_recurring_class: false,
    message_for_trashing_recurring_class: "",
}

const initialState = {
    ...initialState_AddRecurringClass,
    ...initialState_FetchRecurringClassesByDay,
    ...initialState_FetchRecurringClass,
    ...initialState_UpdateRecurringClass,
    ...initialState_TrashRecurringClass,
};

export const addRecurringClass = createAsyncThunk(
    "recurring_classes/add",
    async (class_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.addRecurringClass(class_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getRecurringClasses = createAsyncThunk(
    "recurring_classes/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.getRecurringClasses(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getRecurringClassesByDay = createAsyncThunk(
    "recurring_classes/get_by_day",
    async (day_of_week, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.getRecurringClassesByDay(day_of_week, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const fetchRecurringClassById = createAsyncThunk(
    "recurring_classes/fetch_by_id",
    async (class_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await recurringClassService.fetchRecurringClassById(class_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const updateRecurringClass = createAsyncThunk(
    "recurring_classes/update",
    async (class_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await recurringClassService.updateRecurringClass(class_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const trashRecurringClass = createAsyncThunk(
    "recurring_classes/trash",
    async (class_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await recurringClassService.trashRecurringClass(class_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const recurringClassSlice = createSlice({
    name: "recurring_classes",
    initialState,
    reducers: {
        resetRecurringClases: state => initialState,
        resetAddRecurringClass: state => initialState_AddRecurringClass,
        resetFetchRecurringClassesByDay: state => initialState_FetchRecurringClassesByDay,
        resetFetchRecurringClass: state => initialState_FetchRecurringClass,
        resetUpdateRecurringClass: state => initialState_UpdateRecurringClass,
        resetTrashRecurringClass: state => initialState_TrashRecurringClass,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addRecurringClass.pending, state => {
                state.is_adding_recurring_class = true;
                state.is_added_recurring_class = false;
                state.is_error_adding_recurring_class = false;
                state.message_for_adding_recurring_class = "";
            })
            .addCase(addRecurringClass.fulfilled, (state, action) => {
                state.is_adding_recurring_class = false;
                state.is_added_recurring_class = true;
                state.is_error_adding_recurring_class = false;
                state.new_recurring_class = action.payload;
            })
            .addCase(addRecurringClass.rejected, (state, action) => {
                state.is_adding_recurring_class = false;
                state.is_added_recurring_class = false;
                state.is_error_adding_recurring_class = true;
                state.message_for_adding_recurring_class = action.payload;
            })

            .addCase(getRecurringClasses.pending, (state) => {
                state.is_loading = true;
                state.is_reading_classes = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getRecurringClasses.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading_classes = false;
                state.is_success = true;
                state.recurring_classes = action.payload;
            })
            .addCase(getRecurringClasses.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading_classes = false;
                state.is_error = true;
                state.message = action.payload;
            })

            .addCase(getRecurringClassesByDay.pending, (state) => {
                state.is_fetching_recurring_classes_by_day = true;
                state.is_fetched_recurring_classes_by_day = false;
                state.is_error_fetching_recurring_classes_by_day = false;
                state.message_for_fetching_recurring_classes_by_day = "";
            })
            .addCase(getRecurringClassesByDay.fulfilled, (state, action) => {
                state.is_fetching_recurring_classes_by_day = false;
                state.is_fetched_recurring_classes_by_day = true;
                state.is_error_fetching_recurring_classes_by_day = false;
                state.recurring_classes_on_day = action.payload;
            })
            .addCase(getRecurringClassesByDay.rejected, (state, action) => {
                state.is_fetching_recurring_classes_by_day = false;
                state.is_fetched_recurring_classes_by_day = false;
                state.is_error_fetching_recurring_classes_by_day = true;
                state.message_for_fetching_recurring_classes_by_day = action.payload;
            })

            .addCase(fetchRecurringClassById.pending, (state) => {
                state.is_fetching_recurring_class = true;
                state.is_fetched_recurring_class = false;
                state.is_error_fetching_recurring_class = false;
                state.message_for_fetching_recurring_class = "";
            })
            .addCase(fetchRecurringClassById.fulfilled, (state, action) => {
                state.is_fetching_recurring_class = false;
                state.is_fetched_recurring_class = true;
                state.is_error_fetching_recurring_class = false;
                state.fetched_recurring_class = action.payload;
            })
            .addCase(fetchRecurringClassById.rejected, (state, action) => {
                state.is_fetching_recurring_class = false;
                state.is_fetched_recurring_class = false;
                state.is_error_fetching_recurring_class = true;
                state.message_for_fetching_recurring_class = action.payload;
            })

            .addCase(updateRecurringClass.pending, (state) => {
                state.is_updating_recurring_class = true;
                state.is_updated_recurring_class = false;
                state.is_error_updating_recurring_class = false;
                state.message_for_updating_recurring_class = "";
            })
            .addCase(updateRecurringClass.fulfilled, (state, action) => {
                state.is_updating_recurring_class = false;
                state.is_updated_recurring_class = true;
                state.is_error_updating_recurring_class = false;
                state.updated_recurring_class = action.payload;
            })
            .addCase(updateRecurringClass.rejected, (state, action) => {
                state.is_updating_recurring_class = false;
                state.is_updated_recurring_class = false;
                state.is_error_updating_recurring_class = true;
                state.message_for_updating_recurring_class = action.payload;
            })

            .addCase(trashRecurringClass.pending, (state) => {
                state.is_trashing_recurring_class = true;
                state.is_trashed_recurring_class = false;
                state.is_error_trashing_recurring_class = false;
                state.message_for_trashing_recurring_class = "";
            })
            .addCase(trashRecurringClass.fulfilled, (state, action) => {
                state.is_trashing_recurring_class = false;
                state.is_trashed_recurring_class = true;
                state.is_error_trashing_recurring_class = false;
                state.trashed_recurring_class = action.payload;
            })
            .addCase(trashRecurringClass.rejected, (state, action) => {
                state.is_trashing_recurring_class = false;
                state.is_trashed_recurring_class = false;
                state.is_error_trashing_recurring_class = true;
                state.message_for_trashing_recurring_class = action.payload;
            })
    }
});

export const { resetRecurringClasses,
                resetAddRecurringClass,
                resetFetchRecurringClassesByDay,
                resetFetchRecurringClass,
                resetUpdateRecurringClass,
                resetTrashRecurringClass

            } = recurringClassSlice.actions;

export default recurringClassSlice.reducer;