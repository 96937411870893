import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { FaPlus } from "react-icons/fa";
import HeaderAdmin from "./for_admins/HeaderAdmin";
import FooterAdmin from "./for_admins/FooterAdmin";
import HeaderTeacher from "./for_teachers/HeaderTeacher";
import FooterTeacher from "./for_teachers/FooterTeacher";
import LessonTable from "./LessonTable";
import { getDate_HKT, DATE_FORMAT_FULL } from "../app/date_utils";
import { can_access_page } from "../app/user_utils";
import { PATH_NAME } from "../app/constants";

// the_date is a string in the right format "DD-MM-YYYY"
const DayPage = () => {

    const { the_date } = useParams();

    const input_date = the_date ? the_date : null;

    // Convert to the right format for lesson table
    // yyyy-mm-dd to dd-mm-yyyy
    // const [ formatted_date_DDMMYYYY ] = useState( the_date.split('-').reverse().join('-') );
    const [ formatted_date_YYYYMMDD ] = useState( the_date.split('-').reverse().join('-') );

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {

        if (can_access_page(user, PATH_NAME.Day_Lessons) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        user &&
        <>
            <div className="basic_bg font-sans">
                
                {
                    user.role === "admin" ? <HeaderAdmin /> : <HeaderTeacher />
                }

                <div className="dashboard_container">
                    <section className="heading">
                        <div className="px-6 py-3 text-center font-extrabold text-white">
                            { getDate_HKT( formatted_date_YYYYMMDD, DATE_FORMAT_FULL) }
                            {/* { getDate_HKT( input_date, DATE_FORMAT_FULL) } */}
                        </div>
                        <h1>Classes</h1>
                    </section>

                    <LessonTable the_date={ input_date } the_teacher={ user.role === "teacher" ? user : null }/>
                    {/* This causes extra re-rendering */}
                    {/* <LessonTable the_teacher={[]} the_date={ formatted_date_DDMMYYYY }/> */}
                </div>

                {
                    user.role === "admin" ? <FooterAdmin /> : <FooterTeacher />
                }
                
            </div>
        </>
    );
}

export default DayPage;