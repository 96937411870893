import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { FaPlus } from "react-icons/fa";
import HeaderAdmin from "../HeaderAdmin";
import FooterAdmin from "../FooterAdmin";
import StudentReportsTable from "./StudentReportsTable";
import StudentInfoBox from "./StudentInfoBox";
import { can_access_page } from "../../../app/user_utils";
import { PATH_NAME } from "../../../app/constants";

const StudentReportsPage = () => {

    const { student_id, student_name } = useParams();

    const this_student_id = student_id ? student_id : null;
    const this_student_name = student_name ? student_name : null;

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {

        if (can_access_page(user, PATH_NAME.Admin_Student_Reports) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                    <section className="heading">
                        <h1>Student Reports</h1>
                    </section>
                    
                    <StudentInfoBox student_id={this_student_id} student_name={this_student_name} />

                    <StudentReportsTable student_id={this_student_id} student_name={this_student_name} />
                </div>

                <FooterAdmin />
            </div>
        </>
    );
}

export default StudentReportsPage;