import React, { useEffect, useCallback, useState } from "react";
// import React, { useEffect, useRef, useCallback, useState } from "react";
// import { Chart } from "chart.js/auto";
// import {
//         CHART_SETTINGS,
//         CHART_COLORS,
//       } from "./ChartData11Reading";
import { get_x_axis_label } from "../../../../app/chart_utils";
import { UI_STRING_REPORT_11_READING } from "../../../../app/strings";

const ChartViewer11Reading = ({ chart_data }) => {

  // const chartRefs = useRef([]); // Array of canvas refs
  // const chartInstances = useRef([]); // Array of chart instance refs
  
  const [ curr_chart_data ] = useState( chart_data );

  const [isCanvasReady, setIsCanvasReady] = useState(false); // Track if canvas is ready

  const [x_labels, set_x_labels] = useState([]);
  const [titles, set_titles] = useState([]);
  const [weekly_scores, set_weekly_scores] = useState([]);
  const [weekly_labels, set_weekly_labels] = useState([]);

  const getWeeklyValues = useCallback((content_array) => {

    const numWeeks = content_array.length;

    const week_numbers = Array.from({ length: numWeeks }, (_, i) => `${ content_array[i].week_number }`) ;

    // console.log("ChartViewer11Reading.js >> calculateWeeklyScores >> week_numbers: ", week_numbers);

    const values_array_by_week = [];
    const labels_array_by_week = [];
    const title_by_week = [];

    week_numbers.map((weekNumber, index) => {
      const content = content_array[index].content;

      // Create an array to store values for the current week
      const values_array = [];
      const labels_array = [];
      
      // Initialize a counter to track the current index
      let counter = 0; 

      // Loop through the content object and extract keys that start with the weekNumber
      for (const key in content) {
        if (key.startsWith(`_${weekNumber}_`)) {

          values_array.push(content[key]); // Push matching value into the array

          // Dynamically create the label key for UI_STRING_REPORT_11_READING
          const metricKey = `Week_${weekNumber}_Metric_${counter + 1}`;

          // Use the dynamically created key to access UI_STRING_REPORT_11_READING
          const label = UI_STRING_REPORT_11_READING[metricKey];
          
          labels_array.push(label); // Push matching key into

          // Increment the counter for the next iteration
          counter++;
        }
      }

      values_array_by_week.push(values_array); // Add the values array to the result array
      labels_array_by_week.push(labels_array); // Add the labels array to the result array

      const title_key = `Week_${weekNumber}`;

      title_by_week.push(UI_STRING_REPORT_11_READING[title_key]); // Add the title to the result array

      return 1; // Return a value to avoid a warning
    });

    // console.log("ChartViewer11Reading.js >> calculateWeeklyScores >> values_array: ", values_array_by_week);
    // console.log("ChartViewer11Reading.js >> calculateWeeklyScores >> labels_array: ", labels_array_by_week);

    const result = {
      values_array_by_week,
      labels_array_by_week,
      title_by_week
    };

    return result; // Return filtered content (only the values) for the current week

  }, []); // Dependency array passed 

  useEffect(() => {

    // Check if all canvas refs are ready and set the flag to true
    if (chart_data.length > 0)
    // if (chart_data.length > 0 && chartRefs.current.length === chart_data.length)
    {
      // set_curr_chart_data( chart_data );

      setIsCanvasReady(true);
    }
  }, [chart_data]);

  useEffect(() => {
    if (!isCanvasReady) return;

    // // Store the current chart instances locally
    // const currentChartInstances = chartInstances.current;

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare chart data
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////

    const numWeeks = curr_chart_data.length;

    // X-axis labels for the chart
    set_x_labels( Array.from({ length: numWeeks }, (_, i) => `${ get_x_axis_label( curr_chart_data[i] ) }`) );

    set_titles( getWeeklyValues(curr_chart_data).title_by_week );
    set_weekly_scores( getWeeklyValues(curr_chart_data).values_array_by_week );
    set_weekly_labels( getWeeklyValues(curr_chart_data).labels_array_by_week );
    // const titles = getWeeklyValues(curr_chart_data).title_by_week;
    // const weekly_scores = getWeeklyValues(curr_chart_data).values_array_by_week;
    // const weekly_labels = getWeeklyValues(curr_chart_data).labels_array_by_week;

    // console.log("ChartViewer11Reading.js >> useEffect >> weekly_scores: ", weekly_scores);

    //////////////////////////////////////////////////////////////
    //------------------------------------------------------------
    // Prepare canvas and chart instances
    //------------------------------------------------------------
    //////////////////////////////////////////////////////////////
    
    // weekly_scores.forEach((data, index) => {
    //   const canvas = chartRefs.current[index];
    //   const ctx = canvas?.getContext("2d", { willReadFrequently: true });

    //   if (!ctx) 
    //   {
    //     console.error("Unable to get 2D context for the canvas.");
    //     return;
    //   }

    //   // Destroy the chart instance if it already exists to avoid creating duplicate charts
    //   if (currentChartInstances[index])
    //   {
    //     currentChartInstances[index].destroy();
    //   }

    //   // Get the color from the palette, rotating every 6 charts
    //   const color = CHART_COLORS[index % CHART_COLORS.length];

    //   // Create a new radar chart for each dataset
    //   currentChartInstances[index] = new Chart(ctx, {
    //     type: "radar",
    //     data: {
    //       labels: weekly_labels[index], // The label for each chart metric
    //       datasets: [
    //         {
    //           label: `${titles[index]} (on ${x_labels[index]})`,
    //           data: weekly_scores[index], // The actual data for each chart
    //           fill: true,
    //           backgroundColor: color.backgroundColor,
    //           borderColor: color.borderColor,
    //           pointBackgroundColor: color.borderColor,
    //           pointBorderColor: "#fff",
    //           pointHoverBackgroundColor: "#fff",
    //           pointHoverBorderColor: color.borderColor
    //         }
    //       ]
    //     },
    //     options: CHART_SETTINGS.options
    //   });
    // });

    // // Cleanup on component unmount
    // return () => {
    //   currentChartInstances.forEach((chart) => {

    //     if (chart)
    //     {
    //       chart.destroy();
    //     }
    //   });
    // };
  }, [isCanvasReady, curr_chart_data, getWeeklyValues]); // Dependency array includes isCanvasReady and curr_chart_data

  return (
    <>

    {
        (!curr_chart_data || curr_chart_data.length === 0) &&
        <div className="text-gray-100 text-lg font-semibold mb-4">
            Loading data, please wait...
        </div>
    }

    <div className="bg-transparent my-16">
        <div className="text-gray-100 text-3xl font-semibold mb-2">Level: {curr_chart_data["3"]?.content.sub_level || ""}</div>
    </div> 

    <div>
      {titles.map((title, i) => (
        // <div key={i} style={{ marginBottom: '20px' }}>
        <div key={i} className="bg-white rounded-xl p-6 shadow-md mb-16">
          <div className="text-hots-green-1 font-semibold text-2xl mt-6 mb-8">Week {i + 1} on {x_labels[i]}</div>

          <hr className="mt-4 mb-10 border-gray-400 border-t-2"></hr>
          
          <ul>
            {weekly_scores[i].map((score, j) => (
              <li key={j} className="mb-6">
                <span className="text-blue-950 text-xl font-bold"> {weekly_labels[i][j]}: <br /></span>
                <span className="text-gray-700 text-xl font-regular"> {score ? score : "N/A"}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>

    </>
  );
};

export default ChartViewer11Reading;