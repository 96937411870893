import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import lessonService from "./lessonService";

const initialState_AddLesson = {
    new_lesson: null,
    is_adding: false,
    is_added_lesson: false,
    is_error_adding_lesson: false,
    message_for_adding_lesson: "",
}

const initialState_FetchLessonsByDate = {
    lessons_on_date: [],
    is_fetching_lessons_by_date: false,
    is_fetched_lessons_by_date: false,
    is_error_fetching_lessons_by_date: false,
    message_for_fetching_lessons_by_date: "",
}

const initialState_FetchLesson = {
    fetched_lesson: null,
    is_fetching_lesson: false,
    is_fetched_lesson: false,
    is_error_fetching_lesson: false,
    message_for_fetching_lesson: "",
}

const initialState_UpdateLesson = {
    updated_lesson: null,
    is_updating_lesson: false,
    is_updated_lesson: false,
    is_error_updating_lesson: false,
    message_for_updating_lesson: "",
}

const initialState_TrashLesson = {
    trashed_lesson: null,
    is_trashing_lesson: false,
    is_trashed_lesson: false,
    is_error_trashing_lesson: false,
    message_for_trashing_lesson: "",
}

const initialState = {
    lessons: [],
    is_error: false,
    is_success: false,
    is_loading: false,

    is_reading_lessons: false,
    is_error_reading_lessons: false,
    is_success_reading_lessons: false,
    message: "",

    ...initialState_AddLesson,
    ...initialState_FetchLessonsByDate,
    ...initialState_FetchLesson,
    ...initialState_UpdateLesson,
    ...initialState_TrashLesson,
};

export const addLesson = createAsyncThunk(
    "lessons/add",
    async (lesson_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            // console.log("lessonSlice.js >> addLesson :: lesson_data: ", lesson_data);

            return await lessonService.addLesson(lesson_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getLessons = createAsyncThunk(
    "lessons/get_all",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await lessonService.getLesson(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getLessonsByDate = createAsyncThunk(
    "lessons/get_by_date",
    async (date_string, thunkAPI) => {
        try
        {
            // console.log("lessonSlice.js >> getLessonsByDate :: date_string = " + date_string);

            const token = thunkAPI.getState().auth.user.token;
            return await lessonService.getLessonsByDate(date_string, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const fetchLessonById = createAsyncThunk(
    "lessons/fetch_by_id",
    async (lesson_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await lessonService.fetchLessonById(lesson_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const updateLesson = createAsyncThunk(
    "lessons/update",
    async (lesson_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await lessonService.updateLesson(lesson_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const trashLesson = createAsyncThunk(
    "lessons/trash",
    async (lesson_data, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;

            return await lessonService.trashLesson(lesson_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const lessonSlice = createSlice({
    name: "lessons",
    initialState,
    reducers: {
        resetLessons: state => initialState,
        resetAddLesson: state => initialState_AddLesson,
        resetFetchLessonsByDate: state => initialState_FetchLessonsByDate,
        resetFetchLesson: state => initialState_FetchLesson,
        resetUpdateLesson: state => initialState_UpdateLesson,
        resetTrashLesson: state => initialState_TrashLesson,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLesson.pending, state => {
                state.is_adding_lesson = true;
                state.is_added_lesson = false;
                state.is_error_adding_lesson = false;
                state.message_for_adding_lesson = "";
            })
            .addCase(addLesson.fulfilled, (state, action) => {
                state.is_adding_lesson = false;
                state.is_added_lesson = true;
                state.is_error_adding_lesson = false;
                state.new_lesson = action.payload;
            })
            .addCase(addLesson.rejected, (state, action) => {
                state.is_adding_lesson = false;
                state.is_added_lessons = false;
                state.is_error_adding_lesson = true;
                state.message_for_adding_lesson = action.payload;
            })

            .addCase(getLessons.pending, (state) => {
                state.is_loading = true;
                state.is_reading_lessons = true;
                state.is_error = false;
                state.is_success = false;
                //state.message = "";
            })
            .addCase(getLessons.fulfilled, (state, action) => {
                state.is_loading = false;
                state.is_reading_lessons = false;
                state.is_success = true;
                state.lessons = action.payload;
            })
            .addCase(getLessons.rejected, (state, action) => {
                state.is_loading = false;
                state.is_reading_lessons = false;
                state.is_error = true;
                state.message = action.payload;
            })

            .addCase(getLessonsByDate.pending, (state) => {
                state.is_fetching_lessons_by_date = true;
                state.is_fetched_lessons_by_date = false;
                state.is_error_fetching_lessons_by_date = false;
                state.message_for_fetching_lessons_by_date = "";
            })
            .addCase(getLessonsByDate.fulfilled, (state, action) => {
                state.is_fetching_lessons_by_date = false;
                state.is_fetched_lessons_by_date = true;
                state.is_error_fetching_lessons_by_date = false;
                state.lessons_on_date = action.payload;
            })
            .addCase(getLessonsByDate.rejected, (state, action) => {
                state.is_fetching_lessons_by_date = false;
                state.is_fetched_lessons_by_date = false;
                state.is_error_fetching_lessons_by_date = true;
                state.message_for_fetching_lessons_by_date = action.payload;
            })

            .addCase(fetchLessonById.pending, (state) => {
                state.is_fetching_lesson = true;
                state.is_fetched_lesson = false;
                state.is_error_fetching_lesson = false;
                state.message_for_fetching_lesson = "";
            })
            .addCase(fetchLessonById.fulfilled, (state, action) => {
                state.is_fetching_lesson = false;
                state.is_fetched_lesson = true;
                state.is_error_fetching_lesson = false;
                state.fetched_lesson = action.payload;
            })
            .addCase(fetchLessonById.rejected, (state, action) => {
                state.is_fetching_lesson = false;
                state.is_fetched_lesson = false;
                state.is_error_fetching_lesson = true;
                state.message_for_fetching_lesson = action.payload;
            })

            .addCase(updateLesson.pending, (state) => {
                state.is_updating_lesson = true;
                state.is_updated_lesson = false;
                state.is_error_updating_lesson = false;
                state.message_for_updating_lesson = "";
            })
            .addCase(updateLesson.fulfilled, (state, action) => {
                state.is_updating_lesson = false;
                state.is_updated_lesson = true;
                state.is_error_updating_lesson = false;
                state.updated_lesson = action.payload;
            })
            .addCase(updateLesson.rejected, (state, action) => {
                state.is_updating_lesson = false;
                state.is_updated_lesson = false;
                state.is_error_updating_lesson = true;
                state.message_for_updating_lesson = action.payload;
            })

            .addCase(trashLesson.pending, (state) => {
                state.is_trashing_lesson = true;
                state.is_trashed_lesson = false;
                state.is_error_trashing_lesson = false;
                state.message_for_trashing_lesson = "";
            })
            .addCase(trashLesson.fulfilled, (state, action) => {
                state.is_trashing_lesson = false;
                state.is_trashed_lesson = true;
                state.is_error_trashing_lesson = false;
                state.trashed_lesson = action.payload;
            })
            .addCase(trashLesson.rejected, (state, action) => {
                state.is_trashing_lesson = false;
                state.is_trashed_lesson = false;
                state.is_error_trashing_lesson = true;
                state.message_for_trashing_lesson = action.payload;
            })
    }
});

export const { resetLessons,
                resetAddLesson,
                resetFetchLessonsByDate,
                resetFetchLesson,
                resetUpdateLesson,
                resetTrashLesson
            } = lessonSlice.actions;
            
export default lessonSlice.reducer;