import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import summaryService from './summaryService';

// const initialState_Creation = {
//     new_summary: null,
//     is_creating_summary: false,
//     is_created_summary: false,
//     is_error_creating_summary: false,
//     message_for_creating_summary: "",
// };

const initialState_Generation = {
    output_summary: null,
    is_generating_summary: false,
    is_generated_summary: false,
    is_error_generating_summary: false,
    message_for_generating_summary: "",
};

const initialState_Fetch = {
    fetched_summary: null,
    is_fetching_summary: false,
    is_fetched_summary: false,
    is_error_fetching_summary: false,
    message_for_fetching_summary: "",
};

const initialState_Sign = {
    is_signing_summary: false,
    is_signed_summary: false,
    is_error_signing_summary: false,
    message_for_signing_summary: "",
};

const initialState_Update = {
    is_updating_summary: false,
    is_updated_summary: false,
    is_error_updating_summary: false,
    message_for_updating_summary: "",
};

const initialState_PendingSummaries = {
    is_loading_pending_summaries: false,
    is_loaded_pending_summaries: false,
    is_error_loading_pending_summaries: false,
    pending_summaries_message: "",
};

const initialState_studentSummaries = {
    student_summaries: [],
    is_loading_student_summaries: false,
    is_loaded_student_summaries: false,
    is_error_loading_student_summaries: false,
    student_summaries_message: "",
};

const initialState = {
    // ...initialState_Creation,
    ...initialState_Generation,
    ...initialState_Fetch,
    ...initialState_Sign,
    ...initialState_PendingSummaries,
    ...initialState_studentSummaries,
};

export const generateSummary = createAsyncThunk(
    "summaries/generate",
    async (input_data, thunkAPI) => {
        try
        {
            // console.log("summarySlice.js >> generateSummary :: student_id: ", input_data.student_id);

            const token = thunkAPI.getState().auth.user.token;
            return await summaryService.generateSummary(input_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getSummaryBySharedID = createAsyncThunk(
    "summaries/get_by_shared_id",
    async (shareable_id, thunkAPI) => {
        try
        {
            // console.log("summarySlice.js >> getSummaryBySharedID :: shareable_id: ", shareable_id);

            // const token = thunkAPI.getState().auth.user.token;
            return await summaryService.getSummaryBySharedID(shareable_id);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const signSummary = createAsyncThunk(
    "summaries/sign",
    async (summary_data, thunkAPI) => {
        try
        {
            // console.log("summarySlice.js >> signSummary :: summary_data: ", summary_data);

            const token = thunkAPI.getState().auth.user.token;
            return await summaryService.signSummary(summary_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const updateSummary = createAsyncThunk(
    "summaries/update",
    async (summary_data, thunkAPI) => {
        try
        {
            // console.log("summarySlice.js >> updateSummary :: summary_data: ", summary_data);

            const token = thunkAPI.getState().auth.user.token;
            return await summaryService.updateSummary(summary_data, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getAllPendingSummaries = createAsyncThunk(
    "summaries/get_all_pending",
    async (_, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            
            return await summaryService.getAllPendingSummaries(token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getSummariesByStudent = createAsyncThunk(
    "summaries/get_by_student",
    async (student_id, thunkAPI) => {
        try
        {
            const token = thunkAPI.getState().auth.user.token;
            return await summaryService.getSummariesByStudent(student_id, token);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const getTranslatedSummary = createAsyncThunk(
    "summaries/get_translated_summary",
    async (input_comments, thunkAPI) => {
        try
        {
            // console.log("summarySlice.js >> getTranslatedSummary :: input_comments: ", input_comments);
            return await summaryService.getTranslatedSummary(input_comments);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const summarySlice = createSlice({
    name: "summaries",
    initialState,
    reducers: {
        resetSummaries: state => initialState,
        // resetCreateSummary: state => initialState_Creation,
        resetGenerateSummary: state => initialState_Generation,
        resetFetchSummary: state => initialState_Fetch,
        resetSignSummary: state => initialState_Sign,
        resetUpdateSummary: state => initialState_Update,
        resetGetPendingSummariesState: state => initialState_PendingSummaries,
        resetGetStudentSummariesState: state => initialState_studentSummaries,
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateSummary.pending, state => {
                state.is_generating_summary = true;
                state.is_generated_summary = false;
                state.is_error_generating_summary = false;
            })
            .addCase(generateSummary.fulfilled, (state, action) => {
                state.is_generating_summary = false;
                state.is_generated_summary = true;
                state.is_error_generating_summary = false;
                state.output_summary = action.payload;
            })
            .addCase(generateSummary.rejected, (state, action) => {
                state.is_generating_summary = false;
                state.is_error_generating_summary = true;
                state.is_generated_summary = false;
                state.message_for_generating_summary = action.payload;
            })
            .addCase(getSummaryBySharedID.pending, state => {
                state.is_fetching_summary = true;
                state.is_fetched_summary = false;
                state.is_error_fetching_summary = false;
            })
            .addCase(getSummaryBySharedID.fulfilled, (state, action) => {
                state.is_fetching_summary = false;
                state.is_fetched_summary = true;
                state.is_error_fetching_summary = false;
                state.fetched_summary = action.payload;
            })
            .addCase(getSummaryBySharedID.rejected, (state, action) => {
                state.is_fetching_summary = false;
                state.is_fetched_summary = false;
                state.is_error_fetching_summary = true;
                state.message_for_fetching_summary = action.payload;
            })

            .addCase(signSummary.pending, state => {
                state.is_signing_summary = true;
                state.is_signed_summary = false;
                state.is_error_signing_summary = false;
            })
            .addCase(signSummary.fulfilled, (state, action) => {
                state.is_signing_summary = false;
                state.is_signed_summary = true;
                state.is_error_signing_summary = false;
            })
            .addCase(signSummary.rejected, (state, action) => {
                state.is_signing_summary = false;
                state.is_error_signing_summary = true;
                state.is_signed_summary = false;
                state.message_for_signing_summary = action.payload;
            })

            .addCase(updateSummary.pending, state => {
                state.is_updating_summary = true;
                state.is_updated_summary = false;
                state.is_error_updating_summary = false;
            })
            .addCase(updateSummary.fulfilled, (state, action) => {
                state.is_updating_summary = false;
                state.is_updated_summary = true;
                state.is_error_updating_summary = false;
            })
            .addCase(updateSummary.rejected, (state, action) => {
                state.is_updating_summary = false;
                state.is_updated_summary = false;
                state.is_error_updating_summary = true;
                state.message_for_updating_summary = action.payload;
            })

            .addCase(getAllPendingSummaries.pending, state => {
                state.is_loading_pending_summaries = true;
                state.is_loaded_pending_summaries = false;
                state.is_error_loading_pending_summaries = false;
            })
            .addCase(getAllPendingSummaries.fulfilled, (state, action) => {
                state.is_loading_pending_summaries = false;
                state.is_loaded_pending_summaries = true;
                state.is_error_loading_pending_summaries = false;
                state.pending_summaries = action.payload;
            })
            .addCase(getAllPendingSummaries.rejected, (state, action) => {
                state.is_loading_pending_summaries = false;
                state.is_loaded_pending_summaries = false;
                state.is_error_loading_pending_summaries = true;
                state.pending_summaries_message = action.payload;
            })

            .addCase(getSummariesByStudent.pending, state => {
                state.is_loading_student_summaries = true;
                state.is_loaded_student_summaries = false;
                state.is_error_loading_student_summaries = false;
            })
            .addCase(getSummariesByStudent.fulfilled, (state, action) => {
                state.is_loading_student_summaries = false;
                state.is_loaded_student_summaries = true;
                state.is_error_loading_student_summaries = false;
                state.student_summaries = action.payload;
            })
            .addCase(getSummariesByStudent.rejected, (state, action) => {
                state.is_loading_student_summaries = false;
                state.is_error_loading_student_summaries = true;
                state.is_loaded_student_summaries = false;
                state.student_summaries_message = action.payload;
            })
            // .addCase(getTranslatedSummary.pending, state => {
            //     state.is_generating_summary = true;
            //     state.is_generated_summary = false;
            //     state.is_error_generating_summary = false;
            // })
            // .addCase(getTranslatedSummary.fulfilled, (state, action) => {
            //     state.is_generating_summary = false;
            //     state.is_generated_summary = true;
            //     state.is_error_generating_summary = false;
            //     state.output_summary = action.payload;
            // })
            // .addCase(getTranslatedSummary.rejected, (state, action) => {
            //     state.is_generating_summary = false;
            //     state.is_error_generating_summary = true;
            //     state.is_generated_summary = false;
            //     state.message_for_generating_summary = action.payload;
            // });
    }
});

export const {  resetSummaries,
                resetGenerateSummary,
                resetFetchSummary,
                resetSignSummary,
                resetUpdateSummary,
                resetGetPendingSummariesState,
                resetGetStudentSummariesState, } = summarySlice.actions;
export default summarySlice.reducer;