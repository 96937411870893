import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FooterTeacher from "./FooterTeacher";
import FooterAdmin from "../for_admins/FooterAdmin";
import { 
    UpdateReportForm1Yellow, UpdateReportForm2Pink, UpdateReportForm3Red,
    UpdateReportForm4PreBlue, UpdateReportForm5Blue, UpdateReportForm6Green,
    UpdateReportForm7HEEPWeek1, UpdateReportForm7HEEPWeek2, UpdateReportForm7HEEPWeek3,
    UpdateReportForm7HEEPWeek4, UpdateReportForm7HEEPWeek5, UpdateReportForm7HEEPWeek6,
    UpdateReportForm8HEWeek1, UpdateReportForm8HEWeek2, UpdateReportForm8HEWeek3,
    UpdateReportForm8HEWeek4, UpdateReportForm8HEWeek5, UpdateReportForm8HEWeek6,
    UpdateReportForm10HIWeek1, UpdateReportForm10HIWeek2, UpdateReportForm10HIWeek3,
    UpdateReportForm10HIWeek4, UpdateReportForm10HIWeek5, UpdateReportForm10HIWeek6,
    UpdateReportForm11ReadingWeek1, UpdateReportForm11ReadingWeek2,
    UpdateReportForm11ReadingWeek3, UpdateReportForm11ReadingWeek4,
  } from './report_components';
import { ReportContentData_1_Yellow, ReportContentData_1_Yellow_RequiredFields,
    ReportContentData_2_Pink, ReportContentData_2_Pink_RequiredFields,
    ReportContentData_3_Red, ReportContentData_3_Red_RequiredFields,
    ReportContentData_4_PreBlue, ReportContentData_4_PreBlue_RequiredFields,
    ReportContentData_5_Blue, ReportContentData_5_Blue_RequiredFields,
    ReportContentData_6_Green, ReportContentData_6_Green_RequiredFields,
    ReportContentData_7_HEEP, ReportContentData_7_HEEP_Week_1_RequiredFields, ReportContentData_7_HEEP_Week_2_RequiredFields,
    ReportContentData_7_HEEP_Week_3_RequiredFields, ReportContentData_7_HEEP_Week_4_RequiredFields,
    ReportContentData_7_HEEP_Week_5_RequiredFields, ReportContentData_7_HEEP_Week_6_RequiredFields,
    ReportContentData_8_HE, ReportContentData_8_HE_Week_1_RequiredFields, ReportContentData_8_HE_Week_2_RequiredFields,
    ReportContentData_8_HE_Week_3_RequiredFields, ReportContentData_8_HE_Week_4_RequiredFields,
    ReportContentData_8_HE_Week_5_RequiredFields, ReportContentData_8_HE_Week_6_RequiredFields,
    ReportContentData_10_HI, ReportContentData_10_HI_Week_1_RequiredFields, ReportContentData_10_HI_Week_2_RequiredFields,
    ReportContentData_10_HI_Week_3_RequiredFields, ReportContentData_10_HI_Week_4_RequiredFields,
    ReportContentData_10_HI_Week_5_RequiredFields, ReportContentData_10_HI_Week_6_RequiredFields,
    ReportContentData_11_Reading, ReportContentData_11_Reading_Week_1_RequiredFields, ReportContentData_11_Reading_Week_2_RequiredFields,
    ReportContentData_11_Reading_Week_3_RequiredFields, ReportContentData_11_Reading_Week_4_RequiredFields,
 } from "./report_components/ReportContentData";
import { can_access_page } from "../../app/user_utils";
import { FaPen, FaEye } from "react-icons/fa";
import Spinner from "../Spinner";
import RecordAndUploadVoiceComponent from "./RecordAndUploadVoiceComponent";
import UploadMediaComponent from "./UploadMediaComponent";
import { fetchReportById, updateReport, resetUpdateReportState, resetFetchReportState } from "../../features/reports/reportSlice";
import ImageViewer from "../ImageViewer";
import VideoViewer from "../VideoViewer";
import { PATH_NAME, WEEK_NUMBERS_JUNIOR, WEEK_NUMBERS_SENIOR, CLASS_LEVEL } from "../../app/constants";
import { UI_STRING_LOADING_ALL,
        UI_STRING_REPORT_ALL, } from "../../app/strings";
import { hasTestEveryCycle, isClassSenior } from "../../app/utils";
import { show_error_toast } from "../../app/ui_utils";

const UpdateReportPage = () => {
    const { report_id, class_level, week_number, edit_mode } = useParams();

    const this_report_id = report_id ? report_id : null;
    const this_class_level = class_level ? class_level : null;
    const this_week_number = week_number ? week_number : null;
    const is_in_edit_mode = edit_mode ? edit_mode : false;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.auth);

    const { fetched_report, fetch_status, is_updating_report, is_updated_report } = useSelector((state) => state.reports);

    // Access the cached `fetched_report` from location.state
    // Should only get this if we came here from:
    // Step 1 without changes; or
    // Generated a new ad hoc report
    const cached_report = location.state?.cached_report || null;

    const [ current_report, set_current_report ] = useState(null);

    const [ canRenderForm, setCanRenderForm ] = useState(false);
    const [ formElement, setFormElement ] = useState(null);

    const [ contentFieldErrors, setContentFieldErrors] = useState({}); // To store error states for each content field

    const enter_view_mode = useCallback(() => {

        // Remove /edit_mode from the URL
        navigate( PATH_NAME.Admin_Update_Report + `/${this_class_level}/${this_week_number}/${this_report_id}` );

    }, [this_class_level, this_week_number, this_report_id, navigate]);

    const week_numbers_array = useMemo(() => {

        return isClassSenior(this_class_level) ? WEEK_NUMBERS_SENIOR : WEEK_NUMBERS_JUNIOR;

    }, [ this_class_level]);

    const DEFAULT_REPORT_CONTENT = useMemo(() => {
        const reportContentMap = {
            [CLASS_LEVEL.Yellow]: {
                ...ReportContentData_1_Yellow,
                required_fields: ReportContentData_1_Yellow_RequiredFields,
            },
            [CLASS_LEVEL.Pink]: {
                ...ReportContentData_2_Pink,
                required_fields: ReportContentData_2_Pink_RequiredFields,
            },
            [CLASS_LEVEL.Red]: {
                ...ReportContentData_3_Red,
                required_fields: ReportContentData_3_Red_RequiredFields,
            },
            [CLASS_LEVEL.Pre_Blue]: {
                ...ReportContentData_4_PreBlue,
                required_fields: ReportContentData_4_PreBlue_RequiredFields,
            },
            [CLASS_LEVEL.Blue]: {
                ...ReportContentData_5_Blue,
                required_fields: ReportContentData_5_Blue_RequiredFields,
            },
            [CLASS_LEVEL.Green]: {
                ...ReportContentData_6_Green,
                required_fields: ReportContentData_6_Green_RequiredFields,
            },
            [CLASS_LEVEL.HEEP]: {
                ...ReportContentData_7_HEEP,
                required_fields: {
                    1: ReportContentData_7_HEEP_Week_1_RequiredFields,
                    2: ReportContentData_7_HEEP_Week_2_RequiredFields,
                    3: ReportContentData_7_HEEP_Week_3_RequiredFields,
                    4: ReportContentData_7_HEEP_Week_4_RequiredFields,
                    5: ReportContentData_7_HEEP_Week_5_RequiredFields,
                    6: ReportContentData_7_HEEP_Week_6_RequiredFields,
                }[this_week_number],
            },
            [CLASS_LEVEL.HE_L1]: {
                ...ReportContentData_8_HE,
                required_fields: {
                    1: ReportContentData_8_HE_Week_1_RequiredFields,
                    2: ReportContentData_8_HE_Week_2_RequiredFields,
                    3: ReportContentData_8_HE_Week_3_RequiredFields,
                    4: ReportContentData_8_HE_Week_4_RequiredFields,
                    5: ReportContentData_8_HE_Week_5_RequiredFields,
                    6: ReportContentData_8_HE_Week_6_RequiredFields,
                }[this_week_number],
            },
            [CLASS_LEVEL.HE_L2]: {
                ...ReportContentData_8_HE,
                required_fields: {
                    1: ReportContentData_8_HE_Week_1_RequiredFields,
                    2: ReportContentData_8_HE_Week_2_RequiredFields,
                    3: ReportContentData_8_HE_Week_3_RequiredFields,
                    4: ReportContentData_8_HE_Week_4_RequiredFields,
                    5: ReportContentData_8_HE_Week_5_RequiredFields,
                    6: ReportContentData_8_HE_Week_6_RequiredFields,
                }[this_week_number],
            },
            [CLASS_LEVEL.HI]: {
                ...ReportContentData_10_HI,
                required_fields: {
                    1: ReportContentData_10_HI_Week_1_RequiredFields,
                    2: ReportContentData_10_HI_Week_2_RequiredFields,
                    3: ReportContentData_10_HI_Week_3_RequiredFields,
                    4: ReportContentData_10_HI_Week_4_RequiredFields,
                    5: ReportContentData_10_HI_Week_5_RequiredFields,
                    6: ReportContentData_10_HI_Week_6_RequiredFields,
                }[this_week_number],
            },
            [CLASS_LEVEL.Reading]: {
                ...ReportContentData_11_Reading,
                required_fields: {
                    1: ReportContentData_11_Reading_Week_1_RequiredFields,
                    2: ReportContentData_11_Reading_Week_2_RequiredFields,
                    3: ReportContentData_11_Reading_Week_3_RequiredFields,
                    4: ReportContentData_11_Reading_Week_4_RequiredFields,
                }[this_week_number],
            },
        };
    
        return reportContentMap[this_class_level] || {
            ...ReportContentData_3_Red,
            required_fields: ReportContentData_3_Red_RequiredFields,
        }; // Default case
    }, [this_class_level, this_week_number]);

    const [formData, setFormData] = useState({
        week_number: this_week_number,//week_numbers_array[0].value,
        voice_message_urls: "",
        photo_urls: [],
        video_urls: [],
        content: { ...DEFAULT_REPORT_CONTENT },
        is_completed: false,
        // More fields to be added here
        // checkboxField1: false,
        // commentBoxField1: '',
    });

    const preventFormSubmitOnEnter = useCallback((event) => {
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("update_report_form"));
    }, []);

    useEffect(() => {
        if (this_report_id)
        {
            // No need to fetch if we have the cached report
            if (cached_report)
            {
                // console.log("UpdateReportPage.js >> useEffect >> Using cached report: ", cached_report);

                set_current_report( cached_report );

                setFormData({
                    // week_number: cached_report.week_number,
                    voice_message_urls: cached_report.voice_message_urls || "",
                    photo_urls: cached_report.photo_urls || [],
                    video_urls: cached_report.video_urls || [],
                    content: {
                        ...DEFAULT_REPORT_CONTENT, // Start with default values
                        ...cached_report.content // Override with fetched data
                    },
                    is_completed: cached_report.is_completed || false,
                });
    
                // Can safely render the form now
                setCanRenderForm(true);
            }
            else
            {
                // console.log("UpdateReportPage.js >> useEffect >> Fetching report by ID: ", this_report_id);
                dispatch( fetchReportById(this_report_id) );
            }
        }
        else
        {
            // console.log("No report ID provided. Redirecting...");

            navigate( PATH_NAME.Teacher_Dashboard );
        }

    }, [this_report_id, cached_report, DEFAULT_REPORT_CONTENT, dispatch, navigate]);

    useEffect(() => {
        if (fetch_status === "succeeded" && fetched_report)
        {
            // console.log("UpdateReportPage.js >> useEffect >> fetched_report: ", fetched_report);

            set_current_report( fetched_report );

            setFormData({
                // week_number: fetched_report.week_number,
                voice_message_urls: fetched_report.voice_message_urls || "",
                photo_urls: fetched_report.photo_urls || [],
                video_urls: fetched_report.video_urls || [],
                content: {
                    ...DEFAULT_REPORT_CONTENT, // Start with default values
                    ...fetched_report.content // Override with fetched data
                },
                is_completed: fetched_report.is_completed || false,
                // checkboxField1: report.checkboxField1 || false,
                // commentBoxField1: report.commentBoxField1 || '',
                // Set other fields based on the report data
            });

            // var element = document.getElementById("week_number");

            // // Manually update the selected value of the dropdown as well
            // if (element)
            // {
            //     element.value = fetched_report.week_number;
            // }

            // Can safely render the form now
            setCanRenderForm(true);
        }
    }, [fetch_status, fetched_report, DEFAULT_REPORT_CONTENT]);

    useEffect(() => {

        if (is_updated_report)
        {
            if (current_report && current_report.student)
            {
                navigate( PATH_NAME.Teacher_My_Student_Reports + "/" + current_report.student.student_id + "/" + current_report.student.student_name );
            }
            else
            {
                navigate( PATH_NAME.Teacher_Dashboard );
            }

            // return () => {
            dispatch( resetUpdateReportState() );
            dispatch( resetFetchReportState() );
            // }
        }
    }, [is_updated_report, current_report, dispatch, navigate]);

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;

    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: type === "checkbox" ? checked : value,
    //     }));
    // };

    const handleContentChange = (e) => {
        const { name, value, type, checked } = e.target;

        // console.log(`Field changed: ${name}, New value: ${type === "checkbox" ? checked : value}`);
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: {
                ...prevFormData.content,
                // If checkbox, check boolean. Otherwise, set value
                [name]: type === "checkbox" ? checked : value,
            }
        }));
    };

    const handleVoiceMessagesUploadComplete = (uploadedVoiceMessageUrls) => {

        if (!uploadedVoiceMessageUrls || uploadedVoiceMessageUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            voice_message_urls: uploadedVoiceMessageUrls,
        }));

        // console.log("UpdateReportForm RED >> handleVoiceMessageUploadComplete -- Voice Message URL: ", uploadedVoiceMessageUrl);
    };

    const handleImageUploadComplete = (uploadedPhotoUrls) => {

        if (!uploadedPhotoUrls || uploadedPhotoUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            photo_urls: uploadedPhotoUrls,
        }));
        // setImageUrls(uploadedUrls);
        // Save these URLs for report update

        // console.log("UpdateReportForm RED >> handleImageUploadComplete -- Image URLs: ", uploadedPhotoUrls);
    };

    const handleVideoUploadComplete = (uploadedVideoUrls) => {

        if (!uploadedVideoUrls || uploadedVideoUrls.length === 0)
        {
            return;
        }

        if (this_report_id === null)
        {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            video_urls: uploadedVideoUrls,
        }));

        // console.log("handleVideoUploadComplete -- Video URLs: ", uploadedVideoUrls);
    };


    const handleSubmit = (e) => {

        e.preventDefault();

        // Centre requirement checks
        if ((parseInt(this_week_number) === week_numbers_array.length)
            && (!formData.voice_message_urls || formData.voice_message_urls.length === 0))
        {
            // console.log("UpdateReportPage.js >> handleSubmit :: need voice messages, week_number: ", formData.week_number);

            show_error_toast(UI_STRING_REPORT_ALL.Toast_Error_Missing_Voice_Message);

            return;
        }

        // More centre requirement checks
        if ( hasTestEveryCycle(this_class_level)
            && (parseInt(this_week_number) === week_numbers_array.length)
            && (!formData.photo_urls || formData.photo_urls.length === 0))
        {
            // console.log("UpdateReportPage.js >> handleSubmit :: need quiz photos, week_number: ", formData.week_number);

            show_error_toast(UI_STRING_REPORT_ALL.Toast_Error_Missing_Quiz_Photos);

            return;
        }

        // New way to handle empty inputs
        let newErrors = {}; // Initialize an object to store errors

        const requiredFields = DEFAULT_REPORT_CONTENT.required_fields;

        requiredFields.forEach((field) => {
            const fieldValue = formData.content[field.name] || "";
            if (!field.is_optional && !fieldValue)
            {
              newErrors[field.name] = true;
            }
          });   
        

        // if ( formData.content.weekly_comment === "" )
        // {
        //     show_error_toast(UI_STRING_REPORT_ALL.Toast_Error_Missing_Comment);

        //     return;
        // }

        // if ( this_class_level === CLASS_LEVEL.HEEP && formData.content.key_topic === "" )
        // {
        //     show_error_toast(UI_STRING_REPORT_ALL.Toast_Error_Missing_Key_Topic);

        //     return;
        // }
        
        if (Object.keys(newErrors).length === 0)
        {
            const updated_report = { ...current_report,
                                    ...formData,
                                    is_completed: true,
                                };

            // console.log("UpdateReportPage.js >> handleSubmit :: updated_report = ", updated_report);
            
            dispatch( updateReport(updated_report) );
        }
        else
        {
            // console.log("UpdateReportPage.js >> handleSubmit :: newErrors = ", newErrors);

            setContentFieldErrors(newErrors);

            show_error_toast(UI_STRING_REPORT_ALL.Toast_Error_Missing_Required_Fields);
        }
    };

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (user.role === "admin" || user.role === "manager")
        {
            // console.log("User is admin or manager. Entering view mode...");

            enter_view_mode();
        }
        else if (can_access_page(user, PATH_NAME.Teacher_Update_Report) === false)
        {
            // console.log("User has no permission. Redirecting to dashboard...");

            navigate( PATH_NAME.Dashboard );
        }
    }, [user, enter_view_mode, navigate]);

    const return_to_student_page = (event) => {

        event.preventDefault();

        if (user && (user.role === "admin" || user.role === "manager"))
        {
            navigate(PATH_NAME.Admin_Student_Reports + "/" + current_report.student.student_id + "/" + current_report.student.student_name);
        }
        else if (user && user.role === "teacher")
        {
            navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + current_report.student.student_id + "/" + current_report.student.student_name);
        }

        // console.log("UpdateReportPage.js >> return_to_student_page :: Returning...");
        dispatch( resetUpdateReportState() );
        dispatch( resetFetchReportState() );
    };

    const return_to_report_details = (event) => {

        event.preventDefault();

        navigate(PATH_NAME.Teacher_Update_Report_Details + "/" + this_class_level + "/" + this_report_id + PATH_NAME.Edit_Mode);

        // console.log("UpdateReportPage.js >> return_to_report_details :: Returning...");
        dispatch( resetUpdateReportState() );
        dispatch( resetFetchReportState() );
    };

    if (is_updating_report)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Saving_Report } />;
    }

    if (!canRenderForm || !current_report || fetch_status === "loading")
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Report } />;
    }

    return (

        // (!current_report || fetch_status === "loading") ? <Spinner /> :
        // (
        !canRenderForm ? (<></>) :
        (
        <>
        <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
            <div className="login_container">

                <section className="headless_title mb-12">

                    {is_in_edit_mode && (
                    <div className="flex items-center justify-center text-gray-300 font-bold text-2xl">
                        {UI_STRING_REPORT_ALL.Step_2}
                    </div>
                    )
                    }

                    <h1 className="flex items-center justify-center">

                        {
                            edit_mode === "edit_mode" ?
                            (
                                <>
                                <FaPen className="mr-8"/> {UI_STRING_REPORT_ALL.Update_Report}
                                </>
                            ) :
                            (
                                <>
                                <FaEye className="mr-8"/> {UI_STRING_REPORT_ALL.View_Report}
                                </>
                            )
                        }
                    </h1>
                
                </section>

                <section className="headless_title mb-12">
                    <p className="my-6 text-hots-purple font-extrabold text-4xl">
                        {current_report && current_report.student && current_report.student.student_name}
                    </p>
                    <p className="my-6 text-gray-500 font-bold text-3xl">
                        Preferred Name: {current_report && current_report.student && current_report.student.preferred_name}
                    </p>
                    <p className="my-6 text-white font-bold text-2xl">
                        ({ current_report && current_report.student && current_report.student.student_id })
                    </p>
                </section>
                
                <section className="form">
                    <form id="update_report_form"
                            onKeyDown={preventFormSubmitOnEnter}
                    >

                        <hr className="mt-8 mb-8 regular"></hr>

                        {
                        !is_in_edit_mode && current_report.is_voided && (
                            <>
                            <div className="form_group">

                                <label className="text-gray-700 font-bold text-2xl">{UI_STRING_REPORT_ALL.Report_Is_Voided}</label>

                                <label className="text-gray-300 font-medium text-2xl">
                                    ({(current_report && current_report.void_reason) || UI_STRING_REPORT_ALL.No_Void_Reason })
                                </label>

                            </div>

                            <hr className="mt-8 mb-8 thin"></hr>
                            </>
                        )}

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.The_Teacher}</label>

                            <label className="text-gray-300 font-medium text-xl">
                                {current_report && current_report.by_teacher && current_report.by_teacher.display_name}
                            </label>
                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Report_Status}</label>

                            {current_report.is_completed ?
                            (
                                <label className="text-gray-300 font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Completed}
                                </label>
                            ) : (
                                <label className="text-hots-red font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Incomplete}
                                </label>
                            )}
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>
                        
                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Class_Level}</label>

                            <label className="text-gray-300 font-medium text-xl uppercase">
                                {current_report && current_report.class_level}
                            </label>

                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Week_Number}</label>
                            
                            <label className="text-gray-300 font-medium text-xl uppercase">
                                {UI_STRING_REPORT_ALL.The_Week + " " + (current_report && current_report.week_number)}
                            </label>
                            
                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Class_Date}</label>
                            
                            <label className="text-gray-300 font-medium text-xl uppercase">
                                {current_report && current_report.date_string}
                            </label>
                            
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>
                        
                        {/* Class level specific form fields */}
                        {
                            this_report_id && this_class_level && (
                                (() => {
                                    const formMap = {
                                        "Yellow": UpdateReportForm1Yellow,
                                        "Pink": UpdateReportForm2Pink,
                                        "Red": UpdateReportForm3Red,
                                        "Pre-Blue": UpdateReportForm4PreBlue,
                                        "Blue": UpdateReportForm5Blue,
                                        "Green": UpdateReportForm6Green,
                                        "HEEP": {
                                            "1": UpdateReportForm7HEEPWeek1,
                                            "2": UpdateReportForm7HEEPWeek2,
                                            "3": UpdateReportForm7HEEPWeek3,
                                            "4": UpdateReportForm7HEEPWeek4,
                                            "5": UpdateReportForm7HEEPWeek5,
                                            "6": UpdateReportForm7HEEPWeek6,
                                        },
                                        "HE L1": {
                                            "1": UpdateReportForm8HEWeek1,
                                            "2": UpdateReportForm8HEWeek2,
                                            "3": UpdateReportForm8HEWeek3,
                                            "4": UpdateReportForm8HEWeek4,
                                            "5": UpdateReportForm8HEWeek5,
                                            "6": UpdateReportForm8HEWeek6,
                                        },
                                        "HE L2": {
                                            "1": UpdateReportForm8HEWeek1,
                                            "2": UpdateReportForm8HEWeek2,
                                            "3": UpdateReportForm8HEWeek3,
                                            "4": UpdateReportForm8HEWeek4,
                                            "5": UpdateReportForm8HEWeek5,
                                            "6": UpdateReportForm8HEWeek6,
                                        },
                                        "HI": {
                                            "1": UpdateReportForm10HIWeek1,
                                            "2": UpdateReportForm10HIWeek2,
                                            "3": UpdateReportForm10HIWeek3,
                                            "4": UpdateReportForm10HIWeek4,
                                            "5": UpdateReportForm10HIWeek5,
                                            "6": UpdateReportForm10HIWeek6,
                                        },
                                        "Reading": {
                                            "1": UpdateReportForm11ReadingWeek1,
                                            "2": UpdateReportForm11ReadingWeek2,
                                            "3": UpdateReportForm11ReadingWeek3,
                                            "4": UpdateReportForm11ReadingWeek4,
                                        },
                                    };
                        
                                    const FormComponent = 
                                        typeof formMap[this_class_level] === "object" && formMap[this_class_level][this_week_number] 
                                        ? formMap[this_class_level][this_week_number] 
                                        : formMap[this_class_level] || UpdateReportForm3Red; // Default case
                        
                                    return (
                                        <FormComponent 
                                            formData={formData} 
                                            handleContentChange={handleContentChange} 
                                            is_in_edit_mode={is_in_edit_mode} 
                                            errors={contentFieldErrors}
                                        />
                                    );
                                })()
                            )
                        }

                        <hr className="mt-8 mb-8 regular"></hr>
                        
                        {/* Only show recorder if there are no photos already */}
                        {(formData.voice_message_urls?.length === 0) && (
                        <>
                        <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Voice_Messages_Record_Prompt}
                            </label>
                            
                            { is_in_edit_mode &&
                            (
                            <RecordAndUploadVoiceComponent
                                // inputAudioURL={formData.voice_message_urls}
                                onUploadComplete={ handleVoiceMessagesUploadComplete } />
                            )}

                        </div>
                        </>
                        )}

                        {/* Otherwise, display the voice messages */}
                        {(formData.voice_message_urls && formData.voice_message_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Uploaded_Voice_Messages}
                            </label>
                            
                            {formData.voice_message_urls.map((this_vm_url, index) => (

                                <div key={index} className="my-12">
                                    
                                    <div className="flex items-center space-x-4"> {/* Flexbox to align side by side with spacing */}

                                    <audio src={this_vm_url} controls />

                                    {/* <span className="text-lg font-medium text-gray-600">Uploaded</span> */}

                                    </div>
                                </div>
                            ))}

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {/* Only show uploader if there are no photos already */}
                        {(formData.photo_urls?.length === 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Photo_Upload_Prompt}
                            </label>
                            
                            { is_in_edit_mode &&
                            (
                            <UploadMediaComponent
                                fileType={"image"}
                                onUploadComplete={ handleImageUploadComplete }
                            />
                            )}

                            </div>
                        </>
                        )}

                        {/* Otherwise, display the images */}
                        {(formData.photo_urls && formData.photo_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Uploaded_Photos}</label>
                            
                            <ImageViewer photo_urls={formData.photo_urls} />

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {/* Only show uploader if there are no videos already */}
                        {(formData.video_urls?.length === 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">
                                {UI_STRING_REPORT_ALL.Video_Upload_Prompt}
                            </label>

                            { is_in_edit_mode &&
                            (
                            <UploadMediaComponent
                                fileType={"video"}
                                onUploadComplete={ handleVideoUploadComplete }
                            />
                            )}

                            </div>
                        </>
                        )}

                        {/* Otherwise, display the video */}
                        {(formData.video_urls && formData.video_urls.length > 0) && (
                        <>
                            <div className="form_group">
                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Uploaded_Videos}</label>

                            <VideoViewer videoUrls={formData.video_urls} />

                            </div>
                        </>
                        )}

                        <hr className="mt-8 mb-8 regular"></hr>

                        {
                        is_in_edit_mode && (
                            <>
                            <div className="form_group flex flex-col justify-between mt-16 mb-16">

                            <div className="flex justify-center">
                                <button className="btn w-full" onClick={handleSubmit}>
                                {UI_STRING_REPORT_ALL.Button_Save_Report}
                                </button>
                            </div>

                            <div className="flex justify-center mt-16">
                                <button className="btn btn_gray w-full" onClick={return_to_report_details}>
                                {UI_STRING_REPORT_ALL.Button_Previous_Step}
                                </button>
                            </div>

                            <div className="text-gray-600 font-medium text-center text-xl mt-2">{UI_STRING_REPORT_ALL.Previous_Note}</div>

                            <div className="flex justify-center mt-12">
                                <button className="btn btn_gray w-full" onClick={return_to_student_page}>
                                {UI_STRING_REPORT_ALL.Button_Cancel}
                                </button>
                            </div>

                            <div className="text-gray-600 font-medium text-center text-xl mt-2">{UI_STRING_REPORT_ALL.Cancel_Note}</div>

                            </div>
                            </>
                        )}

                        {
                        !is_in_edit_mode && (
                            <>
                            <div className="form_group flex justify-center mt-12">
                                <button className="btn btn_gray w-full" onClick={return_to_student_page}>
                                    {UI_STRING_REPORT_ALL.Button_Return}
                                </button>
                            </div>
                            </>
                        )}
                    </form>
                </section>

            </div>

        {
            user && (user.role === "admin" || user.role === "manager") &&
            <FooterAdmin />
        }

        {
            user && user.role === "teacher" &&
            <FooterTeacher />
        }
        </div>
        </>
        )
    )
    // );
};

export default UpdateReportPage;