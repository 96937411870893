import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        COMMENT_AREA_ROWS_3,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_11_READING,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm11ReadingWeek2 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>
    
    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_1}`}
        name="_2_1_"
        value={formData.content._2_1_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_2}`}
        name="_2_2_"
        value={formData.content._2_2_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_3}`}
        name="_2_3_"
        value={formData.content._2_3_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_4}`}
        name="_2_4_"
        value={formData.content._2_4_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_5}`}
        name="_2_5_"
        value={formData.content._2_5_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_6}`}
        name="_2_6_"
        value={formData.content._2_6_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_7}`}
        name="_2_7_"
        value={formData.content._2_7_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_2_Metric_8}`}
        name="_2_8_"
        value={formData.content._2_8_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_8_"]}
    />
    
    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />
    
    </div>
  );
};

export default UpdateReportForm11ReadingWeek2;