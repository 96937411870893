import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { FaSignOutAlt, FaHome, FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import TabButtonStudentsForTeacher from "./TabButtonStudentsForTeacher";
import TabButtonTodaySchedule from "../TabButtonTodaySchedule";
import TabButtonCalendar from "../TabButtonCalendar";
import { run_logout } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const HeaderTeacher = () => {

    const { user } = useSelector((state) => state.auth);

    const { incomplete_reports } = useSelector((state) => state.reports);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handle_logout = useCallback(() => {

        run_logout(dispatch, navigate);

    }, [dispatch, navigate]);

    return (
        <header className="bg-basic">
            <div className="container mx-auto p-4">
                <div className="header_top flex justify-between items-center mt-4 mb-2">
                    <div className="header_top_left flex space-x-4">
                        <Link to={ PATH_NAME.Teacher_Dashboard } className="header_logo_txt flex items-center">
                            <FaHome className="mr-1" /> HOTS System
                        </Link>
                        <Link to={ PATH_NAME.Teacher_Todos } className="flex items-center">
                            <div className="relative inline-block">
                                <span className="header_alert_icon">
                                    <FaBell className="mr-1 text-gray-500 hover:text-gray-700" size={24} />
                                </span>
                                {
                                    incomplete_reports && incomplete_reports.length > 0 &&
                                        (<span className="header_alert_txt">{incomplete_reports && incomplete_reports.length}
                                        </span>
                                        )
                                }
                            </div>
                        </Link>
                    </div>
                    <div className="header_top_right">
                        <button onClick={handle_logout}>
                            <span className="flex items-center">
                                <strong className="flex items-center"><FaSignOutAlt className="mr-1" /> Logout</strong>
                                <span className="flex items-center">&nbsp; ({ user?.username })</span>
                            </span>
                        </button>
                    </div>
                </div>

                {/* <div className="header_bottom grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-2 p-4"> */}
                {/* <div className="header_bottom flex justify-center items-center p-4"> */}
                <div className="header_bottom flex flex-wrap justify-between gap-2 p-3">
                    
                    <Link to={ PATH_NAME.Teacher_Dashboard }>
                        <TabButtonTodaySchedule />
                    </Link>

                    <Link to={ PATH_NAME.Day_Search }>
                        <TabButtonCalendar />
                    </Link>

                    <Link to={ PATH_NAME.Teacher_My_Students }>
                        <TabButtonStudentsForTeacher />
                    </Link>
                </div>
            </div>
        </header>
    );

}

export default HeaderTeacher;