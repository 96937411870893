import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { addLesson, resetAddLesson } from "../../../features/lessons/lessonSlice";
import { PATH_NAME } from "../../../app/constants";
import { can_access_page } from "../../../app/user_utils";
import Spinner from "../../Spinner";
import { UI_STRING_LOADING_ALL } from "../../../app/strings";

 
const CloneLesson = () => {
    const { lesson_id } = useParams();

    const this_lesson_id = lesson_id ? lesson_id : null;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.auth);
    
    const { new_lesson, is_adding_lesson, is_added_lesson } = useSelector(state => state.lessons);

    const lesson_data = location.state;//?.lesson_data || null;
    
    // Redirect if not logged in or does not have access
    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Clone_Lesson) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    useEffect(() => {

        // if (this_lesson_id)
        // {
        //     console.log("CloneLesson.js >> clone_lesson :: this_lesson_id: ", this_lesson_id);

        //     console.log("LessonAccordion.js >> clone_lesson :: lesson_data: ", lesson_data);
        // }

        if (this_lesson_id && lesson_data)
        {
            // const lesson_data = {
            //     class_level: curr_lesson.class_level,
            //     date_string: curr_lesson.date_string,
            //     time_of_day: curr_lesson.time_of_day,
            //     end_time: curr_lesson.end_time,
            //     center: curr_lesson.center,
            //     room: curr_lesson.room,
            //     teacher: curr_lesson.teacher,
            //     students: curr_lesson.students };
    
            // console.log("LessonAccordion.js >> clone_lesson :: lesson_data: ", lesson_data);
    
            dispatch( addLesson(lesson_data) );
        }
        else
        {
            // console.log("No lesson ID provided. Redirecting...");

            navigate( PATH_NAME.Dashboard );
        }

    }, [this_lesson_id, lesson_data, dispatch, navigate]);

    useEffect(() => {
        if (is_added_lesson && new_lesson)
        {
            // Go to the edit page
            navigate( PATH_NAME.Admin_Update_Lesson + "/" + new_lesson._id );

            dispatch( resetAddLesson() );
        }

    }, [is_added_lesson, new_lesson, dispatch, navigate]);

    if (!new_lesson || is_adding_lesson)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Cloning_Lesson } />;
    }
    
    return (
        <></>
    )
};

export default CloneLesson;