import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderTeacher from "./HeaderTeacher";
import FooterTeacher from "./FooterTeacher";
import MyStudentReportsTable from "./MyStudentReportsTable";
import { can_access_page } from "../../app/user_utils";
import { PATH_NAME } from "../../app/constants";

const MyStudentReportsPage = () => {

    const { student_id, student_name } = useParams();

    const this_student_id = student_id ? student_id : null;
    const this_student_name = student_name ? student_name : null;

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login);
        }

        if (can_access_page(user, PATH_NAME.Teacher_My_Student_Reports) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    return (
        <>
            <div className="basic_bg font-sans">
                
                <HeaderTeacher />

                <div className="dashboard_container">

                    <section className="heading">
                        <h1>Student Reports</h1>
                    </section>

                    {/* Top Line */}
                    <hr className="my-6 border-white border-t-8 regular"></hr>

                    <p className="my-6 text-white font-bold text-2xl">
                        ({this_student_id})
                    </p>
                    
                    <p className="my-6 text-gray-600 font-extrabold text-4xl">
                        {this_student_name}
                    </p>

                    {/* Bottom Line */}
                    <hr className="my-10 border-white border-t-8 regular"></hr>

                    <MyStudentReportsTable student_id={this_student_id} student_name={this_student_name}/>
                </div>

                <FooterTeacher />
            </div>
        </>
    );
}

export default MyStudentReportsPage;