import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import DashboardAdmin from "./components/for_admins/DashboardAdmin";
import NotificationPage from "./components/for_admins/NotificationPage";
import DaySearchPage from "./components/DaySearchPage";
import DayPage from "./components/DayPage";
import StudentSearchPage from "./components/for_admins/students/StudentSearchPage";
import AddStudent from "./components/for_admins/students/AddStudent";
import StudentReportsPage from "./components/for_admins/students/StudentReportsPage";
import DashboardTeacher from "./components/for_teachers/DashboardTeacher";
import ToDoPage from "./components/for_teachers/ToDoPage";
import MyStudentsPage from "./components/for_teachers/MyStudentsPage";
import MyStudentReportsPage from "./components/for_teachers/MyStudentReportsPage";
import SchoolSchedulePage from "./components/for_admins/recurring_classes/SchoolSchedulePage";
import AddRecurringClass from "./components/for_admins/recurring_classes/AddRecurringClass";
import UpdateRecurringClass from "./components/for_admins/recurring_classes/UpdateRecurringClass";
import AddLesson from "./components/for_admins/timetable/AddLesson";
import CloneLesson from "./components/for_admins/timetable/CloneLesson";
import UpdateLesson from "./components/for_admins/timetable/UpdateLesson";
import AddReport from "./components/for_teachers/AddReport";
import UpdateReportDetailsPage from "./components/for_teachers/UpdateReportDetailsPage";
import UpdateReportPage from "./components/for_teachers/UpdateReportPage";
import ParentSearchPage from "./components/for_admins/parents/ParentSearchPage";
import StudentSummariesPage from "./components/for_admins/parents/StudentSummariesPage";
// import UpdateSummaryPage from "./components/for_admins/parents/UpdateSummaryPage";
import ViewSummaryPage from "./components/for_admins/parents/ViewSummaryPage";

// import { getSettings, resetLoadSettings } from "./features/settings/settingSlice";
import { checkToken } from "./features/auth/authSlice";
import { getPendingReportsByTeacher } from "./features/reports/reportSlice";
// import { getPendingReportsByTeacher, getAllPendingReports } from "./features/reports/reportSlice";
// import { getAllPendingSummaries } from "./features/summaries/summarySlice";
import { getOpenAdminAlerts } from "./features/adminAlerts/adminAlertSlice";

import { run_logout } from "./app/user_utils";
import { is_summary_page } from "./app/utils"
import { PATH_NAME, REFRESH_ADMIN_ALERTS_MS, REFRESH_TEACHER_ALERTS_MS } from "./app/constants";

function App() {

  // const { settings, is_fetching_settings } = useSelector(state => state.settings);

  const { user,
          is_logging_in,
          is_token_verified,
          is_token_failed,
          message_for_verifying_token } = useSelector(state => state.auth);

  const {
          // pending_reports,
          incomplete_reports, } = useSelector(state => state.reports);

  // const { pending_summaries } = useSelector(state => state.summaries);

  const { admin_alerts } = useSelector(state => state.admin_alerts);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route location
  
  // const [is_viewing_summary, set_is_viewing_summary] = useState(false);

  // useEffect(() => {

  //   // console.log("App.js >> useEffect >> dispatching getSettings()...");

  //   dispatch( getSettings() );

  // }, [dispatch]);

  // useEffect(() => {

  //   if (settings && settings.length > 0)
  //   {
  //     // console.log(settings);

  //     // console.log("App.js >> useEffect >> dispatching resetLoadSettings()...");

  //     dispatch( resetLoadSettings() );
  //   }

  // }, [settings, dispatch]);

  useEffect(() => {

    // OK to view summary without logging in
    const is_on_summary_page = is_summary_page( location.pathname );

    if (user)
    {
      dispatch( checkToken(user.token) );
    }
    else if (!user
            && !is_logging_in
            && location.pathname !== PATH_NAME.Login
            && is_on_summary_page === false)
    {
      // console.log("User is not logged in. Go to login page... from App.js, location.pathname: ", location.pathname);
      navigate( PATH_NAME.Login );
    }

  }, [user, is_logging_in, location.pathname, dispatch, navigate]);

  const handle_logout = useCallback(() => {

    run_logout(dispatch, navigate); // Call the utility function with dispatch and navigate

  }, [dispatch, navigate]);

  // Logout user if JWT expired
  useEffect(() => {

    if (user)
    {
      if (is_token_failed && message_for_verifying_token === "jwt expired")
      {
          // console.log("JWT expired. Logging out...");
          handle_logout();
      }
    }
  }, [user, is_token_failed, message_for_verifying_token, dispatch, navigate, handle_logout]);

  // Read role-specific data if token is verified
  useEffect(() => {

      if (is_token_verified && user)
      {
        if (user.role === "admin")
        {
            // Dispatch once when component mounts
            // dispatch( getAllPendingReports() );
            // dispatch( getAllPendingSummaries() );
            dispatch ( getOpenAdminAlerts() );

            // Poll regularly for new alerts
            const interval_id = setInterval(() => {

              // console.log("App.js >> useEffect >> setInterval >> dispatching getOpenAdminAlerts()...");
              
              dispatch( getOpenAdminAlerts() );

            }, REFRESH_ADMIN_ALERTS_MS); // 10-minute interval
            // }, 20000); // Test - 20 seconds

            // Clean up the interval on component unmount
            return () => clearInterval(interval_id);
        }
        else if (user.role === "teacher")
        {
            // Dispatch once on component mount
            dispatch( getPendingReportsByTeacher( user.username ) );

            // Poll regularly for new report alerts
            const longer_interval_id = setInterval(() => {

              dispatch( getPendingReportsByTeacher( user.username ) );

            }, REFRESH_TEACHER_ALERTS_MS); // 1-hour interval

            // Clean up the interval on component unmount
            return () => clearInterval(longer_interval_id);
        }
      }

    }, [
        navigate, dispatch,
        user, is_token_verified,
  ]);

  // Update Browser Title - Admin
  useEffect(() => {

      if (user && is_summary_page( location.pathname ) === false)
      {
        if (user.role === "admin")
        {
          let sum = 0;

          if ( admin_alerts && admin_alerts.length > 0 )
          {
            sum = admin_alerts.length;
          }
          // if (pending_reports && pending_reports.length > 0)
          // {
          //   sum += pending_reports.length;
          // }

          // if (pending_summaries && pending_summaries.length > 0)
          // {
          //   sum += pending_summaries.length;
          // }

          if (sum > 0)
          {
              document.title = "(" + (sum) + ") HOTS System";
          }
          else
          {
              document.title = "HOTS System";
          }
        }
      }

    }, [user,
        admin_alerts,
        // pending_reports,
        // pending_summaries,
        location.pathname,
  ]);

  // Update Browser Title - Teacher
  useEffect(() => {

      if (user)
      {
        if (user.role === "teacher")
        {
          if (incomplete_reports && incomplete_reports.length > 0)
          {
              document.title = "(" + (incomplete_reports.length) + ") HOTS System";
          }
          else
          {
              document.title = "HOTS System";
          }
        }
      }
    }, [user,
        incomplete_reports,
  ]);

  // if (is_fetching_settings)
  // {
  //   return <Spinner />; // Handle loading state
  // }

  return (
    <>    
    <Routes>
      <Route path={ PATH_NAME.Dashboard } element={ <Dashboard /> } />  
      <Route path={ PATH_NAME.Login } element={ <Login /> } />

      {/* Shared (among centre roles) Routes */}
      <Route path={ PATH_NAME.Day_Search } element={ <DaySearchPage /> } />
      <Route path={ PATH_NAME.Day_Lessons + "/:the_date" } element={ <DayPage /> } />

      {/* Admin Routes */}
      <Route path={ PATH_NAME.Admin_Dashboard } element={ <DashboardAdmin /> } />
      <Route path={ PATH_NAME.Admin_Notifications } element={ <NotificationPage /> } />
      <Route path={ PATH_NAME.Admin_School_Schedule } element={ <SchoolSchedulePage /> } />
      <Route path={ PATH_NAME.Admin_Add_Recurring_Class } element={ <AddRecurringClass /> } />
      <Route path={ PATH_NAME.Admin_Update_Recurring_Class + "/:class_id" } element={ <UpdateRecurringClass /> } />
      <Route path={ PATH_NAME.Admin_Add_Lesson } element={ <AddLesson /> } />
      <Route path={ PATH_NAME.Admin_Clone_Lesson + "/:lesson_id" } element={ <CloneLesson /> } />
      <Route path={ PATH_NAME.Admin_Update_Lesson + "/:lesson_id" } element={ <UpdateLesson /> } />
      <Route path={ PATH_NAME.Admin_Student_List } element={ <StudentSearchPage /> } />
      <Route path={ PATH_NAME.Admin_Add_Student } element={ <AddStudent /> } />
      <Route path={ PATH_NAME.Admin_Student_Reports + "/:student_id/:student_name" } element={ <StudentReportsPage /> } />
      <Route path={ PATH_NAME.Admin_Parent_List } element={ <ParentSearchPage /> } />
      <Route path={ PATH_NAME.Admin_Student_Summaries + "/:student_id/:student_name" } element={ <StudentSummariesPage /> } />
      <Route path={ PATH_NAME.Admin_Update_Report + "/:class_level/:week_number/:report_id" } element={ <UpdateReportPage /> } />
      {/* Same Component as Parent's summary page, with /edit_mode added to the end of the path */}
      <Route path={ PATH_NAME.Admin_Update_Summary + "/:shareable_id/:edit_mode" } element={ <ViewSummaryPage /> } />

      {/* Teacher Routes */}
      <Route path={ PATH_NAME.Teacher_Dashboard } element={ <DashboardTeacher /> } />
      <Route path={ PATH_NAME.Teacher_Todos } element={ <ToDoPage /> } />
      <Route path={ PATH_NAME.Teacher_My_Students } element={ <MyStudentsPage /> } />
      <Route path={ PATH_NAME.Teacher_My_Student_Reports + "/:student_id/:student_name" } element={ <MyStudentReportsPage /> } />
      <Route path={ PATH_NAME.Teacher_Add_Report + "/:student_id/:student_name" } element={ <AddReport /> } />
      <Route path={ PATH_NAME.Teacher_Update_Report_Details + "/:class_level/:report_id/:edit_mode" } element={ <UpdateReportDetailsPage /> } />
      {/* Same Component as Teacher's report page, with /edit_mode added to the end of the path */}
      <Route path={ PATH_NAME.Teacher_Update_Report + "/:class_level/:week_number/:report_id/:edit_mode" } element={ <UpdateReportPage /> } />
      
      {/* Shared Routes */}
      <Route path={ PATH_NAME.Parent_View_Summary + "/:shareable_id" } element={ <ViewSummaryPage /> } />
      
    </Routes>

    <ToastContainer />
    
    </>
  );
}

export default App;