import { useEffect, useState, useCallback } from "react";
import { FaPen } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchLessonById, resetFetchLesson,
            updateLesson, resetUpdateLesson,
            trashLesson, resetTrashLesson
 } from "../../../features/lessons/lessonSlice";
import { getTeachersByName, resetTeachers } from "../../../features/teachers/teacherSlice";
import Spinner from "../../Spinner";
import FooterAdmin from "../FooterAdmin";
import StudentSearchComponent from "../../StudentSearchComponent";
import { PATH_NAME, CLASS_LEVELS,
            TIME_SLOTS, ROOMS } from "../../../app/constants";
import { UI_STRING_ADD_LESSON_ADMIN, UI_STRING_LOADING_ALL } from "../../../app/strings";
import { getDate_HKT, DATE_FORMAT_DDMMYYYY } from "../../../app/date_utils";
import { can_access_page } from "../../../app/user_utils";
import { show_error_toast } from "../../../app/ui_utils";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_orange.css";  // Import a theme of your choice

const UpdateLesson = () => {
    const { lesson_id } = useParams();

    const this_lesson_id = lesson_id ? lesson_id : null;

    const [form_data, set_form_data] = useState({
        date_string: "",
        class_level: "",
        time_of_day: "",
        end_time: "",
        room: "",
        center: "",
        selected_teacher: null,
        selected_students: [],
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    
    const { fetched_lesson, is_fetching_lesson, is_fetched_lesson,
            is_updated_lesson, is_updating_lesson,
            is_trashed_lesson, is_trashing_lesson,
    } = useSelector(state => state.lessons);

    const { teachers, is_success_reading_teachers } = useSelector(state => state.teachers);

    const [ formElement, setFormElement ] = useState(null);

    const [ canRenderForm, setCanRenderForm ] = useState(false);

    const [search_teacher_query, set_search_teacher_query] = useState("");
    const [teacher_results, set_teacher_results] = useState([]);
    
    // Redirect if not logged in or does not have access
    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Update_Lesson) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    useEffect(() => {
        if (this_lesson_id)
        {
            dispatch( fetchLessonById(this_lesson_id) );
        }
        else
        {
            // console.log("No lesson ID provided. Redirecting...");

            navigate( PATH_NAME.Dashboard );
        }

    }, [this_lesson_id, dispatch, navigate]);

    useEffect(() => {
        if (is_fetched_lesson && fetched_lesson)
        {
            set_form_data({
                selected_teacher: fetched_lesson.teacher,
                selected_students: fetched_lesson.students,
                class_level: fetched_lesson.class_level,
                date_string: fetched_lesson.date_string,
                time_of_day: fetched_lesson.time_of_day,
                end_time: fetched_lesson.end_time,
                room: fetched_lesson.room,
                center: fetched_lesson.center,
                is_trashed: fetched_lesson.is_trashed || false,
            });

            // console.log("UpdateLesson.js >> useEffect >> fetched_lesson: ", fetched_lesson);

            // If this lesson has already been trashed, redirect to dashboard
            if (fetched_lesson.is_trashed)
            {
                navigate( PATH_NAME.Admin_Dashboard );

                dispatch( resetFetchLesson() );

                return;
            }

            // Can safely render the form now
            setCanRenderForm(true);
        }
    }, [fetched_lesson, is_fetched_lesson, navigate, dispatch]);

    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_lesson_form"));
    }, []);

    useEffect(() => {
        if (is_updated_lesson)
        {
            navigate( PATH_NAME.Admin_Dashboard );

            dispatch( resetUpdateLesson() );
            dispatch( resetFetchLesson() );
        }

    }, [is_updated_lesson, navigate, dispatch]);

    useEffect(() => {
        if (is_trashed_lesson)
        {
            navigate( PATH_NAME.Admin_Dashboard );

            dispatch( resetTrashLesson() );
            dispatch( resetFetchLesson() );
        }

    }, [is_trashed_lesson, navigate, dispatch]);

    useEffect(() => {
        
        if (is_success_reading_teachers && teachers)
        {
            set_teacher_results(teachers);
        }

        // This represents the unmount function,
        // in which it's a good idea to reset to prevent memory leaks.
        return () => {
            dispatch(resetTeachers());
        };

    }, [teachers, is_success_reading_teachers, dispatch]);

    const handle_class_level_change = (event) => {

        // const selected_level = event.target.value;
        const selected_level = CLASS_LEVELS.find(level => level.value === event.target.value);
        
        set_form_data((prev_data) => ({
            ...prev_data,
            class_level: selected_level.value,
        }));

        // console.log("AddLesson.js >> handle_class_level_change :: class_level: ", class_level);
    };

    const handle_time_change = (event) => {

        // const selected_time = event.target.value;
        const selected_time = TIME_SLOTS.find(slot => slot.value === event.target.value);

        set_form_data((prev_data) => ({
            ...prev_data,
            time_of_day: selected_time.start,
            end_time: selected_time.end,
        }));
    };

    // Handle input change for teacher search
    const handle_teacher_search = async (e) => {
        const query = e.target.value;
        set_search_teacher_query(query);

        const nameRegex = /^[A-Za-z]+(?:\.?\s?[A-Za-z]+)*$/;

        // Make sure there is at least one letter.
        // The query can have alphabetical letters, dots, and optional spaces after dots,
        // but no spaces before dots and no leading or trailing spaces.
        if (query.length > 0 && nameRegex.test(query))
        {
            try
            {
                // console.log("AddLesson.js >> handle_teacher_search :: query: ", query);
                dispatch(getTeachersByName(query));  
            }
            catch (error)
            {
                console.error("Failed to fetch teachers", error);
            }
        }
        else
        {
            set_teacher_results([]);
        }
    };

    // Handle adding teacher to the selected list
    const handle_add_teacher = (teacher) => {
        // Only one teacher can be selected
        if (form_data.selected_teacher)
        {
            return;
        }

        if (!form_data.selected_teacher)
        {
            set_form_data({
                ...form_data,
                selected_teacher: teacher
            });
        }
    };

    // Handle removing teacher from the selected list
    const handle_remove_teacher = () => {
        set_form_data({
            ...form_data,
            selected_teacher: null
        });
    };

    const handle_room_change = (event) => {
        const selected_room = event.target.value;
        set_form_data((prev_data) => ({
            ...prev_data,
            room: selected_room,
            center: ROOMS.find(room => room.value === selected_room).center + " - Shop " + ROOMS.find(room => room.value === selected_room).shop,
        }));
    };

    // Handle adding student to the selected list
    const handle_add_student = (student) => {
        if (!form_data.selected_students.find(s => s.student_id === student.student_id))
        {
            set_form_data({
                ...form_data,
                selected_students: [...form_data.selected_students, student]
            });
        }
    };

    // Handle removing student from the selected list
    const handle_remove_student = (studentId) => {
        set_form_data({
            ...form_data,
            selected_students: form_data.selected_students.filter(s => s.student_id !== studentId)
        });
    };

    const handle_date_change = (date) => {

        // console.log("AddReport.js >> handle_date_change >> date: ", date);

        const formattedDate = getDate_HKT(date[0], DATE_FORMAT_DDMMYYYY);

        // console.log("AddReport.js >> handle_date_change >> formattedDate: ", formattedDate);

        // set_selected_date(formattedDate);

        set_form_data((prev_data) => ({
            ...prev_data,
            date_string: formattedDate,
        }));
    };

    const submit_form = e => {
        e.preventDefault();

        if (!form_data.selected_students || form_data.selected_students.length === 0)
        {
            show_error_toast(UI_STRING_ADD_LESSON_ADMIN.Toast_Error_Missing_Students);
        }
        else if (!form_data.selected_teacher)
        {
            show_error_toast(UI_STRING_ADD_LESSON_ADMIN.Toast_Error_Missing_Teacher);
        }
        else
        {
            const updated_lesson = { ...fetched_lesson, // Copy existing lesson data
                                        // Replace with changes
                                        date_string: form_data.date_string,
                                        class_level: form_data.class_level,
                                        time_of_day: form_data.time_of_day,
                                        end_time: form_data.end_time,
                                        room: form_data.room,
                                        center: form_data.center,
                                        teacher: form_data.selected_teacher,
                                        students: form_data.selected_students,
                                };

            // console.log("UpdateLesson.js >> submit_form, lesson_data: ", updated_lesson);

            dispatch( updateLesson(updated_lesson) );
        }
    };

    const handle_trash_lesson = e => {

        e.preventDefault();

        // console.log("UpdateLesson.js >> handle_trash_lesson :: this_lesson_id: ", this_lesson_id);

        setCanRenderForm(false);

        dispatch( trashLesson( fetched_lesson ) );
    };

    const cancel_update_lesson = e => {

        e.preventDefault();

        dispatch( resetFetchLesson() );

        navigate( PATH_NAME.Admin_Dashboard );
    };

    const findTimeSlotByStart = (endValue) => {
        return TIME_SLOTS.find(slot => slot.end === endValue);
    };

    const findClassLevelByLabel = (label) => {
        return CLASS_LEVELS.find(level => level.label === label);
    };

    const findClassroomByRoomValue = (value) => {
        return ROOMS.find(room => room.value === value);
    };

    if (is_updating_lesson)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Updating_Lesson }/>; // Handle loading state
    }

    if (is_trashing_lesson)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Trashing_Lesson }/>; // Handle loading state
    }
    
    // if (is_generating_blank_report)
    // {
    //     return <Spinner message={ UI_STRING_LOADING_ALL.Generating_Reports }/>; // Handle loading state
    // }

    if (!canRenderForm || !fetched_lesson || is_fetching_lesson)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Lesson } />;
    }
    
    return (
        !canRenderForm ? (<></>) :
        (
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center"><FaPen className="mr-8"/>
                            {UI_STRING_ADD_LESSON_ADMIN.Update_Lesson_Title}
                        </h1>
                        {/* <h2>Create an account for a Teacher</h2> */}
                    </section>
                    
                    <section className="form">
                        <form id="add_lesson_form" onKeyDown={preventFormSubmitOnEnter}>

                            <hr className="mt-8 mb-8 regular"></hr>

                            <div className="form_group">
                                <div className="mb-8">
                                    <label className="text-gray-700 font-bold text-xl">Lesson Date:</label>
                                    {/* <label className="text-gray-200 font-medium text-xl">{getCurrentDate_HKT( DATE_FORMAT_DDMMYYYY )}</label> */}

                                    <Flatpickr
                                        className="form-control mt-2"
                                        // value={selected_date}
                                        value={ form_data.date_string }
                                        onChange={handle_date_change}
                                        options={{ dateFormat: "d-m-Y" }}
                                    />
                                </div>

                                <label className="text-gray-700 font-bold text-xl">Select Time Slot:</label>

                                <select
                                    className="form_control"
                                    name="time"
                                    id="time"
                                    onChange={handle_time_change}
                                    defaultValue={findTimeSlotByStart(form_data.end_time).value}
                                >
                                    {TIME_SLOTS.map((slot, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={slot.value}
                                        >
                                            {slot.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Class Level:</label>

                                <select
                                    className="form_control"
                                    name="level"
                                    id="level"
                                    onChange={handle_class_level_change}
                                    defaultValue={findClassLevelByLabel(form_data.class_level).value}
                                >
                                    {CLASS_LEVELS.map((level, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={level.value}
                                        >
                                            {level.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Select Classroom:</label>

                                <select
                                    className="form_control"
                                    name="room"
                                    id="room"
                                    onChange={handle_room_change}
                                    defaultValue={findClassroomByRoomValue(form_data.room).value}
                                >
                                    {ROOMS.map((room, index) => (
                                        <option
                                            key={index}
                                            className="text-gray-700 font-bold text-xl"
                                            value={room.value}
                                        >
                                            {room.label + " (Shop " + room.shop + ")"}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Teacher Field */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Teacher for this Class:</label>
                                <ul>
                                    {form_data.selected_teacher ? (
                                        <li className="text-white text-left font-bold text-xl">
                                            <button type="button" onClick={() => handle_remove_teacher()}>
                                                [-] &nbsp; {form_data.selected_teacher.display_name}
                                            </button>
                                        </li>
                                        ) : (
                                            <></>
                                    )}
                                </ul>
                            </div>

                            {/* Teacher Search Field */}
                            <div className="form_group">
                                {/* <label className="text-gray-700 font-bold text-xl">Search Teacher by Name:</label> */}
                                <input
                                    type="text"
                                    className="form_control"
                                    placeholder="Enter teacher's name to search"
                                    value={search_teacher_query}
                                    onChange={handle_teacher_search}
                                />
                                <ul className="search_results">
                                    {teacher_results.map((teacher) => (
                                        <li className="text-gray-800 text-left text-xl" key={teacher.username}>
                                            <button type="button" onClick={() => handle_add_teacher(teacher)}>
                                                [+] &nbsp; {teacher.display_name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <hr className="mt-8 mb-8"></hr>

                            {/* Selected Students List */}
                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Students Present in this Class:</label>
                                <ul>
                                    {form_data.selected_students.map((student) => (
                                        <li className="text-white text-left text-xl" key={student.student_id}>
                                            <button type="button" onClick={() => handle_remove_student(student.student_id)}>
                                                [-] &nbsp; {student.student_name + " (" + student.student_id + ")"}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <StudentSearchComponent
                                is_multiple={ true }
                                on_result_click={ handle_add_student }
                            />

                            <hr className="mt-20 mb-20 regular"></hr>

                            <div className="form_group mt-12">
                                <button className="btn btn_block" onClick={submit_form}>
                                    {UI_STRING_ADD_LESSON_ADMIN.Button_Save_Lesson}
                                </button>
                            </div>

                            <div className="form_group mt-12">
                                <button className="btn_red btn_block" onClick={handle_trash_lesson}>
                                    {UI_STRING_ADD_LESSON_ADMIN.Button_Delete_Lesson}
                                </button>
                            </div>

                            <div className="form_group mt-12">
                                <button className="btn_gray btn_block" onClick={cancel_update_lesson}>
                                    {UI_STRING_ADD_LESSON_ADMIN.Button_Cancel}
                                </button>
                            </div>
                        </form>
                    </section>
                </div>

                <FooterAdmin />
            </div>
            </>
        )
    )
};

export default UpdateLesson;