const moment = require("moment-timezone");

export const DATE_TIME_ZONE = "Asia/Hong_Kong";
export const DATE_LANGUAGE = "en-US";
export const DATE_FORMAT_MMDDYYYY = "MM-DD-YYYY";
export const DATE_FORMAT_DDMMYYYY = "DD-MM-YYYY";
export const DATE_FORMAT_FULL = "full";

export function getCurrentDate_HKT( input_format )
{
  // Get the current date and time in the Asia/Hong_Kong timezone
  const current_date_hkt = moment.tz(DATE_TIME_ZONE);

  if (input_format === DATE_FORMAT_FULL)
  {
    // Return the full date in "Day, DD Month, YYYY" format
    // Example: "Thursday, 29 February, 2024"
    return current_date_hkt.format("dddd, DD MMMM, YYYY");
  }
  else if (input_format === DATE_FORMAT_MMDDYYYY)
  {
    // Return the date in "MM-DD-YYYY" format
    // Example: "02-29-2024"
    return current_date_hkt.format("MM-DD-YYYY");
  }
  else if (input_format === DATE_FORMAT_DDMMYYYY)
  {
    // Return the date in "DD-MM-YYYY" format
    // Example: "29-02-2024"
    return current_date_hkt.format("DD-MM-YYYY");
  }
  else
  {
    // Return the full date in "Day, DD Month, YYYY" format
    // Example: "Thursday, 29 February, 2024"
    return current_date_hkt.format("dddd, DD MMMM, YYYY");
  }
}

// Takes a date string in "yyyy-mm-dd" format
// Returns whether it is a valid date on the Gregorian calendar
// Assuming the date string is in HKT timezone
// Example usage:
// console.log(is_valid_date('2024-02-29')); // true (leap year)
// console.log(is_valid_date('2023-02-29')); // false (not a leap year)
// console.log(is_valid_date('2024-04-31')); // false (April has 30 days)
export function is_valid_date(date_string)
{
    // Parse the components of the date
    const [year, month, day] = date_string.split('-').map(Number);

    // Create a new date object
    const date = new Date(year, month - 1, day); // JS months are 0-indexed

    // Check if the date is valid
    return date.getFullYear() === year && (date.getMonth() + 1) === month && date.getDate() === day;
}

// Takes a date string in "yyyy-mm-dd" or "mm-dd-yyyy" format
// Returns its full date in in "Day, DD Month, YYYY" representation
// Assuming the input date string is in HKT timezone
// Example usage:
// console.log(getFullDate('2024-02-29')); // "Thursday, 29 February, 2024"
export function getDate_HKT(input_date, output_format, convert_to_utc = false, adjust_timezone = false)
{
  let the_date;

  // In v1.0, input date is always a timestamp or Date object
  the_date = new Date(input_date);
  // Check if input_date is a string
  // if (typeof input_date === 'string' || input_date instanceof String)
  // {
  //   console.log("date_utils.js :: getDate_HKT :: input_date is not a string:", input_date);
    
  //   // Attempt to parse ISO 8601 format
  //   the_date = new Date(input_date);

  //   if (isNaN(the_date.getTime()))
  //   {
  //     // If parsing failed, try replacing dashes with slashes
  //     const adjusted_date_string = input_date.replace(/-/g, '/');
  //     the_date = new Date(adjusted_date_string);

  //     if (isNaN(the_date.getTime()))
  //     {
  //       // If still invalid, parse manually
  //       const dateParts = input_date.match(/^(\d{4})-(\d{2})-(\d{2})$/);

  //       if (dateParts)
  //       {
  //         const year = parseInt(dateParts[1], 10);
  //         const month = parseInt(dateParts[2], 10) - 1; // Months are zero-indexed
  //         const day = parseInt(dateParts[3], 10);

  //         the_date = new Date(year, month, day);
  //       }
  //       else
  //       {
  //         console.error('Invalid date format:', input_date);
  //         return 'Invalid Date';
  //       }
  //     }
  //   }
  // }
  // else
  // {
  //   console.log("date_utils.js :: getDate_HKT :: input_date is not a string:", input_date);

  //   // If input_date is not a string, assume it's a timestamp or Date object
  //   the_date = new Date(input_date);
  // }

  if (isNaN(the_date.getTime()))
  {
    console.error("Invalid date after parsing:", input_date);
    return "Invalid Date";
  }

  // console.log('getDate_MMDDYYYY_HKT :: the_date:', the_date);
  
  let utc_date = the_date;

  // Create a date in UTC
  if (convert_to_utc)
  {
    utc_date = new Date(Date.UTC(
      the_date.getFullYear(),
      the_date.getMonth(),
      the_date.getDate(),
      the_date.getHours(),
      the_date.getMinutes(),
      the_date.getSeconds()
    ));
  }

  if (adjust_timezone)
  {
    let temp_date = utc_date;
    utc_date = new Date(temp_date.getTime() + (temp_date.getTimezoneOffset() * 60000));
  }

  let formattedDate = "";

  const weekday_full = utc_date.toLocaleString(DATE_LANGUAGE, { DATE_TIME_ZONE, weekday: "long" });
  const day_dd = utc_date.toLocaleString(DATE_LANGUAGE, { DATE_TIME_ZONE, day: "2-digit" });
  const month_mm = utc_date.toLocaleString(DATE_LANGUAGE, { DATE_TIME_ZONE, month: "2-digit" });
  const month_full = utc_date.toLocaleString(DATE_LANGUAGE, { DATE_TIME_ZONE, month: "long" });
  const year = utc_date.toLocaleString(DATE_LANGUAGE, { DATE_TIME_ZONE, year: "numeric" });

  if (output_format === DATE_FORMAT_MMDDYYYY)
  {
    // Format the date in "MM-DD-YYYY" for the "Asia/Hong_Kong" timezone
    formattedDate = `${month_mm}-${day_dd}-${year}`;
    // formattedDate = utc_date.toLocaleString('en-US', {
    //   timeZone: 'Asia/Hong_Kong',
    //   month: '2-digit',
    //   day: '2-digit',
    //   year: 'numeric'
    // }).split('/').join('-');
  }
  else if (output_format === DATE_FORMAT_DDMMYYYY)
  {
    // Format the date in "DD-MM-YYYY" for the "Asia/Hong_Kong" timezone
    formattedDate = `${day_dd}-${month_mm}-${year}`;
  }
  else
  // "full"
  // or more options
  {
    // const month = the_date.toLocaleString("en-us", { month: "long" });
    // const year = the_date.getFullYear();
    // const date = the_date.getDate();
    // const day_of_week = the_date.getDay();
    // return `${DAY_OF_WEEK_NUM[day_of_week]}, ${date} ${month}, ${year}`;

    formattedDate = `${weekday_full}, ${day_dd} ${month_full}, ${year}`;
  }

  return formattedDate;
}

// export function getFullDate( input_date )
// {
//   // const today = new Date();
//   const the_date = new Date( input_date );  // Assuming "input_date" is in ISO format or a valid date string
//   const month = the_date.toLocaleString("en-us", { month: "long" });
//   const year = the_date.getFullYear();
//   const date = the_date.getDate();
//   const day_of_week = the_date.getDay();
//   return `${DAY_OF_WEEK_NUM[day_of_week]}, ${date} ${month}, ${year}`;
// }

// export function getWeekNumber_Short( mm_dd_yyyy )
// {
//   const the_date = new Date( mm_dd_yyyy.substring(6, 10), mm_dd_yyyy.substring(0, 2) - 1, mm_dd_yyyy.substring(3, 5) );

//   // This doesn't work in Safari for some reason
//   // const the_date = new Date( mm_dd_yyyy );

//   // This doesn't work in Safari for some reason
//   return DAY_OF_WEEK_NUM_SHORT[ the_date.getDay() ];
// }

// export function getDateInYyyyMmDd( the_date )
// {
//   return the_date.toISOString().split("T")[0];
// }

// export function getDateInMmDdYyyy( input_date )
// {
//   const the_date = new Date( input_date );  // Assuming "input_date" is in ISO format or a valid date string
//   const year = the_date.getFullYear();
//   const month = String(the_date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
//   const day = String(the_date.getDate()).padStart(2, '0');

//   return `${month}-${day}-${year}`;
// }