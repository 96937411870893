import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        RATINGS,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_10_HI,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm10HIWeek2 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`${UI_STRING_REPORT_10_HI.Week_2}`}
    </div>

    {/* Textarea: Topic Section */}
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_10_HI.Key_Topic}
        name="key_topic"
        value={formData.content.key_topic}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_topic"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_1}`}
        name="_2_1_"
        value={formData.content._2_1_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_2}`}
        name="_2_2_"
        value={formData.content._2_2_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_3}`}
        name="_2_3_"
        value={formData.content._2_3_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_4}`}
        name="_2_4_"
        value={formData.content._2_4_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_5}`}
        name="_2_5_"
        value={formData.content._2_5_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_5_"]}
    />
    
    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_6}`}
        name="_2_6_"
        value={formData.content._2_6_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_7}`}
        name="_2_7_"
        value={formData.content._2_7_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_8}`}
        name="_2_8_"
        value={formData.content._2_8_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_8_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_9}`}
        name="_2_9_"
        value={formData.content._2_9_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_9_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_10}`}
        name="_2_10_"
        value={formData.content._2_10_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_10_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_11}`}
        name="_2_11_"
        value={formData.content._2_11_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_11_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="radio"
        label={`${UI_STRING_REPORT_10_HI.Week_2_Metric_12}`}
        name="_2_12_"
        value={formData.content._2_12_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_12_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Checkbox: Can Advance Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_10_HI.Can_Advance}
        name="can_advance"
        value={formData.content.can_advance}
        type="checkbox"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["can_advance"]}
    />
    
    </div>
  );
};

export default UpdateReportForm10HIWeek2;