import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        RATINGS,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_3_RED,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm3Red = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>
    
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_3_RED.Key_Object}
        name="key_object"
        value={formData.content.key_object}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_object"]}
    />
      
    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_3_RED.Key_Letter}
        name="key_letter"
        value={formData.content.key_letter}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["key_letter"]}
    />
    {/* <div className="form_group">
        <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_3_RED.Key_Object}</label>
        <input
            type="text"
            name="key_object"
            value={formData.content.key_object}
            onChange={handleContentChange}
            className="form_control"
            disabled={!is_in_edit_mode}
        />
    </div>

    <div className="form_group">
        <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_3_RED.Key_Letter}</label>
        <input
            type="text"
            name="key_letter"
            value={formData.content.key_letter}
            onChange={handleContentChange}
            className="form_control"
            disabled={!is_in_edit_mode}
        />
    </div> */}

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        type="radio"
        label={`1. ${UI_STRING_REPORT_3_RED.Metric_1}`}
        name="_1_"
        value={formData.content._1_}
        options={RATINGS}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_1_"]}
    />

    {/* <div>
        <label className="text-gray-700 font-bold text-xl block mb-2 text-left">
            1. {UI_STRING_REPORT_3_RED.Metric_1}
        </label>
        <div className="my-6 ml-2">
            {RATINGS.map((rating) => (
                <div key={rating.value} className="flex items-center my-4">
                    <input
                        type="radio"
                        name="_1_phonics"
                        value={rating.value}
                        checked={formData.content._1_phonics === rating.value}
                        onChange={handleContentChange}
                        className="mx-8"
                        style={{
                            transform: "scale(2)", // Scale radio button size
                            WebkitTransform: "scale(2)", // Safari-specific scaling
                        }}
                        disabled={!is_in_edit_mode}
                    />
                    <label className="text-white font-medium text-lg flex items-center">
                        {rating.label}
                    </label>
                </div>
            ))}
        </div>
    </div> */}

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`2. ${UI_STRING_REPORT_3_RED.Metric_2}`}
        name="_2_"
        value={formData.content._2_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />
    
    <ReportFormSection
        label={`3. ${UI_STRING_REPORT_3_RED.Metric_3}`}
        name="_3_"
        value={formData.content._3_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`4. ${UI_STRING_REPORT_3_RED.Metric_4}`}
        name="_4_"
        value={formData.content._4_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`5. ${UI_STRING_REPORT_3_RED.Metric_5}`}
        name="_5_"
        value={formData.content._5_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`6. ${UI_STRING_REPORT_3_RED.Metric_6}`}
        name="_6_"
        value={formData.content._6_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`7. ${UI_STRING_REPORT_3_RED.Metric_7}`}
        name="_7_"
        value={formData.content._7_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`8. ${UI_STRING_REPORT_3_RED.Metric_8}`}
        name="_8_"
        value={formData.content._8_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_8_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`9. ${UI_STRING_REPORT_3_RED.Metric_9}`}
        name="_9_"
        value={formData.content._9_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_9_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`10. ${UI_STRING_REPORT_3_RED.Metric_10}`}
        name="_10_"
        value={formData.content._10_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_10_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        label={`11. ${UI_STRING_REPORT_3_RED.Metric_11}`}
        name="_11_"
        value={formData.content._11_}
        options={RATINGS}
        type="radio"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_11_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Checkbox: Can Advance Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_3_RED.Can_Advance}
        name="can_advance"
        value={formData.content.can_advance}
        type="checkbox"
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["can_advance"]}
    />

    {/* <div className="form_group">
        <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Weekly_Comment}</label>
        <textarea
            name="weekly_comment"
            value={formData.content.weekly_comment}
            onChange={handleContentChange}
            className="form_control"
            rows={COMMENT_AREA_ROWS} // This sets the default height of the textarea to x lines
            disabled={!is_in_edit_mode}
        />
    </div>

    <hr className="mt-8 mb-8 thin"></hr>

    <div className="item-left text-left block my-6">
        <div className="flex items-center">
            <input
                type="checkbox"
                name="can_advance"
                checked={!!formData.content.can_advance} // Ensure it's always a boolean (and not undefined)
                onChange={handleContentChange}
                className="mx-8"
                style={{
                    transform: "scale(2)", // Scale radio button size
                    WebkitTransform: "scale(2)", // Safari-specific scaling
                }}
                disabled={!is_in_edit_mode}
            />
            <label className="text-white font-medium text-lg flex items-center">
                {UI_STRING_REPORT_3_RED.Can_Advance}
            </label>
        </div>
    </div> */}

    
    </div>
  );
};

export default UpdateReportForm3Red;