import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderAdmin from "./for_admins/HeaderAdmin";
import FooterAdmin from "./for_admins/FooterAdmin";
import HeaderTeacher from "./for_teachers/HeaderTeacher";
import FooterTeacher from "./for_teachers/FooterTeacher";
import DaySearchComponent from "./DaySearchComponent";
// import RecurringClassTable from "./RecurringClassTable";
import { FaPlus } from "react-icons/fa";
import { can_access_page } from "../app/user_utils";
import { PATH_NAME } from "../app/constants";
import { UI_STRING_ADD_LESSON_ADMIN } from "../app/strings";

const DaySearchPage = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Day_Search) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    // const go_to_next_page = (student) => {

    //     if (student && student.student_id && student.student_name)
    //     {
    //         navigate(PATH_NAME.Admin_Student_Summaries + "/" + student.student_id + "/" + student.student_name);
    //     }
    // };

    return (
        user &&
        <>
            <div className="basic_bg font-sans">
                
                {
                    user.role === "admin" ? <HeaderAdmin /> : <HeaderTeacher />
                }

                <div className="dashboard_container">
                    <section className="heading">
                        <h1>Class Calendar</h1>
                        
                    </section>

                    <div className="form_group">

                    <hr className="mt-8 mb-8 regular"></hr>

                    {
                    user.role === "admin" &&
                    <>
                    <div className="p-4 tracking-wider">

                        <Link to={ PATH_NAME.Admin_Add_Lesson } className="flex justify-center mt-2 mb-4">
                            <button className="btn w-full">
                            {/* <button className="btn w-5/6"> */}
                                <FaPlus />&nbsp;{UI_STRING_ADD_LESSON_ADMIN.Button_Add_Ad_Hoc_Lesson}
                            </button>
                        </Link>

                    </div>

                    <hr className="mt-8 mb-8 regular"></hr>
                    </>
                    }

                    <div className="p-4 tracking-wider">

                        <DaySearchComponent
                            // [] means all teachers
                            teacher={ [] }
                            // on_result_click={ go_to_next_page }
                        />
                        
                    </div>

                    </div>
                    
                </div>

                {
                    user.role === "admin" ? <FooterAdmin /> : <FooterTeacher />
                }

            </div>
        </>
    );
}

export default DaySearchPage;