import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gcsUploadService from './gcsUploadService';

const initialState_VoiceMessages = {
    new_voice_message_urls: [],
    is_uploading_voice_messages: false,
    is_uploaded_voice_messages: false,
    is_error_uploading_voice_messages: false,
    message_for_voice_message_uploads: "",
    upload_voice_messages_progress: 0,
};

const initialState_Images = {
    new_image_urls: [],
    is_uploading_images: false,
    is_uploaded_images: false,
    is_error_uploading_images: false,
    message_for_image_uploads: "",
    upload_images_progress: 0,
};

const initialState_Videos = {
    new_video_urls: [],
    is_uploading_videos: false,
    is_uploaded_videos: false,
    is_error_uploading_videos: false,
    message_for_video_uploads: "",
    upload_videos_progress: 0,
};

const initialState = {
    ...initialState_VoiceMessages,
    ...initialState_Images,
    ...initialState_Videos,
};

export const uploadVoiceMessages = createAsyncThunk(
    "gcs_uploads/upload_voice_messages",
    async (media_data, thunkAPI) => {
        try
        {
            // console.log("gcsUploadSlice.js >> uploadVoiceMessages :: media_data = ", media_data);

            return await gcsUploadService.uploadVoiceMessages(media_data, thunkAPI);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const uploadImages = createAsyncThunk(
    "gcs_uploads/upload_images",
    async (media_data, thunkAPI ) => {
        try
        {
            // console.log("gcsUploadSlice.js >> uploadImages :: media_data = ", media_data);

            return await gcsUploadService.uploadImages(media_data, thunkAPI);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const uploadVideos = createAsyncThunk(
    "gcs_uploads/upload_videos",
    async (media_data, thunkAPI ) => {
        try
        {
            // console.log("gcsUploadSlice.js >> uploadVideos :: media_data = ", media_data);

            return await gcsUploadService.uploadVideos(media_data, thunkAPI);
        }
        catch (error)
        {
            const message = (error.response && error.response.data && error.response.data.message)
                                || error.message
                                || error.toString();
            return thunkAPI.rejectWithValue( message );
        }
    }
);

export const gcsUploadSlice = createSlice({
    name: "gcs_uploads",
    initialState,
    reducers: {
        resetMediaUploads: state => initialState,
        resetUploadVoiceMessages: state => initialState_VoiceMessages,
        resetUploadImages: state => initialState_Images,
        resetUploadVideos: state => initialState_Videos,
        setUploadVoiceMessagesProgress: (state, action) => {
            state.upload_voice_messages_progress = action.payload;
        },
        setUploadImagesProgress: (state, action) => {
            state.upload_images_progress = action.payload;
        },
        setUploadVideosProgress: (state, action) => {
            state.upload_videos_progress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadVoiceMessages.pending, state => {
                state.is_uploading_voice_messages = true;
                state.is_uploaded_voice_messages = false;
                state.is_error_uploading_voice_messages = false;
                state.upload_voice_messages_progress = 0;
            })
            .addCase(uploadVoiceMessages.fulfilled, (state, action) => {
                state.is_uploading_voice_messages = false;
                state.is_uploaded_voice_messages = true;
                state.new_voice_message_urls = action.payload;
                state.upload_voice_messages_progress = 100;
            })
            .addCase(uploadVoiceMessages.rejected, (state, action) => {
                state.is_uploading_voice_messages = false;
                state.is_error_uploading_voice_messages = true;
                state.message_for_voice_message_uploads = action.payload;
                state.upload_voice_messages_progress = 0;
            })
            .addCase(uploadImages.pending, state => {
                state.is_uploading_images = true;
                state.is_uploaded_images = false;
                state.is_error_uploading_images = false;
                state.upload_images_progress = 0;
            })
            .addCase(uploadImages.fulfilled, (state, action) => {
                state.is_uploading_images = false;
                state.is_uploaded_images = true;
                state.new_image_urls = action.payload;
                state.upload_images_progress = 100;
            })
            .addCase(uploadImages.rejected, (state, action) => {
                state.is_uploading_images = false;
                state.is_error_uploading_images = true;
                state.message_for_image_uploads = action.payload;
                state.upload_images_progress = 0;
            })
            .addCase(uploadVideos.pending, state => {
                state.is_uploading_videos = true;
                state.is_uploaded_videos = false;
                state.is_error_uploading_videos = false;
                state.upload_videos_progress = 0;
            })
            .addCase(uploadVideos.fulfilled, (state, action) => {
                state.is_uploading_videos = false;
                state.is_uploaded_videos = true;
                state.new_video_urls = action.payload;
                state.upload_videos_progress = 100;
            })
            .addCase(uploadVideos.rejected, (state, action) => {
                state.is_uploading_videos = false;
                state.is_error_uploading_videos = true;
                state.message_for_video_uploads = action.payload;
                state.upload_videos_progress = 0;
            });
    }
});

export const { resetMediaUploads,
            resetUploadVoiceMessages,
            resetUploadImages,
            resetUploadVideos,
            setUploadVoiceMessagesProgress,
            setUploadImagesProgress,
            setUploadVideosProgress,
        } = gcsUploadSlice.actions;
export default gcsUploadSlice.reducer;