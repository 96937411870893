import { UI_STRING_REPORT_6_GREEN } from "../../../../app/strings";

export const CHART_DATA_6_GREEN = [
    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // OVERALL CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "學生整體進度",
        type: "line",
        label: "整體評分",
        backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)"
        ],
        borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)"
        ],
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 1 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 1",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_1,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 2 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 2",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_2,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 3 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 3",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_3,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 4 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 4",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_4,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 5 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 5",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_5,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 6 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 6",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_6,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 7 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 7",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_7,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 8 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 8",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_8,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 9 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 9",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_9,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 10 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 10",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_10,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 11 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 11",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_11,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 12 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 12",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_12,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 13 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 13",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_13,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 14 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 14",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_14,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 15 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 15",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_15,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
    },

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // METRIC 16 CHART
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
    {
        title: "老師評分 16",
        type: "line",
        label: UI_STRING_REPORT_6_GREEN.Metric_16,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
    },
];

///////////////////////////////////////////////////////////////
//=============================================================
// CHART SETTINGS
//=============================================================
///////////////////////////////////////////////////////////////

export const CHART_SETTINGS_AVERAGES = {
    borderWidth: 3,
    options: {
        responsive: true, // Ensures the chart responds to window size
        // maintainAspectRatio: false, // Make the chart fit the canvas
        plugins: {
            legend: {
                labels: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 20
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 6,
                ticks: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 18
                    },
                    stepSize: 1, // Force the y-axis to increment by 1 (or any integer step size you need)
                    // suggestedMax: 5,
                    // Custom callback to hide the number 6
                    callback: function (value) {
                        if (value === 6) {
                        return ''; // Do not show 6
                        }
                        return value; // Show all other values
                    },
                },
                grid: {
                    // Hide the grid line for the value 6
                    drawTicks: true,
                    drawBorder: true,
                    color: (context) => (context.tick.value === 6 ? "transparent" : "rgba(114, 114, 114, 0.9)"),
                },
            },
            x: {
                ticks: {
                    color: "rgba(254, 254, 254, 0.9)",
                    font: {
                        size: 18
                    }
                },
                grid: {
                    color: "rgba(114, 114, 114, 0.9)"
                }
            }
        }
    }
}

export const CHART_SETTINGS_SCORES = {
    borderWidth: 2,
    options: {
        responsive: true, // Ensures the chart responds to window size
        // maintainAspectRatio: false, // Allows chart to scale without maintaining strict aspect ratio
        scales: {
            y: {
                beginAtZero: true,
                max: 5.5,
                ticks: {
                    stepSize: 1, // Force the y-axis to increment by 1 (or any integer step size you need)
                    // Custom callback to hide the number 6
                    callback: function (value) {
                        if (value === 5.5) {
                        return ''; // Do not show 6
                        }
                        return value; // Show all other values
                    },
                },
                grid: {
                    // Hide the grid line for the value 6
                    drawTicks: true,
                    drawBorder: true,
                    color: (context) => (context.tick.value === 5.5 ? "transparent" : "rgba(233, 233, 233, 1)"),
                },
            }
        }
    }
};