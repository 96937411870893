import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        COMMENT_AREA_ROWS_3,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_11_READING,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm11ReadingWeek3 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>
    
    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_1}`}
        name="_3_1_"
        value={formData.content._3_1_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_2}`}
        name="_3_2_"
        value={formData.content._3_2_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_3}`}
        name="_3_3_"
        value={formData.content._3_3_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_4}`}
        name="_3_4_"
        value={formData.content._3_4_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_4_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_5}`}
        name="_3_5_"
        value={formData.content._3_5_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_5_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_6}`}
        name="_3_6_"
        value={formData.content._3_6_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_6_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_7}`}
        name="_3_7_"
        value={formData.content._3_7_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_7_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_3_Metric_8}`}
        name="_3_8_"
        value={formData.content._3_8_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_3_8_"]}
    />
    
    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />
    
    </div>
  );
};

export default UpdateReportForm11ReadingWeek3;