import { useEffect, useState, useCallback } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addStudent, resetAddStudent } from "../../../features/students/studentSlice";
import Spinner from "../../Spinner";
import FooterAdmin from "../FooterAdmin";
import { can_access_page } from "../../../app/user_utils";
import { PATH_NAME, STUDENT_ID_PREFIX } from "../../../app/constants";
import { UI_STRING_ADD_STUDENT_ADMIN } from "../../../app/strings";
import { show_error_toast } from "../../../app/ui_utils";

const AddStudent = () => {
    const [form_data, set_form_data] = useState({
        student_first_name: "",
        student_last_name: "",
        student_id: STUDENT_ID_PREFIX,
        // preferred_name: "",
    });

    const { student_first_name, student_last_name, student_id } = form_data;
    // const { student_first_name, student_last_name, student_id, preferred_name } = form_data;

    const [ formElement, setFormElement ] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { new_student, is_adding_student, is_added_student, is_error_adding_student, message_adding_student } = useSelector(state => state.students);

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }

        if (can_access_page(user, PATH_NAME.Admin_Add_Student) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    useEffect(() => {

        // The return statement inside the useEffect function is used to define the cleanup function.
        // This cleanup function is triggered when the component is about to unmount,
        // which effectively catches the "leaving the page" scenario.
        return () => {
            dispatch( resetAddStudent() );
        }
    }, [dispatch]);

    useEffect(() => {

        if (is_added_student)
        {
            // Reset the form
            set_form_data({
                student_first_name: "",
                student_last_name: "",
                student_id: STUDENT_ID_PREFIX,
                // preferred_name: "",
            });
        }

    }, [is_added_student, set_form_data]);
    
    const preventFormSubmitOnEnter = useCallback((event) => {
    
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("add_student_form"));
    }, []);

    const on_change = e => {

        // Reset the added student message
        // when admin starts typing again
        if (is_added_student)
        {
            dispatch( resetAddStudent() );
        }

        set_form_data(prev_state => ({
            ...prev_state,
            [e.target.name]: e.target.value
        }))
    };

    const on_id_change = (e) => {

        const input_value = e.target.value;

        // Reset the added student message
        // when admin starts typing again
        if (is_added_student)
        {
            dispatch( resetAddStudent() );
        }

        // Ensure the value starts with "SYL"
        if (input_value.startsWith( STUDENT_ID_PREFIX ))
        {
            // Allow the user to edit only the part after "SYL"

            set_form_data(prev_state => ({
                ...prev_state,
                student_id: input_value,
            }))
        }
        else
        {
            // If the user tries to remove the "SYL" prefix, force it back

            set_form_data(prev_state => ({
                ...prev_state,
                student_id: STUDENT_ID_PREFIX,
            }))
        }
    };

    // Also reset the added student message when the admin focuses on the form fields
    useEffect(() => {
        const formFields = document.querySelectorAll("#add_student_form input, #add_student_form textarea");
      
        const handleFocus = () => {
            if (is_added_student)
            {
                dispatch( resetAddStudent() );
            }
        };
      
        formFields.forEach((field) => {
          field.addEventListener("focus", handleFocus);
        });
      
        return () => {
          formFields.forEach((field) => {
            field.removeEventListener("focus", handleFocus);
          });
        };
    }, [is_added_student, dispatch]);

    const on_submit = e => {
        e.preventDefault();

        // Check if student_id is in the correct format ("SYL" followed by 4 digits)
        const id_regex = /^SYL\d{4}$/;

        if (!student_id)
        {
            dispatch( resetAddStudent() );

            show_error_toast(UI_STRING_ADD_STUDENT_ADMIN.Toast_Error_Missing_Student_Number);
        }
        else if (!id_regex.test(student_id))
        {
            dispatch( resetAddStudent() );

            show_error_toast(UI_STRING_ADD_STUDENT_ADMIN.Toast_Error_Invalid_Student_Number);
        }
        else if (!student_first_name)
        {
            dispatch( resetAddStudent() );

            show_error_toast(UI_STRING_ADD_STUDENT_ADMIN.Toast_Error_Missing_Student_First_Name);
        }
        else if (!student_last_name)
        {
            dispatch( resetAddStudent() );

            show_error_toast(UI_STRING_ADD_STUDENT_ADMIN.Toast_Error_Missing_Student_Last_Name);
        }
        // else if (!preferred_name)
        // {
        //     dispatch( resetAddStudent() );

        //     show_error_toast(UI_STRING_ADD_STUDENT_ADMIN.Toast_Error_Missing_Student_Preferred_Name);
        // }
        else
        {
            // const trial = (trial_option === "Yes") ? true : false
            // console.log("AddStudent.js >> Adding student = " + student_name + ", student_id = " + student_id );
            const student_name = student_first_name + " " + student_last_name;
            const preferred_name = student_first_name;

            const student_data = { student_name, student_id, preferred_name };
            dispatch( addStudent(student_data) );
        }
    };

    return (
        is_adding_student ? <Spinner /> :
        (
            <>
            <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
                <div className="login_container">
                    <section className="headless_title">
                        <h1 className="flex items-center justify-center"><FaUserPlus className="mr-8"/>Add a Student</h1>
                        {/* <h2>Create an account for a Teacher</h2> */}
                    </section>
                    
                    <section className="form">
                        <form id="add_student_form" onKeyDown={preventFormSubmitOnEnter}>

                            {/* <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Student Number:</label>
                                <input type="text" className="form_control" id="student_id" name="student_id"
                                    value={student_id} placeholder="Enter a unique student number (SYL####)" onChange={on_change} />
                            </div> */}

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Student Number:</label>
                                <label className="text-gray-200 font-regular text-lg">Enter a unique student number (SYL####)</label>
                                <input
                                    type="text"
                                    className="form_control"
                                    id="student_id"
                                    name="student_id"
                                    value={student_id}
                                    placeholder="Enter a unique student number (SYL####)"
                                    onChange={on_id_change} // Correctly binding the change handler
                                />
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">First Name:</label>
                                <input type="text" className="form_control" id="student_first_name" name="student_first_name"
                                    value={student_first_name} placeholder="Enter student's first name" onChange={on_change} />
                            </div>

                            <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Surname:</label>
                                <input type="text" className="form_control" id="student_last_name" name="student_last_name"
                                    value={student_last_name} placeholder="Enter student's surname" onChange={on_change} />
                            </div>

                            {/* <div className="form_group">
                                <label className="text-gray-700 font-bold text-xl">Preferred Name:</label>
                                <label className="text-gray-200 font-regular text-lg">The name to be used in progress reports.</label>
                                <label className="text-gray-200 font-regular text-lg">It is usually the student's first name.</label>
                                <label className="text-gray-200 font-regular text-lg">E.g. John, Yan Yan, etc.</label>
                                <input type="text" className="form_control" id="preferred_name" name="preferred_name"
                                    value={preferred_name} placeholder="Enter student's preferred name" onChange={on_change} />
                            </div> */}

                            <div className="form_group">
                                <button className="btn btn_block" onClick={on_submit}>Add to System</button>
                            </div>

                            {
                                is_added_student && new_student &&
                                <div>
                                    <div className="text-hots-purple font-regular text-lg text-center">Student added successfully:</div>
                                    <div className="text-hots-purple font-bold text-xl text-center">{new_student.student_name} ({new_student.student_id})</div>
                                </div>
                            }

                            {
                                is_error_adding_student && message_adding_student &&
                                <div>
                                    <div className="text-hots-red font-bold text-xl text-center">{message_adding_student}</div>
                                </div>
                            }
                        </form>
                    </section>
                </div>

                <FooterAdmin />
            </div>
            </>
        )
    )
};

export default AddStudent;