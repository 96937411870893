import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getReportsByStudent, resetGetStudentReportsState } from "../../../features/reports/reportSlice";
import { generateSummary, resetGenerateSummary, } from "../../../features/summaries/summarySlice";
import Spinner from "../../Spinner";
import { FaEye } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PATH_NAME } from "../../../app/constants";
import { UI_STRING_LOADING_ALL, UI_STRING_REPORT_ADMIN } from "../../../app/strings";
import { show_error_toast } from "../../../app/ui_utils";

const StudentReportsTable = ({ student_id, student_name }) => {

  const curr_student_id = student_id ? student_id : null;
  const curr_student_name = student_name ? student_name : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canRenderTable, setCanRenderTable] = useState(false);

  const [selectedReports, setSelectedReports] = useState([]);

  const { student_reports, is_loaded_student_reports } = useSelector((state) => state.reports);

  const { output_summary, is_generating_summary } = useSelector((state) => state.summaries);

  const MAX_REPORT_SELECTION = 6;
  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [current_reports, setCurrentReports] = useState([]);
  
  const handleDropdownChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {

    // if (is_error)
    // {
    //     console.log(message);
    // }

    if (curr_student_id === null)
    {
        // console.log("StudentReportsTable.js >> useEffect :: curr_student_id is null. Redirecting to dashboard...");
        
        navigate( PATH_NAME.Dashboard );

        return;
    }

    dispatch( getReportsByStudent( curr_student_id ) );

    return () => {
        dispatch( resetGetStudentReportsState() );
    };
  }, [navigate, curr_student_id, dispatch]);

  useEffect(() => {

    if (is_loaded_student_reports && student_reports)
    {
      setTotalItems(student_reports.length);
      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      setCurrentReports(student_reports.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      ));

      setCanRenderTable(true);
    }
  }, [is_loaded_student_reports, student_reports, currentPage, totalItems]);

  // useEffect(() => {

  //   if (is_generated_summary && output_summary)
  //   {
  //     // console.log("StudentReportsTable.js >> useEffect :: output_summary = ", output_summary);

  //     navigate( PATH_NAME.Admin_Update_Summary + "/" + output_summary.shareable_id + PATH_NAME.Edit_Mode );
  //   }
    
  // }, [is_generated_summary, output_summary, navigate]);

  const handleCheckboxChange = (event, report) => {

    // event.preventDefault();
    
    setSelectedReports((prevSelected) => {

      // Check if the report is already selected
      if (prevSelected.includes(report))
      {
        // If it's already selected, remove it
        return prevSelected.filter((r) => r !== report);
      } 
      // If not selected and less than the max allowed reports are selected, add the report
      else if (prevSelected.length < MAX_REPORT_SELECTION)
      {
        return [...prevSelected, report];
      }
      
      // If max allowed reports are already selected, do nothing (return the current array)
      return prevSelected;
    });
  };

  const isReportSelected = (report) => selectedReports.includes(report);

  const handleViewClick = (report_id, class_level, week_number) => {

    navigate( PATH_NAME.Admin_Update_Report + "/" + class_level + "/" + week_number + "/" + report_id);
  };

  const go_to_new_summary = useCallback((new_summary) => {

    navigate( PATH_NAME.Admin_Update_Summary + "/" + new_summary.shareable_id + PATH_NAME.Edit_Mode );

  }, [navigate]);

  useEffect(() => {

    if (output_summary)
    {
      go_to_new_summary(output_summary);

      return () => {
        dispatch( resetGenerateSummary() );
      };
    }
  }, [output_summary, go_to_new_summary, dispatch]);

  const handleGenerateSummary = () => {
  // const handleGenerateSummary = (selected_reports) => {

    if (selectedReports.length === 0)
    {
      show_error_toast(UI_STRING_REPORT_ADMIN.Toast_Error_No_Report_Selected);

      return;
    }

    if (areClassLevelsDifferent(selectedReports))
    {
      show_error_toast(UI_STRING_REPORT_ADMIN.Toast_Error_Class_Levels_Differ);
      
      return;
    }

    // console.log("Selected reports:", selectedReports);

    const report_ids = selectedReports.map((report) => report._id);
    // const input_comments = [];
    // const input_reports = [];
    

    // selected_reports.forEach((report) => {
    //   // input_comments.push(report.weekly_comment);
    //   input_reports.push(report);
    // });
    
    // These reports should all have the same student
    // const summary_data = { student: input_reports[0].student, reports: input_reports };

    // Test
    // dispatch( getTranslatedSummary(input_comments) );
    // Don't forget to reset later

    // console.log("StudentSummariesTable.js >> handleGenerateSummary :: student_id = ", student_id);

    const input_data = { student_id: student_id, report_ids: report_ids };

    // console.log("StudentReportsTable.js >> handleGenerateSummary :: input_data = ", input_data);

    dispatch( generateSummary(input_data) );
  };

  const areClassLevelsDifferent = (input_reports) => {
    if (!input_reports || input_reports.length === 0)
    {
      return false; // No objects to compare
    }
  
    // Get the value of the specific field in the first object
    const first_value = input_reports[0].class_level;
  
    // Check if all other objects have the same value for the specified field
    for (let i = 1; i < input_reports.length; i++)
    {
      if (input_reports[i].class_level !== first_value)
      {
        return true; // Fields differ
      }
    }
  
    return false; // All fields are the same
  };  

  const goToStudentSummaryPage = () => {
    if (curr_student_id && curr_student_name)
    {
        navigate(PATH_NAME.Admin_Student_Summaries + "/" + curr_student_id + "/" + curr_student_name);
    }
  }

  const get_bar_color = (report) => {

    // This report was voided -- gray it out
    if (report.is_voided === true)
    {
        return "bg-gray-300";
    }
    // This report has not been completed
    else if (report.is_completed === false)
    {
        return "bg-rose-100";
    }
    // Normal status
    else
    {
        return "bg-white";
    }
  }

  if (is_generating_summary)
  {
    return <Spinner message={ UI_STRING_LOADING_ALL.Generating_Summary }/>;
  }

  if (!canRenderTable)
  {
      return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Student_Reports }/>;
  }

  return (
    // !canRenderTable || is_generating_summary ? <Spinner /> :
      (
      <>
      <div className="p-4 tracking-wider">

        <div className="mt-6 mb-20 text-center">
          <div className="text-gray-600 text-lg font-medium">
            Select reports below (up to {MAX_REPORT_SELECTION}) to generate a summary.
          </div>
          
          <button className="btn btn_block w-5/6 mt-4" onClick={() => handleGenerateSummary()}>
            <RiAiGenerate />&nbsp;Generate Summary
          </button>

          <button className="btn_white_outline btn_block w-5/6 mt-4" onClick={() => goToStudentSummaryPage()}>
            <IoDocumentTextOutline />&nbsp;Student's Summary Page
          </button>
        </div>

        <div className="flex justify-center mb-12 mx-4">
          {/* {
            is_generating_summary ?
                <div className="text-gray-700 text-lg font-bold text-center">
                  Generating summary. Please wait...
                </div>
                :
                <></>
          } */}
          {/* {
            is_generated_summary && output_summary ?
                <div className="text-gray-700 text-lg font-bold text-center">
                  output_summary = {output_summary.output_summary}
                </div>
                :
                <></>
          } */}
          {/* {
            output_summary ?
              <Link to={PATH_NAME.Admin_Update_Summary + "/" + output_summary.shareable_id + PATH_NAME.Edit_Mode} >
                <div className="text-hots-green-1 text-xl font-bold text-center">
                  <span className="flex items-center">Click <u>here</u> to view the summary</span>
                </div>
                <div className="text-gray-100 text-lg font-medium text-center my-4 mx-4">
                  (Or find it under Parents tab)
                </div>
              </Link>
              :
              <></>
          } */}
        </div>


        <div className="flex items-center mb-8 ml-10">

          <div className="text-white font-bold text-xl">Go to Page &nbsp;</div>
          <div>
            <select className="border rounded p-2" onChange={handleDropdownChange} value={currentPage}>
              {Array.from({ length: totalPages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="px-6 min-w-full table-auto rounded-3xl border border-separate border-tools-table-outline border-white border-4 w-full border-spacing-y-3">
            <thead className="bg-transparent text-xl">
              <tr>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">Select</th>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">Class On</th>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">Level</th>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">Week</th>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">Teacher</th>
                <th className="px-6 py-3 text-left text-lg font-extrabold text-white">View</th>
              </tr>
            </thead>

            <tbody className="px-4 bg-transparent text-lg">

              {current_reports.length > 0 && current_reports.map((report, index) => (

                <tr key={index}
                    className={`pl-4 ${ get_bar_color(report) }`}
                >

                  <td className="whitespace-nowrap rounded-l-xl">
                    <input
                      type="checkbox"
                      checked={isReportSelected(report)}
                      onChange={(event) => handleCheckboxChange(event, report)}
                      style={{
                        transform: "scale(2.5)", // Scale radio button size
                        WebkitTransform: "scale(2.5)", // Safari-specific scaling
                      }}
                      disabled={report.is_voided === true}
                    />
                  </td>

                  <td className="py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className={`font-medium ${ report.is_voided ? `text-gray-500` : `text-gray-900`}`}>
                          {report.date_string}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className={`font-medium ${ report.is_voided ? `text-gray-500` : `text-gray-900`}`}>
                          {report.class_level}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-10">
                        <div className={`font-medium ${ report.is_voided ? `text-gray-500` : `text-hots-red`}`}>
                          {report.week_number || "pending"}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-2">
                        <div className={`font-medium ${ report.is_voided ? `text-gray-500` : `text-gray-900`}`}>
                          {report.by_teacher.display_name}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="py-4 whitespace-nowrap rounded-r-xl">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <button className="btn_item"  onClick={() => handleViewClick(report._id, report.class_level, report.week_number)}>
                          <FaEye />
                        </button>
                      </div>
                    </div>
                  </td>

                </tr>

                
              ))}

              <tr className="my-12">
                <th colSpan="4" className="px-6 py-6 text-left font-medium text-white">
                <div className="text-white text-xl">
                  {totalItems === 0 ? (
                    "No entries to show"
                    ) : totalItems === 1 ? (
                    `Showing: 1 of 1 entry`
                    ) : (
                    `Showing: ${1 + (currentPage - 1) * ITEMS_PER_PAGE} to ${Math.min(currentPage * ITEMS_PER_PAGE, totalItems)} of ${totalItems} entries`
                  )}
                </div>
                </th>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
      </>
      )
  );
};

export default StudentReportsTable;
