import React from "react";
import ReportFormSection from "./ReportFormSection";
import { 
        COMMENT_AREA_ROWS_3,
        COMMENT_AREA_ROWS } from "../../../app/constants";
import {
        UI_STRING_REPORT_11_READING,
        UI_STRING_REPORT_ALL, } from "../../../app/strings";

const UpdateReportForm8HEWeek4 = ({ formData, handleContentChange, is_in_edit_mode, errors }) => {
  return (
    <div>

    <div className="text-gray-700 font-bold text-xl text-left mb-8">
        {`${UI_STRING_REPORT_11_READING.Week_4}`}
    </div>

    <ReportFormSection
        type="text"
        label={UI_STRING_REPORT_11_READING.Sub_Level}
        name="sub_level"
        value={formData.content.sub_level}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["sub_level"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_4_Metric_1}`}
        name="_4_1_"
        value={formData.content._4_1_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_1_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_4_Metric_2}`}
        name="_4_2_"
        value={formData.content._4_2_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_2_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    <ReportFormSection
        type="textarea"
        rows={COMMENT_AREA_ROWS_3} // Set the number of rows for textarea
        label={`${UI_STRING_REPORT_11_READING.Week_4_Metric_3}`}
        name="_4_3_"
        value={formData.content._4_3_}
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["_4_3_"]}
    />

    <hr className="mt-8 mb-8 thin" />

    {/* Textarea: Weekly Comment Section */}
    <ReportFormSection
        label={UI_STRING_REPORT_ALL.Weekly_Comment}
        name="weekly_comment"
        value={formData.content.weekly_comment}
        type="textarea"
        rows={COMMENT_AREA_ROWS} // Set the number of rows for textarea
        on_change={handleContentChange}
        is_in_edit_mode={is_in_edit_mode}
        field_error={errors["weekly_comment"]}
    />
    
    </div>
  );
};

export default UpdateReportForm8HEWeek4;