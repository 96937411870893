import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FooterTeacher from "./FooterTeacher";
import FooterAdmin from "../for_admins/FooterAdmin";
import { can_access_page } from "../../app/user_utils";
import { FaPen, FaEye } from "react-icons/fa";
import Spinner from "../Spinner";
import { fetchReportById, updateReportDetails,
        resetFetchReportState, resetUpdateReportDetailsState } from "../../features/reports/reportSlice";
import { PATH_NAME, WEEK_NUMBERS_JUNIOR, WEEK_NUMBERS_SENIOR, CLASS_LEVELS } from "../../app/constants";
import { UI_STRING_LOADING_ALL,
        UI_STRING_REPORT_ALL,
        UI_STRING_REPORT_DETAILS } from "../../app/strings";
import { show_error_toast } from "../../app/ui_utils";
import { getDate_HKT, DATE_FORMAT_DDMMYYYY } from "../../app/date_utils";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_orange.css";  // Import a theme of your choice
import { isClassSenior } from "../../app/utils";

const UpdateReportDetailsPage = () => {
    const { report_id, class_level, edit_mode } = useParams();

    const this_report_id = report_id ? report_id : null;
    const this_class_level = class_level ? class_level : null;
    const is_in_edit_mode = edit_mode ? edit_mode : false;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);

    const { fetched_report, fetch_status,
            is_updating_report_details, is_updated_report_details } = useSelector((state) => state.reports);

    const [ canRenderForm, setCanRenderForm ] = useState(false);
    const [ formElement, setFormElement ] = useState(null);

    const [week_options, set_week_options] = useState(WEEK_NUMBERS_JUNIOR);
    const [is_loading_week_options, set_is_loading_week_options] = useState(false);

    // const week_numbers_array = useMemo(() => {

    //     return isClassSenior(this_class_level) ? WEEK_NUMBERS_SENIOR : WEEK_NUMBERS_JUNIOR;

    // }, [ this_class_level]);

    const [formData, setFormData] = useState({
        class_level: this_class_level,
        week_number: "",
        date_string: "",
        is_voided: false,
        void_reason: "",
    });

    const preventFormSubmitOnEnter = useCallback((event) => {
        if (event.key === "Enter")
        {
            // Prevent form submission
            event.preventDefault();
            return false;
        }
    }, []);

    useEffect(() => {
        // Attach event listener when formElement is set
        if (formElement)
        {
            formElement.addEventListener("keydown", preventFormSubmitOnEnter);

            // Remove event listener on cleanup
            return () => {
                formElement.removeEventListener("keydown", preventFormSubmitOnEnter);
            };
        }
    }, [formElement, preventFormSubmitOnEnter]);

    useEffect(() => {
        // When the component is mounted, set the form element
        setFormElement(document.getElementById("update_report_form"));
    }, []);

    useEffect(() => {
        if (this_report_id)
        {
            dispatch( fetchReportById(this_report_id) );
        }
        else
        {
            // console.log("No report ID provided. Redirecting...");

            navigate( PATH_NAME.Teacher_Dashboard );
        }

    }, [this_report_id, dispatch, navigate]);

    useEffect(() => {
        if (fetch_status === "succeeded" && fetched_report)
        {
            // If class level and week number were previously saved,
            // update week options based on class level,
            // and set the week number in form data accordingly
            if (fetched_report.class_level && fetched_report.week_number)
            {
                if (isClassSenior(fetched_report.class_level))
                {
                    set_week_options( WEEK_NUMBERS_SENIOR );
                }
                else
                {
                    set_week_options( WEEK_NUMBERS_JUNIOR );
                }
            }

            setFormData({
                class_level: fetched_report.class_level,
                week_number: fetched_report.week_number,
                date_string: fetched_report.date_string,
                is_voided: fetched_report.is_voided || false,
                void_reason: fetched_report.void_reason || "",
            });

            // Can safely render the form now
            setCanRenderForm(true);
        }
    }, [fetch_status, fetched_report]);

    useEffect(() => {

        if (is_updated_report_details)
        {
            if (fetched_report && fetched_report.student)
            {
                // If voided, return to reports page
                if (formData.is_voided)
                {
                    navigate( PATH_NAME.Teacher_My_Student_Reports +
                                    "/" + fetched_report.student.student_id +
                                    "/" + fetched_report.student.student_name );
                }
                // Otherwise, go to Step 2
                else
                {
                    // class level may have been changed, but report id should remain the same
                    navigate( PATH_NAME.Teacher_Update_Report
                                + "/" + formData.class_level
                                + "/" + formData.week_number
                                + "/" + this_report_id
                                + PATH_NAME.Edit_Mode );
                }
            }
            else
            {
                navigate( PATH_NAME.Teacher_Dashboard );
            }

            // return () => {

            // console.log("UpdateReportDetailsPage.js >> useEffect >> is_updated_report_details :: Navigated to next page. Returning...");

            dispatch( resetUpdateReportDetailsState() );
            // Reset even if moving onto Step 2, where report will be fetched again
            dispatch( resetFetchReportState() );
            // }
        }
    }, [is_updated_report_details, fetched_report, formData, this_report_id, dispatch, navigate]);

    // Generic input change handler
    const handle_change = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handle_date_change = (date) => {

        const formattedDate = getDate_HKT(date[0], DATE_FORMAT_DDMMYYYY);

        setFormData((prev_data) => ({
            ...prev_data,
            date_string: formattedDate,
        }));
    };

    const handle_class_level_change = (event) => {

        const selected_level = CLASS_LEVELS.find(level => level.value === event.target.value);

        set_is_loading_week_options(true); // Start loading before options update

        setTimeout(() => {
            if (selected_level.type === "Junior")
            {
                set_week_options( WEEK_NUMBERS_JUNIOR );
            }
            else
            {
                set_week_options( WEEK_NUMBERS_SENIOR );
            }

            set_is_loading_week_options(false); // End loading after options update

        }, 1000); // Simulate a delay for updating options
        
        setFormData((prev_data) => ({
            ...prev_data,
            class_level: selected_level.value,
        }));
    };

    const hasFieldUpdates = (form, report) => {

        return (
            form.class_level !== report.class_level ||
            form.week_number !== report.week_number ||
            form.date_string !== report.date_string ||
            form.is_voided !== report.is_voided ||
            form.void_reason !== report.void_reason
        );
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        // The way the selections work, class level and week number will not be empty
        // Data string shouldn't be empty either, but checking for backward compatibility
        if (!formData.date_string)
        {
            show_error_toast(UI_STRING_REPORT_DETAILS.Toast_Error_Missing_Date);
            return;
        }

        // Check if there are any changes
        if (!hasFieldUpdates(formData, fetched_report))
        {
            // console.log("UpdateReportDetailsPage.js >> handleSubmit :: No changes detected. Moving on...");

            const cached_report = fetched_report;

            // Go straight to Step 2
            // Caching the report, so that it does not need to be fetched again
            navigate( PATH_NAME.Teacher_Update_Report
                                + "/" + formData.class_level
                                + "/" + formData.week_number
                                + "/" + this_report_id
                                + PATH_NAME.Edit_Mode, {
                state: { cached_report }
            } );

            // return () =>
            // {
                // console.log("UpdateReportDetailsPage.js >> handleSubmit :: Navigated to next page. Returning...");
                dispatch( resetUpdateReportDetailsState() );
                // Reset even if moving onto Step 2, where report should be cached
                dispatch( resetFetchReportState() );
            // }
        }
        else
        // Save updated details
        {
            const updated_report = { ...fetched_report,
                                    ...formData,
                                    // is_completed: true, // Update in Step 2
                                };

            // console.log("UpdateReportPage.js >> handleSubmit :: updated_report = ", updated_report);
            
            dispatch( updateReportDetails(updated_report) );
        }
    };

    useEffect(() => {
        if (!user)
        {
            navigate( PATH_NAME.Login );
        }
        
        if (can_access_page(user, PATH_NAME.Teacher_Update_Report_Details) === false)
        {
            // console.log("User has no permission. Redirecting to dashboard...");

            navigate( PATH_NAME.Dashboard );
        }
    }, [user, navigate]);

    const return_to_student_page = (event) => {

        event.preventDefault();

        if (user && (user.role === "admin" || user.role === "manager"))
        {
            navigate(PATH_NAME.Admin_Student_Reports + "/" + fetched_report.student.student_id + "/" + fetched_report.student.student_name);
        }
        else if (user && user.role === "teacher")
        {
            navigate(PATH_NAME.Teacher_My_Student_Reports + "/" + fetched_report.student.student_id + "/" + fetched_report.student.student_name);
        }

        // console.log("UpdateReportDetailsPage.js >> return_to_student_page :: Returning...");
        dispatch( resetUpdateReportDetailsState() );
        dispatch( resetFetchReportState() );
    };

    if (is_updating_report_details)
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Saving_Report_Details } />;
    }

    if (!canRenderForm || !fetched_report || fetch_status === "loading")
    {
        return <Spinner message={ UI_STRING_LOADING_ALL.Loading_Report } />;
    }

    return (

        // (!fetched_report || fetch_status === "loading") ? <Spinner /> :
        // (
        !canRenderForm ? (<></>) :
        (
        <>
        <div className="bg-gradient-to-b from-hots-green-1 to-hots-green-2 ... text-center">
            <div className="login_container">

                <section className="headless_title mb-12">

                    <div className="flex items-center justify-center text-gray-300 font-bold text-2xl">
                        {UI_STRING_REPORT_ALL.Step_1}
                    </div>

                    <h1 className="flex items-center justify-center">

                        {
                            edit_mode === "edit_mode" ?
                            (
                                <>
                                <FaPen className="mr-8"/> {UI_STRING_REPORT_ALL.Update_Report_Details}
                                </>
                            ) :
                            (
                                <>
                                <FaEye className="mr-8"/> {UI_STRING_REPORT_ALL.View_Report}
                                </>
                            )
                        }
                    </h1>
                
                </section>

                <section className="headless_title mb-12">

                    <p className="my-6 text-hots-purple font-extrabold text-4xl">
                        {fetched_report && fetched_report.student && fetched_report.student.student_name}
                    </p>
                    <p className="my-6 text-gray-500 font-bold text-3xl">
                        Preferred Name: {fetched_report && fetched_report.student && fetched_report.student.preferred_name}
                    </p>
                    <p className="my-6 text-white font-bold text-2xl">
                        ({ fetched_report && fetched_report.student && fetched_report.student.student_id })
                    </p>
                </section>
                
                <section className="form">
                    <form id="update_report_form"
                            onKeyDown={preventFormSubmitOnEnter}
                    >

                        <hr className="mt-8 mb-8 regular"></hr>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.The_Teacher}</label>

                            <label className="text-gray-300 font-medium text-xl">
                                {fetched_report && fetched_report.by_teacher && fetched_report.by_teacher.display_name}
                            </label>
                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Report_Status}</label>

                            {fetched_report.is_completed ?
                            (
                                <label className="text-gray-300 font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Completed}
                                </label>
                            ) : (
                                <label className="text-hots-red font-medium text-xl">
                                    {UI_STRING_REPORT_ALL.Incomplete}
                                </label>
                            )}
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Class_Date}</label>
                            
                            {/* <label className="text-gray-300 font-medium text-xl uppercase">
                                {fetched_report && fetched_report.date_string}
                            </label> */}

                            <Flatpickr
                                className="form-control mt-2"
                                // Set default date value
                                value={formData.date_string ? formData.date_string : ""}
                                onChange={handle_date_change}
                                options={{ dateFormat: "d-m-Y" }}
                            />
                            
                        </div>
                        
                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Class_Level}</label>

                            <select
                                className="form_control"
                                name="level"
                                id="level"
                                onChange={handle_class_level_change}
                                // Conditional default value
                                defaultValue={formData.class_level ? formData.class_level : ""}
                            >
                                <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                {CLASS_LEVELS.map((level, index) => (
                                    <option
                                        key={index}
                                        className="text-gray-700 font-bold text-xl"
                                        value={level.value}
                                    >
                                        {level.label}
                                    </option>
                                ))}
                            </select>

                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Week_Number}</label>
                            
                            {/* <label className="text-gray-300 font-medium text-xl uppercase">
                                {UI_STRING_REPORT_ALL.The_Week + " " + (fetched_report && fetched_report.week_number)}
                            </label> */}

                            <select
                                className="form_control"
                                name="week_number"
                                id="week_number"
                                onChange={handle_change}
                                // Conditional default value
                                defaultValue={formData.week_number ? formData.week_number : ""}
                                disabled={is_loading_week_options} // Disable when loading
                            >
                                {is_loading_week_options ? (
                                    <option>Please wait...</option> // Display "Please wait..." while loading
                                ) : (
                                    
                                    <>
                                    <option value="" disabled>{UI_STRING_REPORT_ALL.Pick_One}</option>

                                    {
                                    week_options.map((week, index) => (
                                    <option
                                        key={index}
                                        className="text-gray-700 font-bold text-xl"
                                        value={week.value}>
                                        {week.label}
                                    </option>
                                    ))
                                    }
                                    </>
                                )}
                            </select>
                            
                        </div>

                        <hr className="mt-8 mb-8 thin"></hr>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl w-full">
                                {UI_STRING_REPORT_ALL.Is_Report_Voided}
                            </label>
                            
                            
                        </div>

                        <div className="flex items-center mt-0 mb-8">
                        <input
                            type="checkbox"
                            name="is_voided"
                            value={formData.is_voided}
                            checked={!!formData.is_voided}
                            onChange={handle_change}
                            className="ml-4"
                            style={{
                            transform: "scale(2)", // Scale checkbox size
                            WebkitTransform: "scale(2)", // Safari-specific scaling
                            }}
                            disabled={!is_in_edit_mode}
                        />
                        <label className="text-white font-medium text-lg flex items-center ml-6">
                            Yes
                        </label>
                        </div>

                        <div className="form_group">

                            <label className="text-gray-700 font-bold text-xl">{UI_STRING_REPORT_ALL.Void_Reason}</label>

                            <label className="text-gray-600 font-medium text-lg">{UI_STRING_REPORT_ALL.Void_Reason_Example}</label>
                            
                            <input
                                type="text"
                                name="void_reason"
                                value={formData.void_reason}
                                onChange={handle_change}
                                className="form_control"
                                disabled={!is_in_edit_mode}
                            />
                            
                        </div>
                        
                        <hr className="mt-8 mb-8 regular"></hr>

                        {
                        is_in_edit_mode && (
                            <>
                            <div className="form_group flex justify-center mt-12">
                                <button className="btn btn_block" onClick={handleSubmit}>
                                    {UI_STRING_REPORT_ALL.Button_Next_Step}
                                </button>
                            </div>

                            <div className="form_group flex justify-center mt-4 mb-16">
                                <button className="btn btn_gray w-full" onClick={return_to_student_page}>
                                    {UI_STRING_REPORT_ALL.Button_Cancel}
                                </button>
                            </div>
                            </>
                        )}

                        {
                        !is_in_edit_mode && (
                            <>
                            <div className="form_group flex justify-center mt-12">
                                <button className="btn btn_gray w-full" onClick={return_to_student_page}>
                                    {UI_STRING_REPORT_ALL.Button_Return}
                                </button>
                            </div>
                            </>
                        )}
                    </form>
                </section>

            </div>

        {
            user && (user.role === "admin" || user.role === "manager") &&
            <FooterAdmin />
        }

        {
            user && user.role === "teacher" &&
            <FooterTeacher />
        }
        </div>
        </>
        )
    )
    // );
};

export default UpdateReportDetailsPage;