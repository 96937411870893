import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import HeaderAdmin from "../HeaderAdmin";
import FooterAdmin from "../FooterAdmin";
import RecurringClassTable from "./RecurringClassTable";
import { PATH_NAME } from "../../../app/constants";
import { can_access_page } from "../../../app/user_utils";
import { UI_STRING_RECURRING_CLASS } from "../../../app/strings";
import { FaPlus } from "react-icons/fa";

const SchoolSchedulePage = ({ day_of_week }) => {

    const this_day_of_week = day_of_week;

    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    const [ should_render, set_should_render ] = useState(false);

    // Update later
    const [ week_day, set_week_day ] = useState( this_day_of_week || "Tuesday" );

    useEffect(() => {

        if ( !this_day_of_week )
        {
            
        }
    }, [this_day_of_week, navigate]);

    useEffect(() => {

        if (can_access_page(user, PATH_NAME.Admin_School_Schedule) === false)
        {
            navigate( PATH_NAME.Dashboard );
        }

    }, [user, navigate]);

    useEffect(() => {

        if (user && user.role === "admin")
        {
            // console.log("DashboardAdmin.js >> useEffect :: should render...");

            set_should_render(true);
        }
    
    // Dependency ensures that the effect runs whenever user changes
    // but still keeps the desired behavior for rendering the component once. 
    }, [user]); 

    // Function to handle dropdown changes
    const handleWeekDayChange = (e) => {

        set_week_day(e.target.value);
    };


    return (
        should_render &&
        (<>
            <div className="basic_bg font-sans">
                
                <HeaderAdmin />

                <div className="dashboard_container">
                
                    <section className="heading">
                        <h1>{UI_STRING_RECURRING_CLASS.School_Schedule_Page_Title}</h1>
                        
                    </section>

                    <hr className="mt-8 mb-8 regular"></hr>

                    <div className="form_group">

                    <div className="p-4 tracking-wider">

                        <Link to={ PATH_NAME.Admin_Add_Recurring_Class } className="flex justify-center mt-2 mb-4">
                            <button className="btn w-full">
                                <FaPlus />&nbsp;{UI_STRING_RECURRING_CLASS.Button_Add_Recurring_Class}
                            </button>
                        </Link>

                    </div>

                    <hr className="mt-8 mb-8 regular"></hr>

                    {/* Dropdown for selecting the week day */}
                    <div className="form_group mb-4 p-4">

                    <label htmlFor="week_day"
                        className="text-gray-700 font-semibold text-2xl"
                            >
                            Select Day to View:
                    </label>
                    <select id="week_day" value={week_day} onChange={handleWeekDayChange} className="form_control">
                        <option value="Monday" disabled>Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                    </select>
                    </div>

                    {/* Update later */}
                    <RecurringClassTable the_week_day={ week_day } />

                    </div>

                </div>

                <FooterAdmin />
            </div>
        </>)
    );
}

export default SchoolSchedulePage;