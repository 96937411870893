import { useLocation } from "react-router-dom";
import { MdEventRepeat } from "react-icons/md";
import { PATH_NAME } from "../app/constants";
import { UI_STRING_HEADER_ALL } from "../app/strings";

const TabButtonSchoolSchedule = () => {

    const location = useLocation();

    return (
        location.pathname === PATH_NAME.Admin_School_Schedule
        ? (
            <button className="btn_white_outline_highlighted">
                <MdEventRepeat />&nbsp;{UI_STRING_HEADER_ALL.Button_School_Schedule}
            </button>
        ) : (
            <button className="btn_white_outline">
                <MdEventRepeat />&nbsp;{UI_STRING_HEADER_ALL.Button_School_Schedule}
            </button>
        )
    );
};

export default TabButtonSchoolSchedule;