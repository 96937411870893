import axios from "axios";
import { MAIN_URL } from "../../app/config";

const API_URL = MAIN_URL + "/api/recurring_classes/"

const addRecurringClass = async (class_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("recurringClassService.js >> addRecurringClass :: class_data: ", class_data);

    const response = await axios.post(API_URL, class_data, config);
    return response.data;
}

const getRecurringClasses = async token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL, config);
    return response.data;
}

const getRecurringClassesByDay = async (day_of_week, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    // console.log("recurringClassService.js >> getRecurringClassesByDay :: day_of_week = " + day_of_week);
    const response = await axios.get(API_URL + "day/" + day_of_week, config);
    return response.data;
}

const fetchRecurringClassById = async (class_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await axios.get(API_URL + class_id, config);
    return response.data;
}

const updateRecurringClass = async (class_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    const response = await axios.put(API_URL + class_data._id, class_data, config);
    return response.data;
}

const trashRecurringClass = async (class_data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };

    const response = await axios.put(API_URL + "trash/" + class_data._id, class_data, config);
    return response.data;
}

const recurringClassService = { getRecurringClasses,
                                addRecurringClass,
                                getRecurringClassesByDay,
                                fetchRecurringClassById,
                                updateRecurringClass,
                                trashRecurringClass
                            };

export default recurringClassService;