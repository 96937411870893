import ChartViewer1Yellow from "./ChartViewer1Yellow";
import ChartViewer2Pink from "./ChartViewer2Pink";
import ChartViewer3Red from "./ChartViewer3Red";
import ChartViewer4PreBlue from "./ChartViewer4PreBlue";
import ChartViewer5Blue from "./ChartViewer5Blue";
import ChartViewer6Green from "./ChartViewer6Green";
import ChartViewer7HEEP from "./ChartViewer7HEEP";
import ChartViewer8HE from "./ChartViewer8HE";
import ChartViewer10HI from "./ChartViewer10HI";
import ChartViewer11Reading from "./ChartViewer11Reading";

const ChartViewer = ({ chart_data, class_level }) => {

  const this_chart_data = chart_data ? chart_data : null;
  const this_class_level = class_level ? class_level : null;
  
  return (
    <>
    {
        this_chart_data && this_class_level &&
        (this_class_level === "Yellow") ? (<ChartViewer1Yellow chart_data={this_chart_data} />) :
        (this_class_level === "Pink") ? (<ChartViewer2Pink chart_data={this_chart_data} />) :
        (this_class_level === "Red") ? (<ChartViewer3Red chart_data={this_chart_data} />) :
        (this_class_level === "Pre-Blue") ? (<ChartViewer4PreBlue chart_data={this_chart_data} />) :
        (this_class_level === "Blue") ? (<ChartViewer5Blue chart_data={this_chart_data} />) :
        (this_class_level === "Green") ? (<ChartViewer6Green chart_data={this_chart_data} />) :
        (this_class_level === "HEEP") ? (<ChartViewer7HEEP chart_data={this_chart_data} />) :
        (this_class_level === "HE L1") ? (<ChartViewer8HE chart_data={this_chart_data} />) :
        (this_class_level === "HE L2") ? (<ChartViewer8HE chart_data={this_chart_data} />) :
        (this_class_level === "HI") ? (<ChartViewer10HI chart_data={this_chart_data} />) :
        (this_class_level === "Reading") ? (<ChartViewer11Reading chart_data={this_chart_data} />) :
        (<ChartViewer3Red chart_data={this_chart_data} />)
    }
    </>
);
};

export default ChartViewer;