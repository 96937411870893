import { toast } from "react-toastify";
import { TOAST_ERROR_MS } from "./constants";

export function show_error_toast(message)
{
  toast.error(message, {
                position: "bottom-left",
                autoClose: TOAST_ERROR_MS,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className: "bigger_toast text-3xl",
              });
}