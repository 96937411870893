export const CHART_DATA_8_HE = [

    ///////////////////////////////////////////////////////////////
    //-------------------------------------------------------------
    // RADAR CHART TEMPLATE
    //-------------------------------------------------------------
    ///////////////////////////////////////////////////////////////
];

///////////////////////////////////////////////////////////////
//=============================================================
// CHART SETTINGS
//=============================================================
///////////////////////////////////////////////////////////////

export const CHART_SETTINGS = {
    type: "radar",
    // data: data,
    options: {
        elements: {
            line: {
                borderWidth: 3
            }
        },
        scales: {
            r: {
                ticks: {
                    stepSize: 1,
                    beginAtZero: true,
                    min: 0,
                    max: 5,
                },
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
    },
};

export const CHART_COLORS = [
    { backgroundColor: "rgba(54, 162, 235, 0.2)", borderColor: "rgb(54, 162, 235)" }, // Blue
    { backgroundColor: "rgba(255, 206, 86, 0.2)", borderColor: "rgb(255, 206, 86)" }, // Yellow
    { backgroundColor: "rgba(75, 192, 192, 0.2)", borderColor: "rgb(75, 192, 192)" }, // Green
    { backgroundColor: "rgba(255, 99, 132, 0.2)", borderColor: "rgb(255, 99, 132)" }, // Red
    { backgroundColor: "rgba(153, 102, 255, 0.2)", borderColor: "rgb(153, 102, 255)" }, // Purple
    { backgroundColor: "rgba(255, 159, 64, 0.2)", borderColor: "rgb(255, 159, 64)" }  // Orange
];